import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../../../service';
import { CommonService } from '../../../service/common/common.service';
@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(!(request.url.includes('document/hand-book')|| (request.url.includes('cozBot')) ||  (request.url.includes('stat')))){
    CommonService.showLoader();
    }
    let tokenDetails = '';
    tokenDetails = "Bearer JWT" ;
    if (!/^(http|https):/i.test(request.url)) {
      let url = '';
      url = '';
      const headers: any = {
        'Authorization': `${tokenDetails}`,
        'Cache-Control': 'no-cache',
      }
      request = request.clone({
        url,
        headers: new HttpHeaders(headers)
      });
    }
    if (request.method === 'POST' || request.method === 'PUT') {
      let body = request.body;
      if (body) {
        let newBody;
        if (request.body instanceof FormData) {
          newBody = new FormData();
          request.body.forEach((value: any, key: any) => {
            // const snakeValue = typeof value === 'object' ? this.camelCaseToSnakeCase(value) : value;
            newBody.append(this.toSnakeCase(key), value);
          });
        } 
        else {
          newBody = this.camelCaseToSnakeCase(request.body);
        }
        request = request.clone({
          body: newBody
        });
      }
    }
    if (this.storageService.getItem('token')!=null){
      const headers: any = {
        'Authorization': `${tokenDetails}`
      }
      let url = request.url;
    request = request.clone({
      url,
      headers: new HttpHeaders(headers)
    });
    return next.handle(request);
    }
    else{

      let url = request.url;
    request = request.clone({
      url
    });
    return next.handle(request);
    }

  }

  //function to convert Camel Case Keys to Snake Case
  camelCaseToSnakeCase(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map(value => this.camelCaseToSnakeCase(value));
    }
    else if (typeof obj === 'object' && obj !== null) {
      const snakeObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const snakeKey = this.toSnakeCase(key);
          snakeObj[snakeKey] = this.camelCaseToSnakeCase(obj[key]);
        }
      }
      return snakeObj;
    }
    return obj;
  }
  //function to convert Camel Case String to Snake case
    toSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }
}


