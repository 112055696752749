import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseUrl = environment.baseApiUrl;
  endpointUrl = 'preferences/template';
  private http: HttpClient;
  private static loaderEnabled: boolean = false;
  private refreshClickEvent = new Subject<any>();
  private documentTypeEvent = new Subject<any>();
  private privelegeClickEvent = new Subject<any>();
  constructor(_http: HttpClient) {
    this.http = _http;
  }
  groupData(listData: any[]) {
    // Initialize groups
    const activeData = listData.filter(app => app.isActive);
    const inactiveData = listData.filter(app => !app.isActive);

    // Create grouped list with headers
     let groupedList = [];

    if (activeData.length > 0) {
       groupedList.push({
        label: 'Active',
        items: activeData
      });
    }

    if (inactiveData.length > 0) {
       groupedList.push({
        label: 'Inactive',
        items: inactiveData
      });
    }

    return groupedList;
  }
  onClickSendRefreshEvent(route) {
    this.refreshClickEvent.next(route);
  }
  onClickSendDocumentTypeEvent(data) {
    this.documentTypeEvent.next(data);
  }
  getDocumentTypeEvent(): Observable<any> {
    return this.documentTypeEvent.asObservable();
  }
  onClickSendPrivelegeEvent(res) {
    this.privelegeClickEvent.next(res);
  }
  getPrivelegeEvent(): Observable<any> {
    return this.privelegeClickEvent.asObservable();
  }
  getRefreshEvent(): Observable<any> {
    return this.refreshClickEvent.asObservable();
  }
  addComment(commentBody: {}) {
    return this.http.post(environment.baseApiUrl + 'comment', commentBody);
  }
  addMultipleRemark(body: {}) {
    return this.http.post(environment.baseApiUrl + 'doctracker/remark/multiple', body);
  }
  getFilterTemplateList(preferenceId: Number) {
    return this.http.get<any>(this.baseUrl + this.endpointUrl + '/' + preferenceId);
  }
  getColumnSettings(preferenceId: Number) {
    return this.http.get<any>(this.baseUrl + 'preferences/columns-list?preference_type=' + preferenceId);
  }
  getQueueCount(){
    return this.http.get<any>(this.baseUrl + 'doctracker/queue/count');
  }
  getInboxEmailCount(){
    return this.http.get<any>(this.baseUrl + 'etracker/email/status');
  }
  getSFTPFiles(){
    return this.http.get<any>(this.baseUrl + 'edi/sftp/file/list');
  }
  getInboxEmailsByType(actionType){
    return this.http.get<any>(this.baseUrl + 'etracker/email/inbox/log?email_source='+actionType);
  }
  getCozmosHandbook(){
    return this.http.get<any>(this.baseUrl + 'document/hand-book');
  }
  getNotificationList() {
    return this.http.get<any>(this.baseUrl + 'notification/list');
  }
  getNotificationCount() {
    return this.http.get<any>(this.baseUrl + 'notification/count');
  }
  getNotificationListById(notificationId: Number) {
    return this.http.get<any>(this.baseUrl + 'notification/'+notificationId);
  }
  postNotificationRead(body){
    return this.http.post(this.baseUrl + 'notification/read', body);
  }
  createFilterTemplate(templateBody: {}) {
    return this.http.post(this.baseUrl + this.endpointUrl, templateBody);
  }
  templateDetailsByID(templateId: Number) {
    return this.http.get(this.baseUrl + this.endpointUrl + '/' + templateId + '/details')
  }
  deleteTemplateById(templateId: Number) {
    return this.http.delete(this.baseUrl + this.endpointUrl + '/' + templateId + '/details')
  }
  getMasterPreferencesList() {
    return this.http.get(this.baseUrl + 'master/preferences');
  }
  getEmailLogAttachement(id,emailSource) {
    return this.http.get(this.baseUrl + 'etracker/email/inbox/download/'+id+'?email_source='+emailSource,{observe: 'response', responseType: 'blob' });
  }
  getAssignableUserList(module: string = "", name: string = "",email:string="") {
    return this.http.get<any>(this.baseUrl + 'admin/user/short-info?name=' + name + '&email='+email+'&module=' + module);
  }
  getLeVendorCustomData(type, field, status) {
    if (status == null) {
      return this.http.get<any>(this.baseUrl + 'admin/le-vendor/custom?type=' + type + '&field=' + field);
    } else {
      return this.http.get<any>(this.baseUrl + 'admin/le-vendor/custom?type=' + type + '&field=' + field + '&is_active=' + status);
    }
  }

  getFinancialDimensionList(module) {
      return this.http.get<any>(this.baseUrl + `auto-coding/${module}/names`);
  }
  getGenericDropdownList() {
      return this.http.get<any>(this.baseUrl + `auto-coding/generic-dropdown/names`);
  }
  getUserLegalEntity() {
    return this.http.get<any>(this.baseUrl + 'doctracker/user/le');
  }
  getCustomLegalEntityList() {
    return this.http.get<any>(this.baseUrl + 'admin/custom-profile/le');
  }
  postUploadInvoice(body: FormData) {
    return this.http.post(this.baseUrl + 'doctracker/upload', body);
  }
  getProfileDetails() {
    return this.http.get<any>(this.baseUrl + 'admin/user/profile');
  }
  getUserListJSONDetails() {
    return this.http.get<any>(this.baseUrl + 'admin/user/json-info');
  }
  getMasterDataController() {
    return this.http.post<any>(this.baseUrl + 'master/data-controller/list', {});
  }
  getAutocodeModifiedStats(body){
    return this.http.post<any>(this.baseUrl + 'doctracker/auto-coding-modified/stat',body);
  }
  getAutocodeInspectStats(body){
    return this.http.post<any>(this.baseUrl + 'doctracker/auto-coding-inspect/stat',body);
  }
  getAutocodeAutoManualStats(body){
    return this.http.post<any>(this.baseUrl + 'doctracker/auto-coding-manual/stat',body);
  }
  getActionTypeStats(body){
    return this.http.post<any>(this.baseUrl + 'doctracker/auto-manual/stat',body);
  }
  getDashBoardKPIStatusOverview(body){
    return this.http.post<any>(this.baseUrl + 'doctracker/status/stat',body);
  }
  getDashBoardKPILEOverview(body){
  return this.http.post<any>(this.baseUrl + 'doctracker/inspect/le/stat',body);
}
  getDashboardKPIErrorCategory(body){
    return this.http.post<any>(this.baseUrl + 'doctracker/error-category/stat',body);
  }
  getDashBoardKPIExceptionOverview(body){
    return this.http.post<any>(this.baseUrl + 'doctracker/user-action/stat',body);
  }
  getAutoEmailTemplateList(body,isBlob:boolean){
    if(isBlob){
      return this.http.post(this.baseUrl + 'email_template/auto/list',body,{observe: 'response', responseType: 'blob' });
    }else{
    return this.http.post<any>(this.baseUrl + 'email_template/auto/list',body);
    }
  }
  getManualEmailTemplateList(body,isBlob:boolean){
    if(isBlob){
      return this.http.post(this.baseUrl + 'email_template/manual/list',body,{observe: 'response', responseType: 'blob' });
    }else{
    return this.http.post<any>(this.baseUrl + 'email_template/manual/list',body);
    }
  }
  getBlackListEmail(body:any){
    return this.http.get<any>(this.baseUrl + 'etracker/email/blacklist/list?page='+body?.page+'&page_size='+body?.pageSize+'&order_by='+body?.orderBy+'&order_type='+body?.orderType+'&email='+body?.email+'&email_type='+body?.emailTypeId+'&email_format='+body?.emailFormat);
  }
  deleteAutoEmailTemplateById(id){
    return this.http.delete<any>(this.baseUrl + 'email_template/auto/'+id);
  }
  // deleteManualEmailTemplateById(id){
  //   return this.http.delete<any>(this.baseUrl + 'email_template/manual/'+id);
  // }
  deleteManualEmailTemplateById(id,isShared:boolean){
    if(isShared){
      return this.http.delete<any>(this.baseUrl + 'email_template/manual/'+id);
    }else{
    return this.http.delete<any>(this.baseUrl + 'email_template/manual/private/'+id);
    }
  }

  deleteManualAttachmentEmailTemplateById(body){
      return this.http.post<any>(this.baseUrl + 'email_template/attachment',body);
    }

  deleteBlacklistEmailConfig(id){
    return this.http.delete<any>(this.baseUrl + 'etracker/email/blacklist/'+id);
  }
  deleteEmailTemplateControllerById(id){
    return this.http.delete<any>(this.baseUrl + 'email_template/controller/'+id);
  }
  createAutoEmailTemplate(body){
    return this.http.post<any>(this.baseUrl + 'email_template/auto/create',body);
  }
  // createManualEmailTemplate(body){
  //   return this.http.post<any>(this.baseUrl + 'email_template/manual/create',body);
  // }
  createManualEmailTemplate(body,isShared:boolean){
    if(isShared){
      return this.http.post<any>(this.baseUrl + 'email_template/manual/create',body);
    }else{
    return this.http.post<any>(this.baseUrl + 'email_template/manual/private/create',body);
    }
  }
  addEmailTemplateAttachments(body){
    return this.http.post(this.baseUrl+'email_template/attachment/insert',body)
  }
  createBlacklistEmailTemplate(body){
    return this.http.post<any>(this.baseUrl + 'etracker/email/blacklist',body);
  }
  createEmailTemplateController(body){
    return this.http.post<any>(this.baseUrl + 'email_template/controller/create',body);
  }
  updateEmailTemplateController(body,templateId){
    return this.http.put(this.baseUrl + 'email_template/controller/'+templateId,body);
  }
  updateAutoEmailTemplate(body,templateId){
    return this.http.put(this.baseUrl + 'email_template/auto/'+templateId,body);
  }
  // updateManualEmailTemplate(body,templateId){
  //   return this.http.put(this.baseUrl + 'email_template/manual/'+templateId,body);
  // }

  updateManualEmailTemplate(body,templateId,isShared:boolean){
    if(isShared){
      return this.http.put(this.baseUrl + 'email_template/manual/'+templateId,body);
    }else{
    return this.http.put(this.baseUrl + 'email_template/manual/private/'+templateId,body);
  }
}
  // updateManualEmailTemplate(body:any,isShared:boolean){
  //   if(isShared){
  //     return this.http.put(this.baseUrl + 'email_template/manual/'+isShared,body);
  //   }else{
  //   return this.http.put<any>(this.baseUrl + 'email_template/manual/private/' + body.pk, body);
  //   }
  // }
  updateBlacklistEmailConfig(body,id){
    return this.http.put(this.baseUrl + 'etracker/email/blacklist/'+id,body);
  }
  getEmailTemplateCOntrollerList(body,isBlob:boolean){
    if(isBlob){
      return this.http.post(this.baseUrl + 'email_template/controller/list',body,{observe: 'response', responseType: 'blob' });
    }else{
    return this.http.post<any>(this.baseUrl + 'email_template/controller/list',body);
    }
  }
  getAutoEmailTemplateById(templateId){
    return this.http.get<any>(this.baseUrl + 'email_template/auto/'+templateId);
  }
  getManualEmailTemplateById(templateId){
    return this.http.get<any>(this.baseUrl + 'email_template/manual/'+templateId);
  }
  getBlacklistEmailConfigById(id){
    return this.http.get<any>(this.baseUrl + 'etracker/email/blacklist/'+id);
  }
  getEmailTypeList(){
    return this.http.post(this.baseUrl+'etracker/email_type/list',{});
  }
  getAnnouncementList(pageNumber, pageSize) {
    return this.http.get<any>(environment.baseApiUrl + 'announcement/list?page=' + pageNumber + '&page_size=' + pageSize)
  }
  downloadIBANReport(body){
        return this.http.post(environment.baseApiUrl+'doctracker/iban/report',body,{observe: 'response', responseType: 'blob' });
  }
  getErrorCategoryList(){
    return this.http.get(environment.baseApiUrl+'admin/error-code/category');
  }

  get loaderEnabled() {
    return CommonService.loaderEnabled;
  }

  public static showLoader() {
    CommonService.loaderEnabled = true;
  }

  public static hideLoader() {
    CommonService.loaderEnabled = false;
  }
}
