import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "../service/common/common.service";

/**
 * @export
 * @class Settings
 */

export namespace Settings {
  export abstract class DateFormats {
    public static readonly dateFormatBackend = "DD-MM-yyyy";
    public static readonly dateFormatFrontend = "yyyy-MM-DD HH:mm:ss";
    public static readonly dateFormatOnlyDate = "yyyy-MM-DD";
  }
  export abstract class AutoRefreshTime {
    public static readonly refreshTimeInMilliSeconds = 900000; //900000 milli-seconds are 15 minutes
  }
  export abstract class CommonFunctions {
    static userDetailsSubscription: Subscription;
    static commonService: CommonService;
    static preferenceListColumnSettings: any;
    static router: any;
    public static enumToArray(enumObject, objectBody) {
      return Object.keys(enumObject)
        .filter(key => !isNaN(Number(enumObject[key])))
        .map(key => ({ [objectBody.key1]: enumObject[key], [objectBody.key2]: key }));
    }
    public static    sortByKey(array: any[], key: string, order: 'asc' | 'desc') {
      return array.sort((a, b) => {
          const valueA = a[key];
          const valueB = b[key];

          if (typeof valueA === 'number' && typeof valueB === 'number') {
              return order === 'asc' ? valueA - valueB : valueB - valueA;
          } else if (typeof valueA === 'string' && typeof valueB === 'string') {
              const nameA = valueA.toLowerCase();
              const nameB = valueB.toLowerCase();
              if (nameA < nameB) {
                  return order === 'asc' ? -1 : 1;
              } else if (nameA > nameB) {
                  return order === 'asc' ? 1 : -1;
              }
          }

          return 0;
      });
  }
    public static enumCharToArray(enumObject: any): { id: string, name: string }[] {
      return Object.keys(enumObject).map(key => ({ id: key, name: enumObject[key] }));
    }
public static  getDatesFromDateRange(dateRange) {
  var dates = {
      startDate: null,
      endDate: null
  }
  if (dateRange) {
      if (dateRange[0] != "" && dateRange[1] != undefined) {
          dates.startDate = moment(dateRange[0]).format(Settings.DateFormats.dateFormatBackend);
          dates.endDate = moment(dateRange[1]).format(Settings.DateFormats.dateFormatBackend);
      }
      else if (dateRange[0] && (!dateRange[1])) {
          dates.startDate = moment(dateRange[0]).format(Settings.DateFormats.dateFormatBackend);
          dates.endDate = moment(dateRange[0]).format(Settings.DateFormats.dateFormatBackend);
      }
      else {
          dates.startDate = null;
          dates.endDate = null;
      }
  } else {
      dates.startDate = null;
      dates.endDate = null;
  }
  return dates;
}
    public static getPreferenceList(keyName) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (userDetails) {
        return userDetails?.preferences;
      }
    }
    public static hasMultipleExclusionsInFields(obj: any): boolean {
      if (!obj) {
        return false;
      }
      let exclusionCount = 0;
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'string') {
          const field = obj[key] as string;
          if (field.includes(',')) {
            const exclusions = field.split(',');
            const multipleExclusions = exclusions.filter(exclusion => exclusion.includes('!'));
            if (multipleExclusions.length > 1) {
              exclusionCount++;
            }
          } else {
            if (field.includes('!')) {
              exclusionCount++;
            }
          }
        }
      }
      return exclusionCount > 1;
    }
public static transformData(originalData: any[]) {
  const data = originalData.reduce((acc, item) => {
    const groupName = item.isShared==true ? 'Public' : item.isShared==false?'Private':'No Template Type';
    const existingGroup = acc.find(group => group.name === groupName);

    if (existingGroup) {
      existingGroup.items.push({ id: item.id, templateName: item.templateName });
    } else {
      acc.push({
        name: groupName,
        items: [{ id: item.id, templateName: item.templateName }]
      });
    }

    return acc;
  }, []);
  return data;
}   public static replaceAtWithEmails(text: string): string {
     const regex = /(\b\w+[\w.]*@\w+\.\w+\b)/g;
      return text.replace(regex, '#$1');
    }
    public static replaceHashWithEmails(text: string): string {
     const regex = /#(\b\w+[\w.]*@\w+\.\w+\b)/g;
      return text.replace(regex, "<b>$1</b>").replace(/\n/g, '<br>');;
    }
    public static getPrivilegeList() {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (userDetails) {
        return userDetails?.privileges;
      }
    }
    public static getDocumentTypeList() {
      const documentTypes = JSON.parse(localStorage.getItem('documentTypes'));
      if (documentTypes) {
        return documentTypes;
      }
    }
    public static getConfiguration() {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (userDetails) {
        return userDetails?.configuration;
      }
    }
    public static camelCaseToSnakeCase(obj) {
      let snakeObj = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
          snakeObj[snakeKey] = obj[key];
        }
      }
      return snakeObj;
    }
    public static getCurrentDateRangeforGrid(isFilter, receivedStartDate, receivedEndDate) {
      if (isFilter) {
        // var startDate: any = receivedStartDate ? new Date(receivedStartDate.split("-").reverse().join("-")) : null;
        // var endDate: any = receivedStartDate ? new Date(receivedEndDate.split("-").reverse().join("-")) : null;
        // startDate = startDate ? moment.utc(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)).format(Settings.DateFormats.dateFormatFrontend) : null;
        // endDate = endDate ? moment.utc(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999)).format(Settings.DateFormats.dateFormatFrontend) : null;

        var startDate: any = receivedStartDate ? moment.utc(receivedStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD'):null
        var endDate: any = receivedEndDate ? moment.utc(receivedEndDate, 'DD-MM-YYYY').format('YYYY-MM-DD'):null
        return { startDate, endDate };
      } else {
        // const currentDate = new Date();
        // const startDate = receivedStartDate ? null : moment.utc(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6, 0, 0, 0, 0)).format(Settings.DateFormats.dateFormatFrontend);
        // const endDate = receivedEndDate ? null : moment.utc(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999)).format(Settings.DateFormats.dateFormatFrontend);

        var currentDateforStart = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split("T")[0];
        var currentDateForEnd = new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0];

        const startDate = receivedStartDate ? null : moment.utc(currentDateforStart, 'YYYY-MM-DD').format('YYYY-MM-DD')
        const endDate = receivedEndDate ? null : moment.utc(currentDateForEnd, 'YYYY-MM-DD').format('YYYY-MM-DD')
        return { startDate, endDate };
      }
    }
    //Base 64 to Local URL
    public static decodeFile(fileSource,fileType) {
      if(fileType==2 || fileType==3){
        const byteArray = new Uint8Array(
          atob(fileSource)
            .split("")
            .map(char => char.charCodeAt(0))
        );
        const file = new Blob([byteArray], { type: "text/plain; charset=utf-8" });
        const textDecoder = new TextDecoder("utf-8");
        const decodedString = textDecoder.decode(byteArray);
        return decodedString
      }
      else{const byteArray = new Uint8Array(
        atob(fileSource)
          .split("")
          .map(char => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      return fileURL;
      }
    }
    public static   previewFile(base64Data: string,fileName:string): void {
      const binaryData = atob(base64Data);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const byteArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }
      let contentType: string;

      const fileType = fileName.split('.').pop()?.toLowerCase();
      switch (fileType) {
        case 'pdf':
          contentType = 'application/pdf';
          break;
          case 'txt':
          contentType = 'text/plain';
          break;
          case 'json':
          contentType = 'application/json';
          break;
          case 'xls':
          contentType = 'application/vnd.ms-excel';
          break;
          case 'xlsx':
          contentType='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
          case'docx':
          contentType='application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
          case 'gif':
          contentType = 'image/gif';
          break;
          case 'jpg':
          case 'jpeg':
          contentType = 'image/jpeg'; // Modify this according to your image type (e.g., image/png for PNG images)
          break;
          case 'png':
          contentType= 'image/png';
          break;
          default:
          console.error('Unsupported file type.');
          return;
      }
      const blob = new Blob([arrayBuffer], { type: contentType});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.target='_blank'
      link.click();
      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    }
    public static multipleEmailsValidator(sourceEmail): ValidatorFn {
      return (control: FormControl): { [key: string]: any } | null => {
        const emails: string = control.value;
        if (emails && emails?.length > 0) {
          const emailArray: string[] = emails?.split(',').map(email => email?.trim());
          const sourceEmailArray: string[] = sourceEmail.split(',').map(email => email.trim());
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const invalidEmails = emailArray.filter(email => !emailRegex.test(email));
          if (invalidEmails.length > 0) {
            return { invalidEmails: true };
          }
          const forbiddenEmail = emailArray.find(email => sourceEmailArray.includes(email));
          // if (forbiddenEmail) {
          //   return { forbiddenEmail: true }; // Return null if the forbidden email is found
          // }
        }
        return null;
      };
    }
    public static emailsValidator(): ValidatorFn {
      return (control: FormControl): { [key: string]: any } | null => {
        const emails: string[] = control.value;

        if (emails && emails.length > 0) {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const invalidEmails = emails.filter(email => !emailRegex.test(email));

          if (invalidEmails.length > 0) {
            return { invalidEmails: true };
          }
        }
        return null;
      };
    }
    public static customNumberValidator(control: FormControl): { [key: string]: any } | null {
      const pattern = /^(-)?\d*(?:\.\d*)*(?:,\d+)?$/;
      const value = control.value;

      if (value && !pattern.test(value)) {
        return { invalidNumber: true };
      }

      return null;
    }
  }
  export abstract class GridConfiguration {
    public static readonly outRuleTagList:string[]=["CHARGE_AMOUNT","CURRENCY","CUSTOMER_ADDRESS","CUSTOMER_CITY","CUSTOMER_ID","CUSTOMER_NAME","CUSTOMER_TAX_ID","CUSTOMER_ZIP","DELIVERY_PARTY","DISCOUNT_AMOUNT","DUE_DATE","HEADER\\TAX_AMOUNT","HEADER\\TAX_CODE","HEADER\\TAX_PERCENTAGE","INVOICE_DATE","INVOICE_NO","LINE\\EXTERNAL_ITEM_NUMBER","LINE\\ITEM_DESCRIPTION","LINE\\ITEM_NUMBER","LINE\\ITEM_QUANTITY","LINE\\LINE_AMOUNT","LINE\\LINE_CHARGE_AMOUNT","LINE\\LINE_DISCOUNT_AMOUNT","LINE\\LINE_DISCOUNT_PERCENTAGE","LINE\\LINE_NUMBER","LINE\\PURCHASE_ORDER","LINE\\QUANTITY_UOM","LINE\\TAX\\TAX_AMOUNT","LINE\\TAX\\TAX_CODE","LINE\\TAX\\TAX_PERCENTAGE","LINE\\UNIT_PRICE","PAYMENT_REFERENCE","PAYMENT_TERM","PURCHASE_ORDER","SUB_TOTAL","TOTAL_AMOUNT","TOTAL_TAX"
    ,"VENDOR_ADDRESS","VENDOR_CITY","VENDOR_IBAN","VENDOR_NAME","VENDOR_TAX_ID","VENDOR_ZIP"]
    public static readonly outRuleXMLTagList:string[]=["CHARGE_AMOUNT","CURRENCY","CUSTOMER_ADDRESS","CUSTOMER_CITY","CUSTOMER_ID","CUSTOMER_NAME","CUSTOMER_TAX_ID","CUSTOMER_ZIP","DELIVERY_PARTY","DISCOUNT_AMOUNT","DUE_DATE","HEADER\\TAX_AMOUNT","HEADER\\TAX_CODE","HEADER\\TAX_PERCENTAGE","INVOICE_DATE","INVOICE_NO","INVOICE_UUID","LINE\\EXTERNAL_ITEM_NUMBER","LINE\\ITEM_DESCRIPTION","LINE\\ITEM_NUMBER","LINE\\ITEM_QUANTITY","LINE\\LINE_AMOUNT","LINE\\LINE_CHARGE_AMOUNT","LINE\\LINE_DISCOUNT_AMOUNT","LINE\\LINE_DISCOUNT_PERCENTAGE","LINE\\LINE_NUMBER","LINE\\PURCHASE_ORDER","LINE\\QUANTITY_UOM","LINE\\TAX\\TAX_AMOUNT","LINE\\TAX\\TAX_CODE","LINE\\TAX\\TAX_PERCENTAGE","LINE\\UNIT_PRICE","PAYMENT_REFERENCE","PAYMENT_TERM","PDF_PATH","PURCHASE_ORDER","SUB_TOTAL","TOTAL_AMOUNT","TOTAL_TAX"
    ,"VENDOR_ADDRESS","VENDOR_CITY","VENDOR_IBAN","VENDOR_NAME","VENDOR_TAX_ID","VENDOR_ZIP"]
   public static readonly DocTrackerModuleKey: { display: string, value: string }[] = [
    { display: "Document ID", value: "invoice_no" },
    { display: "Vendor VAT ID", value: "vendor_vat" },
    { display: "PO Number", value: "po_number" },
    { display: "Legal Entity Name", value: "legal_entity_name" },
    { display: "Legal Entity VAT ID", value: "legal_entity_vat" },
    { display: "Vendor Name", value: "vendor_name" },
    { display: "Document Type", value: "document_type_id" },
    { display: "Test Indicator", value: "test_indicator" },
    { display: "Legal Entity City", value: "legal_entity_city" },
    { display: "Currency", value: "currency" },
    { display: "Total Amount", value: "total_amount" },
    { display: "Discount", value: "discount_amount" },
    { display: "Charge", value: "charge_amount" },
    { display: "Net", value: "net_amount" },
    { display: "VAT", value: "vat_amount" },
    { display: "Vendor City", value: "vendor_city" },
    { display: "Vendor Account", value: "vendor_id" },
    { display: "Legal Entity ID", value: "legal_entity_id" },
    { display: "Document Group", value: "message_type" },
    { display: "Document Date", value: "invoice_date" },
    { display: "Legal Entity Zip", value: "legal_entity_zip" },
    { display: "Payment Reference", value: "payment_reference" },
    { display: "Vendor IBAN", value: "vendor_iban" },
    { display: "Vendor Zip", value: "vendor_zip" },
    { display: "Due Date", value: "due_date" },
    { display: "Tax Code", value: "tax_code" },
    { display: "Tax Percentage", value: "tax_percentage" },
    { display: "Tax Amount", value: "tax_amount" },
  ];
  public static readonly AutoCodeFieldKey:{ display: string, value: string }[] = [
    { display: "Approver Type", value: "approver_type" },
    { display: "Procurement Category", value: "procurement_category" },
    { display: "Sales Tax Group", value: "tax_group" },
    { display: "Sales Tax Item Group", value: "tax_item_group" },
    { display: "Approver", value: "approver" },
    { display: "Business Unit", value: "business_unit" },
    { display: "Activity", value: "activity" },
    { display: "Financial Department", value: "financial_department" },
    { display: "Department", value: "department" },
    { display: "Cost Center", value: "cost_center" },
    { display: "Equipment", value: "equipment" },
    { display: "Market Products", value: "market_products" },
    { display: "Intercompany", value: "intercompany" },
    { display: "Segment", value: "segment" },
    { display: "Location", value: "location" },
    { display: "Bank Loans", value: "bank_loans" },
    { display: "Worker", value: "worker" },
    { display: "France Local COA", value: "france_local_coa" },
    { display: "Belgium Local COA", value: "belgium_local_coa" },
    { display: "Turkey Local COA", value: "turkey_local_coa" },
    { display: "Spain Local COA", value: "spain_local_coa" },
    { display: "Italy Local COA", value: "italy_local_coa" }
];

  public static readonly DocTrackerLineModuleKey: { display: string, value: string }[] = [{ display: "Item Track ID", value: "line_transaction_id" },
  { display: "PO No.", value: "purchase_order" },
  { display: "Line No.", value: "line_number" },
  { display: "Item No.", value: "item_number" },
  { display: "External Item No.", value: "external_item_number" },
  { display: "Item Description", value: "item_description" },
  { display: "Item Quantity", value: "item_quantity" },
  { display: "Quantity UOM", value: "quantity_uom" },
  { display: "Item Amount", value: "line_amount" },
  { display: "Item Discount Amount", value: "line_discount_amount" },
  { display: "Item Discount %", value: "line_discount_percentage" },
  { display: "Item Charge Amount", value: "line_charge_amount" },
  { display: "Unit Price", value: "unit_price" },
  { display: "Tax Code", value: "tax_code" },
  { display: "Tax Percentage", value: "tax_percentage" },
  { display: "Tax Amount", value: "tax_amount" },
];
    public static readonly documentField:string[]=  ["invoice_no", "vendor_vat", "po_number", "legal_entity_name", "legal_entity_vat","vendor_name","legal_entity_city", "currency", "total_amount", "discount_amount", "charge_amount", "net_amount","vat_amount", "vendor_city", "vendor_id", "legal_entity_id", "message_type","invoice_date", "legal_entity_zip", "payment_reference", "vendor_iban", "vendor_zip", "due_date"]
    public static readonly pageNumber: number = 1;
    public static readonly pageSize: number = 50;
    public static readonly pageSizeList: number[] = [50, 75, 100];
    public static readonly totalRecords: number = 0;
    public static readonly orderBy: String = "";
    public static readonly orderType: Number = 1;
    public static readonly docTrackerGridSchema=
      [{
        header: 'Status', field: 'currentStatusText', disabled: true, class: "status-column",
        sortable: true, freezed: true, checked: true
      },
      {
        header: 'Doc-Track ID', field: 'transactionId', disabled: true, class: 'tid-column',
        sortable: true,
        freezed: true, checked: true,filterable:true
      },
      {
        header: 'Document Type', field: 'documentTypeId', disabled: false, class: "doctype-column",
        sortable: true, checked: true
      },
      {
        header: 'LE ID', field: 'legalEntityId', disabled: false, class: "le-id-column",
        sortable: true, checked: true,filterable:true
      },
      {
        header: 'LE Name', field: 'legalEntityName', disabled: false, class: "le-name-column",
        sortable: true, checked: true
      },
      {
        header: 'Vendor Account', field: 'vendorId', disabled: false, class: "vendor-id-column",
        sortable: true, checked: true,filterable:true
      },
      {
        header: 'Vendor Name', field: 'vendorName', disabled: false, class: "vendor-name-column",
        sortable: true, checked: true,filterable:true
      },
      {
        header: 'PO Number', field: 'poNumber', disabled: false, class: "po-number-column",
        sortable: true, checked: true,filterable:true
      },
      {
        header: 'Document ID', field: 'invoiceNo', disabled: false, class: "invoice-column",
        sortable: true, checked: true,filterable:true
      },
      {
        header: 'Document Date', field: 'invoiceDate', disabled: false, class: "invoicedate-column",
        sortable: true, checked: true,filterable:true
      },
      {
        header: 'Amount', field: 'totalAmount', disabled: false, class: "amount-column",
        sortable: true, checked: true
      },
      {
        header: 'Currency', field: 'currency', disabled: false, class: "currency-column",
        sortable: true, checked: true
      }
        , {
        header: 'Destination', field: 'destination', disabled: false, class: "destapp-column",
        sortable: true, checked: true
      },
      {
        header: 'TI Number', field: 'tiNumber', disabled: false, class: "ti-number",
        sortable: false, checked: true,filterable:true
      },
      {
        header: 'Received (UTC)', field: 'fileFirstReceivedOn', disabled: false, class: "received-column",
        sortable: true, checked: true
      },
      {
        header: 'Document Group', field: 'messageType', disabled: false, class: "docgroup-column",
        sortable: true, checked: true
      },
      {
        header: 'File Type', field: 'fileType', disabled: false, class: "source-column",
        sortable: true, checked: true
      },
      {
        header: 'File Source', field: 'fileSource', disabled: false, class: "source-column",
        sortable: true, checked: true
      },
      {
        header: 'Language', field: 'language', disabled: false, class: "language-column",
        sortable: true, checked: true
      },
      {
        header: 'Pages', field: 'numberOfPage', disabled: false, class: "page-column",
        sortable: true, checked: true
      },
      {
        header: 'Assigned To', field: 'assignedTo', disabled: false, class: "assignedto-column",
        sortable: true, checked: true
      },
      {
        header: 'Action By', field: 'modifiedBy', disabled: false, class: "actiontakenby-column",
        sortable: true, checked: true
      },
      {
        header: 'Action At', field: 'modifiedOn', disabled: false, class: "action-taken-at-column",
        sortable: true, checked: true
      },
      {
        header: 'Test Indicator', field: 'testIndicator', disabled: false, class: "test-column",
        sortable: true, checked: true
      },
      {
        header: 'Document Provider', field: 'documentProvider', disabled: false, class: "docprovider-column",
        sortable: true, checked: true
      },
      {
        header: 'EDI Reference No.', field: 'ediControlNumber', disabled: false, class: "edi-control-column",
        sortable: true, checked: true
      },
      {
        header: 'Remarks', field: 'remarksData', disabled: false, class: "remark-column",
        sortable: true, checked: true,filterable:true
      },
      {
        header: 'VAT Due Date', field: 'vatDueDate', disabled: false, class: "processing-engine-column",
        sortable: true, checked: true
      },
      {
        header: 'Processing Engine', field: 'processingEngine', disabled: false, class: "processing-engine-column",
        sortable: true, checked: true
      },
      { header: 'Source File', field: 'sourceFile', disabled: false, sortable: false,  class: "sourcefile-column", checked: true },
      { header: 'Destination File', field: 'destFile', disabled: false, sortable: false,  class: "destfile-column", checked: true },
      { header: 'Attachments', field: 'isEmbeddedAvailable', disabled: false, sortable: false,  class: "destfile-column", checked: true }
      ]
    public static readonly  rolePrivilegeList = [
        {
          module: 'Document Tracker', id: 1,
          items: [
            { id: 1, name: 'View', value: 'VIEW_ALL_DOC_TRACKER_LISTS' },
            { id: 1, name: 'Actions', value: 'CREATE_DOC_TRACKER_ACTION' },
            { id: 1, name: 'Mark Completed', value: 'DOCUMENT_FORCE_COMPLETE_UPDATE' },
            { id: 1, name: 'File Download', value: 'DOWNLOAD_DOC_TRACKER_ATTACHMENT' },
            { id: 1, name: 'File Upload', value: 'UPLOAD_DOC_TRACKER_FILE' },
            { id: 1, name: 'Reset Destination', value: 'DOCUMENT_DESTINATION_UPDATE' },
            { id: 1, name: 'IBAN Report Download', value: 'DOWNLOAD_IBAN_REPORT_ATTACHMENT' },
          ]
        },
        {
          module: 'Document Tracker Line', id: 5,
          items: [
            { id: 5, name: 'View', value: 'DOCUMENT_LINE_LIST_VIEW' },
            { id: 5, name: 'Add', value: 'DOCUMENT_LINE_CREATE' },
            { id: 5, name: 'Update', value: 'DOCUMENT_LINE_UPDATE' },
            { id: 5, name: 'Delete', value: 'DOCUMENT_LINE_DELETE' },
          ]
        },
        {
          module: 'Document Tracker Auto Coding', id: 6,
          items: [
            { id: 6, name: 'View', value: 'AUTOCODE_DOCUMENT_VIEW' },
            { id: 6, name: 'Add/Update', value: 'AUTOCODE_DOCUMENT_CREATE_UPDATE' },
            { id: 6, name: 'Delete', value: 'AUTOCODE_DOCUMENT_DELETE' },
            { id: 6, name: 'Approve', value: 'AUTOCODE_APPROVER_CREATE' },
            { id: 6, name: 'Collapse', value: 'AUTOCODE_COLLAPSE_CREATE' },
          ]
        },
        {
          module: 'E Tracker', id: 10,
          items: [
            { id: 10, name: 'View', value: 'VIEW_ALL_E_TRACKER_EMAILS' },
            { id: 10, name: 'File Download', value: 'DOWNLOAD_E_TRACKER_EMAIL_ATTACHMENT' },
            { id: 10, name: 'Actions', value: 'CREATE_E_TRACKER_EMAIL_REPLY_TO' },
          ]
        },
        {
          module: 'Master Data: Legal Entities', id: 15,
          items: [
            { id: 15, name: 'View', value: 'VIEW_LEGAL_ENTITY_LIST' },
            { id: 15, name: 'Add/Update', value: 'CREATE_UPDATE_LEGAL_ENTITY' },
          ]
        },
        {
          module: 'Master Data: Purchase Orders', id: 20,
          items: [
            { id: 20, name: 'View', value: 'VIEW_PO_LIST' },
            { id: 20, name: 'Add', value: 'CREATE_PURCHASE_ORDER' },
            { id: 20, name: 'Update', value: 'UPDATE_PURCHASE_ORDER' },
            { id: 20, name: 'Delete', value: 'DELETE_PURCHASE_ORDER' },
          ]
        },
        {
          module: 'Master Data: Vendors', id: 25,
          items: [
            { id: 25, name: 'View', value: 'VIEW_VENDOR_LIST' },
            { id: 25, name: 'Add/Update', value: 'CREATE_UPDATE_VENDOR' },
            {id:25,name:'Add/Update Autocode',value:'VENDOR_AUTOCODE_MANAGE'},
          ]
        },
        {
          module: 'Master Data: AP Invoices', id: 30,
          items: [
            { id: 30, name: 'View', value: 'VIEW_AP_INVOICE_LIST' },
          ]
        },
        {
            module: 'AP Invoice: Extended AP Invoice', id: 31,
            items: [
              { id: 31, name: 'View', value: 'AP_INVOICE_LINE_VIEW' },
              { id: 31, name: 'Add', value: 'AP_INVOICE_LINE_CREATE' },
              { id: 31, name: 'Update', value: 'AP_INVOICE_LINE_UPDATE' },
              {id: 31, name: 'Delete', value: 'AP_INVOICE_LINE_DELETE'},
            ]
          },
        {
          module: 'Master Data: Currencies', id: 35,
          items: [
            { id: 35, name: 'View', value: 'VIEW_CURRENCY_LIST' },
            { id: 35, name: 'Add/Update', value: 'CREATE_UPDATE_CURRENCY' },
          ]
        },
        {
          module: 'Master Data: VAT Alias', id: 40,
          items: [
            { id: 40, name: 'View', value: 'VIEW_VAT_ALIAS_LIST' },
            { id: 40, name: 'Add/Update', value: 'CREATE_UPDATE_VAT_ALIAS' },
            { id: 40, name: 'Delete', value: 'DELETE_VAT_ALIAS' },
          ]
        },
        {
          module: 'Master Data: Vendor Reference Lookup', id: 41,
          items: [
            { id: 41, name: 'View', value: 'VIEW_REFERENCE_LOOKUP' },
            { id: 41, name: 'Add', value: 'CREATE_REFERENCE_LOOKUP' },
            { id: 41, name: 'Delete', value: 'DELETE_REFERENCE_LOOKUP' },
            { id: 41, name: 'Update', value: 'UPDATE_REFERENCE_LOOKUP' },
          ]
        },
        {
          module: 'Master Data: Company Name Alias', id: 45,
          items: [
            { id: 45, name: 'View', value: 'VIEW_NAME_ALIAS_LIST' },
            { id: 45, name: 'Add/Update', value: 'CREATE_UPDATE_NAME_ALIAS' },
            { id: 45, name: 'Delete', value: 'DELETE_NAME_ALIAS' },
          ]
        },
        {
          module: 'Master Data: ZIP Code Alias', id: 50,
          items: [
            { id: 50, name: 'View', value: 'VIEW_ZIP_ALIAS_LIST' },
            { id: 50, name: 'Add/Update', value: 'CREATE_UPDATE_ZIP_ALIAS' },
            { id: 50, name: 'Delete', value: 'DELETE_ZIP_ALIAS' },
          ]
        },
        {
          module: 'Master Data: City Alias', id: 55,
          items: [
            { id: 55, name: 'View', value: 'VIEW_CITY_ALIAS_LIST' },
            { id: 55, name: 'Add/Update', value: 'CREATE_UPDATE_CITY_ALIAS' },
            { id: 55, name: 'Delete', value: 'DELETE_CITY_ALIAS' },
          ]
        },
        {
          module: 'Master Data: Currency Alias', id: 60,
          items: [
            { id: 60, name: 'View', value: 'VIEW_CURRENCY_EQUIVALENT_LIST' },
            { id: 60, name: 'Add/Update', value: 'CREATE_UPDATE_CURRENCY_EQUIVALENT' },
            { id: 60, name: 'Delete', value: 'DELETE_CURRENCY_EQUIVALENT' },
          ]
        },
        {
          module: 'Administration: Users', id: 65,
          items: [
            { id: 65, name: 'View', value: 'VIEW_USER_LIST' },
            { id: 65, name: 'Add', value: 'CREATE_USER' },
            { id: 65, name: 'Update', value: 'UPDATE_USER' },
          ]
        },
        {
          module: 'Administration: Roles', id: 70,
          items: [
            { id: 70, name: 'View', value: 'VIEW_ROLE_LIST' },
            { id: 70, name: 'Add', value: 'CREATE_ROLE' },
            { id: 70, name: 'Update', value: 'UPDATE_ROLE' },
            { id: 70, name: 'Delete', value: 'DELETE_ROLE' },
          ]
        },
        {
          module: 'Administration: PO Prefix', id: 75,
          items: [
            { id: 75, name: 'View', value: 'VIEW_PO_PREFIX_LIST' },
            { id: 75, name: 'Add/Update', value: 'CREATE_UPDATE_PO_PREFIX' },
            { id: 75, name: 'Delete', value: 'DELETE_PO_PREFIX' },
          ]
        },
        {
          module: 'Administration: Document Action', id: 80,
          items: [
            { id: 80, name: 'View', value: 'VIEW_ACTION_LIST' },
            { id: 80, name: 'Add/Update', value: 'CREATE_UPDATE_STATUS_ACTION_ASSOCIATION' },
          ]
        },
        {
          module: 'Administration: Error Code List', id: 85,
          items: [
            { id: 85, name: 'View', value: 'VIEW_ERROR_CODE_LIST' },
            { id: 85, name: 'Add/Update', value: 'CREATE_UPDATE_ERROR_CODE_LIST' },
            { id: 85, name: 'Delete', value: 'DELETE_ERROR_CODE' },
          ]
        },
        {
          module: 'Administration: Language Intent', id: 90,
          items: [
            { id: 90, name: 'View', value: 'VIEW_DOCUMENT_IDENTIFIER_LIST' },
            { id: 90, name: 'Add/Update', value: 'CREATE_UPDATE_DOCUMENT_IDENTIFIER' },
            { id: 90, name: 'Delete', value: 'DELETE_DOCUMENT_IDENTIFIER' },
          ]
        },
        {
          module: 'Administration: Custom Profile List', id: 95,
          items: [
            { id: 95, name: 'View', value: 'VIEW_CUSTOM_PROFILE_LIST' },
            { id: 95, name: 'Add/Update', value: 'CREATE_UPDATE_CUSTOM_PROFILE' },
            { id: 95, name: 'Delete', value: 'DELETE_CUSTOM_PROFILE' },
          ]
        },
        {
          module: 'Administration: Blacklist Email Config', id: 96,
          items: [
            { id: 96, name: 'View', value: 'BLACKLIST_EMAIL_LIST_VIEW' },
            { id: 96, name: 'Add', value: 'BLACKLIST_EMAIL_CREATE' },
            { id: 96, name: 'Update', value: 'BLACKLIST_EMAIL_UPDATE' },
            { id: 96, name: 'Delete', value: 'BLACKLIST_EMAIL_DELETE' },
          ]
        },
        {
          module: 'Administration: User Activity Logs & Data Change Logger', id: 100,
          items: [
            { id: 100, name: 'View', value: 'VIEW_USER_ACTIVITY_LIST' },
          ]
        },
        {
          module: 'Administration: Document Type Management', id: 101,
          items: [
            { id: 101, name: 'Add', value: 'DOCUMENT_TYPE_CREATE' },
            { id: 101, name: 'Update', value: 'DOCUMENT_TYPE_UPDATE' },
            { id: 101, name: 'Delete', value: 'DOCUMENT_TYPE_DELETE' },
          ]
        },
        {
          module: 'Administration: System Announcement', id: 105,
          items: [
            { id: 105, name: 'View', value: 'VIEW_ANNOUNCEMENT' },
            { id: 105, name: 'Add/Update', value: 'CREATE_UPDATE_ANNOUNCEMENT' },
            { id: 105, name: 'Delete', value: 'DELETE_ANNOUNCEMENT' },
          ]
        },
        {
          module: 'Administration: Application Configuration', id: 110,
          items: [
            { id: 110, name: 'View', value: 'VIEW_APPLICATION_CONFIGURATION' },
            { id: 110, name: 'Update', value: 'UPDATE_APPLICATION_CONFIGURATION' }
          ]
        },
        {
          module: 'Administration: Auto Email Template', id: 115,
          items: [
            { id: 115, name: 'View', value: 'EMAIL_TEMPLATE_AUTO_LIST_VIEW' },
            { id: 115, name: 'Add', value: 'EMAIL_TEMPLATE_AUTO_CREATE' },
            { id: 115, name: 'Update', value: 'EMAIL_TEMPLATE_AUTO_UPDATE' },
            { id: 115, name: 'Delete', value: 'EMAIL_TEMPLATE_AUTO_DELETE' },
          ]
        },
        {
          module: 'Administration: Manual Email Template (Public)', id: 116,
          items: [
            { id: 116, name: 'View', value: 'EMAIL_TEMPLATE_MANUAL_LIST_VIEW' },
            { id: 116, name: 'Add', value: 'EMAIL_TEMPLATE_MANUAL_CREATE' },
            { id: 116, name: 'Update', value: 'EMAIL_TEMPLATE_MANUAL_UPDATE' },
            { id: 116, name: 'Delete', value: 'EMAIL_TEMPLATE_MANUAL_DELETE' },
            { id: 116, name: 'View Usage Count', value: 'EMAIL_TEMPLATE_USAGE_LIST_VIEW' },
          ]
        },
        {
          module: 'Administration: Manual Email Template (Private)', id: 117,
          items: [
            { id: 117, name: 'View', value: 'EMAIL_TEMPLATE_MANUAL_LIST_VIEW' },
            { id: 117, name: 'Add', value: 'EMAIL_TEMPLATE_MANUAL_PRIVATE_CREATE' },
            { id: 117, name: 'Update', value: 'EMAIL_TEMPLATE_MANUAL_PRIVATE_UPDATE' },
            { id: 117, name: 'Delete', value: 'EMAIL_TEMPLATE_MANUAL_PRIVATE_DELETE' },
            { id: 117, name: 'View Usage Count', value: 'EMAIL_TEMPLATE_USAGE_LIST_VIEW' },
          ]
        },
        {
          module: 'Administration: Cron Job Logger', id: 118,
          items: [
            { id: 118, name: 'View', value: 'CRONJOB_LIST_VIEW' },
          ]
        },
        {
            module: 'Administration: Autocode Action Logs', id: 119,
            items: [
              { id: 119, name: 'View', value: 'AUTOCODE_CHANGE_LOGGER_LIST' },
            ]
          },
        {
          module: 'EDI: Trading Partner Setup', id: 120,
          items: [
            { id: 120, name: 'View', value: 'TRADING_PARTNER_LIST_VIEW' },
            { id: 120, name: 'Add', value: 'TRADING_PARTNER_CREATE' },
            { id: 120, name: 'Delete', value: 'TRADING_PARTNER_DELETE' },
            { id: 120, name: 'Full Update', value: 'TRADING_PARTNER_UPDATE' },
            { id: 120, name: 'Partial Update', value: 'TRADING_PARTNER_PARTIAL_UPDATE' }
          ]
        },
        {
          module: 'EDI: Rules Setup', id: 125,
          items: [
            { id: 125, name: 'View', value: 'TRADING_PARTNER_RULE_LIST_VIEW' },
            { id: 125, name: 'Add/Update', value: 'TRADING_PARTNER_RULE_CREATE' },
            { id: 125, name: 'Delete', value: 'TRADING_PARTNER_RULE_DELETE' }
          ]
        },
        {
          module: 'EDI: Codelist', id: 130,
          items: [
            { id: 130, name: 'View', value: 'CODELIST_LIST_VIEW' },
            { id: 130, name: 'Add', value: 'CODELIST_CREATE' },
            { id: 130, name: 'Update', value: 'CODELIST_UPDATE' },
            { id: 130, name: 'Delete', value: 'CODELIST_DELETE' }
          ]
        },
        {
          module: 'EDI: Codelist Library', id: 135,
          items: [
            { id: 135, name: 'View', value: 'CODELIST_LIBRARY_LIST_VIEW' },
            { id: 135, name: 'Add', value: 'CODELIST_LIBRARY_CREATE' },
            { id: 135, name: 'Update', value: 'CODELIST_LIBRARY_UPDATE' },
            { id: 135, name: 'Delete', value: 'CODELIST_LIBRARY_DELETE' }
          ]
        },
        {
          module: 'EDI: Connectivity', id: 140,
          items: [
            { id: 140, name: 'View', value: 'CONNECTIVITY_LIST_VIEW' },
            { id: 140, name: 'Add/Update', value: 'CONNECTIVITY_CREATE_UPDATE' },
            { id: 140, name: 'Delete', value: 'CONNECTIVITY_DELETE' }
          ]
        },
        {
          module: 'E-XML Management', id: 145,
          items: [
            { id: 145, name: 'View', value: 'EINVOICE_PARTNER_LIST_VIEW' },
            { id: 145, name: 'Add', value: 'EINVOICE_PARTNER_CREATE' },
            { id: 145, name: 'Delete', value: 'EINVOICE_PARTNER_DELETE' },
            { id: 145, name: 'Update', value: 'EINVOICE_PARTNER_UPDATE' },
          ]
        },
        {
          module: 'E-XML Rules Setup', id: 150,
          items: [
            { id: 150, name: 'View', value: 'EINVOICE_RULE_LIST_VIEW' },
            { id: 150, name: 'Add/Update', value: 'EINVOICE_RULE_CREATE' },
            { id: 150, name: 'Delete', value: 'EINVOICE_RULE_DELETE' }
          ]
        },
        {
          module: 'Autocoding: Procurement Categories', id: 179,
          items: [
            { id: 179, name: 'View', value: 'VIEW_PROCUREMENT_CATEGORY' },
            { id: 179, name: 'Add', value: 'CREATE_PROCUREMENT_CATEGORY' },
            { id: 179, name: 'Delete', value: 'DELETE_PROCUREMENT_CATEGORY' },
            { id: 179, name: 'Update', value: 'UPDATE_PROCUREMENT_CATEGORY' },
          ]
        },
        {
          module: 'Autocoding: Activity (Financial Dimensions)', id: 180,
          items: [
            { id: 180, name: 'View', value: 'VIEW_ACTIVITY' },
            { id: 180, name: 'Add', value: 'CREATE_ACTIVITY' },
            { id: 180, name: 'Delete', value: 'DELETE_ACTIVITY' },
            { id: 180, name: 'Update', value: 'UPDATE_ACTIVITY' },
          ]
        },
        {
          module: 'Autocoding: Bank Loan (Financial Dimensions)', id: 181,
          items: [
            { id: 181, name: 'View', value: 'VIEW_BANK_LOAN' },
            { id: 181, name: 'Add', value: 'CREATE_BANK_LOAN' },
            { id: 181, name: 'Delete', value: 'DELETE_BANK_LOAN' },
            { id: 181, name: 'Update', value: 'UPDATE_BANK_LOAN' },
          ]
        },
        {
          module: 'Autocoding: Business Unit (Financial Dimensions)', id: 182,
          items: [
            { id: 182, name: 'View', value: 'VIEW_BUSINESS_UNIT' },
            { id: 182, name: 'Add', value: 'CREATE_BUSINESS_UNIT' },
            { id: 182, name: 'Delete', value: 'DELETE_BUSINESS_UNIT' },
            { id: 182, name: 'Update', value: 'UPDATE_BUSINESS_UNIT' },
          ]
        },
        {
          module: 'Autocoding: Cost Center (Financial Dimensions)', id: 183,
          items: [
            { id: 183, name: 'View', value: 'VIEW_COST_CENTRE' },
            { id: 183, name: 'Add', value: 'CREATE_COST_CENTRE' },
            { id: 183, name: 'Delete', value: 'DELETE_COST_CENTRE' },
            { id: 183, name: 'Update', value: 'UPDATE_COST_CENTRE' },
          ]
        },
        {
          module: 'Autocoding: Equipment (Financial Dimensions)', id: 184,
          items: [
            { id: 184, name: 'View', value: 'VIEW_EQUIPMENT' },
            { id: 184, name: 'Add', value: 'CREATE_EQUIPMENT' },
            { id: 184, name: 'Delete', value: 'DELETE_EQUIPMENT' },
            { id: 184, name: 'Update', value: 'UPDATE_EQUIPMENT' },
          ]
        },
        {
          module: 'Autocoding: Financial Department (Financial Dimensions)', id: 185,
          items: [
            { id: 185, name: 'View', value: 'VIEW_FINANCIAL_DEPARTMENT' },
            { id: 185, name: 'Add', value: 'CREATE_FINANCIAL_DEPARTMENT' },
            { id: 185, name: 'Delete', value: 'DELETE_FINANCIAL_DEPARTMENT' },
            { id: 185, name: 'Update', value: 'UPDATE_FINANCIAL_DEPARTMENT' },
          ]
        },
        {
          module: 'Autocoding: Intercompany (Financial Dimensions)', id: 186,
          items: [
            { id: 186, name: 'View', value: 'VIEW_INTER_COMPANY' },
            { id: 186, name: 'Add', value: 'CREATE_INTER_COMPANY' },
            { id: 186, name: 'Delete', value: 'DELETE_INTER_COMPANY' },
            { id: 186, name: 'Update', value: 'UPDATE_INTER_COMPANY' },
          ]
        },
        {
          module: 'Autocoding: Location (Financial Dimensions)', id: 187,
          items: [
            { id: 187, name: 'View', value: 'VIEW_LOCATION' },
            { id: 187, name: 'Add', value: 'CREATE_LOCATION' },
            { id: 187, name: 'Delete', value: 'DELETE_LOCATION' },
            { id: 187, name: 'Update', value: 'UPDATE_LOCATION' },
          ]
        },
        {
          module: 'Autocoding: Market Products (Financial Dimensions)', id: 188,
          items: [
            { id: 188, name: 'View', value: 'VIEW_MARKET_PRODUCT' },
            { id: 188, name: 'Add', value: 'CREATE_MARKET_PRODUCT' },
            { id: 188, name: 'Delete', value: 'DELETE_MARKET_PRODUCT' },
            { id: 188, name: 'Update', value: 'UPDATE_MARKET_PRODUCT' },
          ]
        },
        {
          module: 'Autocoding: Segment (Financial Dimensions)', id: 189,
          items: [
            { id: 189, name: 'View', value: 'VIEW_SEGMENT' },
            { id: 189, name: 'Add', value: 'CREATE_SEGMENT' },
            { id: 189, name: 'Delete', value: 'DELETE_SEGMENT' },
            { id: 189, name: 'Update', value: 'UPDATE_SEGMENT' },
          ]
        },
        {
          module: 'Autocoding: Worker (Financial Dimensions)', id: 190,
          items: [
            { id: 190, name: 'View', value: 'VIEW_WORKER' },
            { id: 190, name: 'Add', value: 'CREATE_WORKER' },
            { id: 190, name: 'Delete', value: 'DELETE_WORKER' },
            { id: 190, name: 'Update', value: 'UPDATE_WORKER' },
          ]
        },
        {
          module: 'Autocoding: Belgium Local CoA (Financial Dimensions)', id: 191,
          items: [
            { id: 191, name: 'View', value: 'VIEW_BELGIUM_LOCAL_COA' },
            { id: 191, name: 'Add', value: 'CREATE_BELGIUM_LOCAL_COA' },
            { id: 191, name: 'Delete', value: 'DELETE_BELGIUM_LOCAL_COA' },
            { id: 191, name: 'Update', value: 'UPDATE_BELGIUM_LOCAL_COA' },
          ]
        },
        {
          module: 'Autocoding: France Local CoA (Financial Dimensions)', id: 192,
          items: [
            { id: 192, name: 'View', value: 'VIEW_FRANCE_LOCAL_COA' },
            { id: 192, name: 'Add', value: 'CREATE_FRANCE_LOCAL_COA' },
            { id: 192, name: 'Delete', value: 'DELETE_FRANCE_LOCAL_COA' },
            { id: 192, name: 'Update', value: 'UPDATE_FRANCE_LOCAL_COA' },
          ]
        },
        {
          module: 'Autocoding: Italy Local CoA (Financial Dimensions)', id: 193,
          items: [
            { id: 193, name: 'View', value: 'VIEW_ITALY_LOCAL_COA' },
            { id: 193, name: 'Add', value: 'CREATE_ITALY_LOCAL_COA' },
            { id: 193, name: 'Delete', value: 'DELETE_ITALY_LOCAL_COA' },
            { id: 193, name: 'Update', value: 'UPDATE_ITALY_LOCAL_COA' },
          ]
        },
        {
          module: 'Autocoding: Spain Local CoA (Financial Dimensions)', id: 194,
          items: [
            { id: 194, name: 'View', value: 'VIEW_SPAIN_LOCAL_COA' },
            { id: 194, name: 'Add', value: 'CREATE_SPAIN_LOCAL_COA' },
            { id: 194, name: 'Delete', value: 'DELETE_SPAIN_LOCAL_COA' },
            { id: 194, name: 'Update', value: 'UPDATE_SPAIN_LOCAL_COA' },
          ]
        },
        {
          module: 'Autocoding: Turkey Local CoA (Financial Dimensions)', id: 195,
          items: [
            { id: 195, name: 'View', value: 'VIEW_TURKEY_LOCAL_COA' },
            { id: 195, name: 'Add', value: 'CREATE_TURKEY_LOCAL_COA' },
            { id: 195, name: 'Delete', value: 'DELETE_TURKEY_LOCAL_COA' },
            { id: 195, name: 'Update', value: 'UPDATE_TURKEY_LOCAL_COA' },
          ]
        },
        {
          module: 'Autocoding: Main Accounts', id: 196,
          items: [
            { id: 196, name: 'View', value: 'VIEW_MAIN_ACCOUNT' },
            { id: 196, name: 'Add', value: 'CREATE_MAIN_ACCOUNT' },
            { id: 196, name: 'Delete', value: 'DELETE_MAIN_ACCOUNT' },
            { id: 196, name: 'Update', value: 'UPDATE_MAIN_ACCOUNT' },
          ]
        },
        {
          module: 'Autocoding: Derived Financial Dimensions', id: 197,
          items: [
            { id: 197, name: 'View', value: 'VIEW_DERIVED_FINANCIAL_DIMENSIONS' },
            { id: 197, name: 'Add', value: 'CREATE_DERIVED_FINANCIAL_DIMENSIONS' },
            { id: 197, name: 'Delete', value: 'DELETE_DERIVED_FINANCIAL_DIMENSIONS' },
            { id: 197, name: 'Update', value: 'UPDATE_DERIVED_FINANCIAL_DIMENSIONS' },
          ]
        },
  {
    module: 'Autocoding: Item Sales Tax Groups', id: 198,
    items: [
      { id: 198, name: 'View', value: 'VIEW_ITEM_SALES_TAX_GROUPS' },
      { id: 198, name: 'Add', value: 'CREATE_ITEM_SALES_TAX_GROUPS' },
      { id: 198, name: 'Delete', value: 'DELETE_ITEM_SALES_TAX_GROUPS' },
      { id: 198, name: 'Update', value: 'UPDATE_ITEM_SALES_TAX_GROUPS' },
    ]
  },
  {
    module: 'Autocoding: Sales Tax Groups', id: 199,
    items: [
      { id: 199, name: 'View', value: 'VIEW_TAX_GROUP' },
      { id: 199, name: 'Add', value: 'CREATE_TAX_GROUP' },
      { id: 199, name: 'Delete', value: 'DELETE_TAX_GROUP' },
      { id: 199, name: 'Update', value: 'UPDATE_TAX_GROUP' },
    ]
  },
  {
    module: 'Autocoding: Number Sequence Groups', id: 200,
    items: [
      { id: 200, name: 'View', value: 'VIEW_NUMBER_SEQUENCE_GROUP' },
      { id: 200, name: 'Add', value: 'CREATE_NUMBER_SEQUENCE_GROUP' },
      { id: 200, name: 'Delete', value: 'DELETE_NUMBER_SEQUENCE_GROUP' },
      { id: 200, name: 'Update', value: 'UPDATE_NUMBER_SEQUENCE_GROUP' },
    ]
  },
  {
    module: 'Autocoding: Single Approver (Approvers)', id: 201,
    items: [
      { id: 201, name: 'View', value: 'VIEW_SINGLE_APPROVER' },
      { id: 201, name: 'Add', value: 'CREATE_SINGLE_APPROVER' },
      { id: 201, name: 'Delete', value: 'DELETE_SINGLE_APPROVER' },
      { id: 201, name: 'Update', value: 'UPDATE_SINGLE_APPROVER' },
    ]
  },
  {
    module: 'Autocoding: Group Approver (Approvers)', id: 202,
    items: [
      { id: 202, name: 'View', value: 'VIEW_GROUP_APPROVER' },
      { id: 202, name: 'Add', value: 'CREATE_GROUP_APPROVER' },
      { id: 202, name: 'Delete', value: 'DELETE_GROUP_APPROVER' },
      { id: 202, name: 'Update', value: 'UPDATE_GROUP_APPROVER' },
    ]
  }
      ]
    public static readonly errorLogSchema: {}[] = [{ header: "Code", field: "errorCode", class: "error-code" },
    { header: "Message", field: "message", class: "error-message" },
    { header: "Data", field: "traceMessage", class: "trace-message" }]
    public static readonly acknowledgementLogs: {}[] = [{ header: "Status", field: "status", class: "status" },
    { header: "Message", field: "message", class: "error-message" },
    { header: "Received On(UTC)", field: "receivedOn", class: "received-on" }]
  }
  export abstract class CommentId {
    public static readonly eTrackerCommentId: number = 1;
    public static readonly docTrackerCommentId: number = 2;
    public static readonly eTrackerRemarkId: number = 3;
    public static readonly docTrackerRemarkId: number = 4;
  }
  export abstract class Status {
    public static readonly eTrackerStatusList: {}[] = [{ statusId: 2, statusName: "Exception" }, { statusId: 1, statusName: "Processed" },{ statusId: 3, statusName: "Returned" }]
  }
  export abstract class AngularEditorConfiguration {
    public static readonly editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '100px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter Your Message* here...',
      defaultParagraphSeparator: '',
      defaultFontSize: '',
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['italic'],
        [
          'undo',
          'redo',
          'subscript',
          'superscript',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'heading',
          'fontName'
        ],
        [
          'fontSize',
          'backgroundColor',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode'
        ]
      ]
    };
  }
  export enum DocStatus {
    "AUTOCODE GENERATED"=95,
    "COMPLETED" = 35,
    "DISCARDED" = 45,
    "INSPECT" = 40,
    "INSPECT AUTO CODING"=80,
    "ON HOLD" = 60,
    "PENDING"=75,
    "PENDING ACK" = 30,
    "PENDING VALIDATION" = 15,
    "PROCESSING" = 10,
    "RECEIVED" = 5,
    "REJECTED" = 50,
    "REJECTED AND REPLIED" = 55,
    "SENDING TO DESTINATION" = 65,
    "SNOOZE" = 70,
    "VALIDATED" = 20,
    "WAITING FOR REVIEW" = 25,
    "WAITING FOR AUTO CODING GENERATION"=85,
    "WAITING FOR AUTO CODING REVIEW"=90
  }
  export enum ExcepptionGraphActions{
  "REPROCESS"=5,
 "EDITED & REPROCESS"=10,
 "RESCAN"=15,
 "DISCARD"=20,
 "REJECT"=25,
 "RELEASE"=30,
  "RESEND"=35
  }
  export enum ProcessingEngine{
    "AZURE"=1,
    "CUSTOM"=2,
    "EDI"=3,
    "XML"=4
    }
  export enum ExceptionOverviewColorDrillDown{
    "#ed8b00"=5,
    "#002b45"=10,
    "#f8d4a1"=15,
    "#0D5E25"=20,
    "#BC2030"=25,
    "#B8B8B7"=30,
    "#1B5786"=35
  }
  export enum ErrorCategory {
    "Acknowledgement" = 5,
    "AI Extraction" = 10,
    "Amount" = 15,
    "Currency" = 20,
    "LE" = 25,
    "PO" = 30,
    "Vendor" = 35,
    "Other" = 40,
    "EDI" = 45
  }
  export enum ErrorOverviewDrilldownColor{
    "#ed8b00" = 5,
    "#002b45" = 10,
    "#f8d4a1" = 15,
    "#0D5E25" = 20,
    "#047676" = 25,
    "#9A182E" = 30,
    "#B8B8B7" = 35,
    "#1B5786" = 40,
    "#4D4E4C" = 45
  }
  export enum FileFormatEDI{
   "EDIFACT"=1,
   "X12"=2
  }
  export enum MessageTypeEDI{
    I="IN",
    O="OUT"
  }
export enum ConnectivityType{
  "FTP"=5,
  "SFTP"=10,
	"API"=15,
	"ESB"=20,
	// "AS2"=25,
	"FSA"=30,
  "SMTP"=35,
  // "OFTP"=40
}
  export enum EmailTemplateFormat{
    "Email"=1,
    "Domain"=2,
    "Email Regex"=3
  }
  export enum NotificationType {
    "ETRACKER_ASSIGN" = 5,
    "ETRACKER_COMMENT" = 10,
    "DOCTRACKER_ASSIGN" = 15,
    "DOCTRACKER_COMMENT" = 20
  }
  export enum TestIndicator {
    "PROD" = 2,
    "TEST" = 1
  }
  export enum DocumentProvider {
    "VENDOR" = 1,
    "INTERCOMPANY" = 2
  }
  export enum PreferenceType {
    "DOCTRACKER_COLUMN" = 4,
    "DOCTRACKER_FILTER" = 3,
    "ETRACKER_COLUMN" = 2,
    "ETRACKER_FILTER" = 1
  }
  export enum DMSModule {
    "DOCTRACKER" = 10,
    "ETRACKER" = 20
  }
  export enum DMSAction {
    "E"="Export",
    "EM"= "Email",
    "R" = "Read",
    "RS" = "Rescan",
    "S"= "SEND"
  }
  export enum MessageType {
    "COST INVOICE" = 0,
    "PO INVOICE" = 1
  }
  export enum FileType {
    "EDI" = 2,
    "PDF" = 1,
    "XML" = 3
  }
  export enum FileSource {
    "EMAIL" = 1,
    "UI" = 2,
    "SFTP" = 3,
    "XMLBLOB"=4
  }
  export enum DocumentType {
    "CREDIT NOTE" = 1,
    "INTEREST NOTE" = 2,
    "INVOICE" = 3,
    "UNIDENTIFIED" = 4
  }
  export enum ETrackerStatus {
    "EXCEPTION" = 2,
    "PROCESSED" = 1,
    "RETURNED"=3
  }
  export enum VendorGroup {
    "EXTSUPPL" = 2,
    "ICSUP" = 1
  }
  export enum Module {
    "DOCUMENT_TRACKER" = 10,
    "E_TRACKER" = 20,
    "DASHBOARD" = 30,
    "LEGAL_ENTITY_MDM" = 40,
    "PO_MDM" = 50,
    "VENDOR_MDM" = 60,
    "VAT_ALIAS" = 70,
    "NAME_ALIAS" = 80,
    "ZIP_ALIAS" = 90,
    "CITY_ALIAS" = 100,
    "CURRENCY_MDM" = 110,
    "CURRENCY_ALIAS" = 120,
    "USER_MANAGEMENT" = 130,
    "ROLE_MANAGEMENT" = 140,
    "PO_PREFIX_MANAGEMENT" = 150,
    "DOCUMENT_ACTION_MANAGEMENT" = 160,
    "ERROR_CODE_LIST" = 170,
    "LANGUAGE_INTENT_ALIAS" = 180,
    "CUSTOM_PROFILE_LIST" = 190,
    "EMAIL_TEMPLATE" = 200,
    "APPLICATION_CONFIGURATION" = 210,
    "ANNOUNCEMENT_MANAGEMENT" = 220,
    "DOCUMENT_TRACKER_LINE" = 230,
    "TRADING_PARTNER_MANAGEMENT" = 240,
    "TRADING_PARTNER_RULE_MANAGEMENT" = 250,
    "CODELIST_MANAGEMENT" = 260,
    "CONNECTIVITY_MANAGEMENT" = 270,
    "USER_PREFERENCE" = 280,
    "DOCUMENT_TYPE_MANAGEMENT" = 290,
    "BLACKLIST_EMAIL" = 300,
    "EINVOICE_PARTNER_MANAGEMENT" = 310,
    "EINVOICE_RULE_MANAGEMENT" = 320,
    "REFERENCE_LOOKUP" = 330,
    "BANK_LOAN" = 340,
    "BUSINESS_UNIT" = 350,
    "COST_CENTER" = 360,
    "EQUIPMENT" = 370,
    "FINANCIAL_DEPARTMENT" = 380,
    "INTERCOMPANY" = 390,
    "LOCATION" = 400,
    "MARKET_PRODUCTS" = 410,
    "SEGMENT" = 420,
    "WORKER" = 430,
    "BELGIUM_LOCAL_COA" = 440,
    "FRANCE_LOCAL_COA" = 450,
    "ITALY_LOCAL_COA" = 460,
    "SPAIN_LOCAL_COA" = 470,
    "TURKEY_LOCAL_COA" = 480,
    "ACTIVITY" = 490,
    "PROCUREMENT_CATEGORY" = 500,
    "MAIN_ACCOUNTS" = 510,
    "SALES_TAX_GROUPS" = 520,
    "ITEM_SALES_TAX_GROUPS" = 530,
    "NUMBER_SEQUENCE_GROUPS" = 540,
    "DERIVED_FINANCIAL_DIMENSIONS" = 550,
    "SINGLE_APPROVER" = 560,
    "GROUP_APPROVER" = 570
}

  export enum EnvironmentType {
    "DEV" = 1,
    "PROD" = 2,
    "TEST" = 3,
    "UAT" = 4,
    "TRAIN" = 5,
    "DR" = 6
  }
  export enum ActionsOnDoc {
    "REPROCESS" = 3,
    "RESEND" = 4,
    "RELEASE" = 2,
    "CANCEL" = 1,
    "RESCAN" = 5,
    "REJECT" = 6,
    "DISCARD" = 7,
    "MARK AS COMPLETED" = 8,
  }
  export enum SourceSystem {
    "D365FO" = 10,
    "Sertica" = 20,
    "LS Retail" = 30,
    "Velocity"= 40
  }
  export enum DestinationType {
    "D365FO" = 10,
    "Sertica" = 20,
    "LS Retail" = 30,
    "Velocity"= 40
  }

  export enum EmailType {
    "REPLY" = 1,
    "FORWARD" = 2,
    "RETURN" = 3,
    "USER SIGNUP" = 4,
    "USER APPROVAL" = 5,
    "REJECTION" = 6,
    "ERROR" = 7,
    "ASSIGNMENT" = 8,
    "NOTIFICATION" = 9
  }
  export enum StatusOverviewColorDrillDown {
    "#00B050" = 35, //Completed
    "#BC2030" = 40, //Error
    "#B8B8B7" = 60, //On Hold
    "#ED8B00" = 45, //Discarded
    "#4D4E4C" = 50, //Rejected
    "#68B2E3" = 30, //Pending Ack
    "#1B5786" = 25  //Waiting For Review
  }
  export enum ActionType {
    D = "DELETE",
    I = "INSERT",
    S = "SELECT/SEARCH",
    U = "UPDATE",
    E ="EXPORT",
    SA="SAVE",
    "RE"="REPROCESS",
    "RS"="RESCAN",
    "RN"="RESEND",
    "RL"="RELEASE",
    "RJ"="REJECT",
    "DI"="DISCARD",
    "ED"="EDIT & REPROCESS"
  }
  export abstract class RegexPattern {
    public static readonly removeSpace: any = "^(?!\\s+$).+";
    public static readonly whiteSpace:any="^(?!\\s*$)[\\s\\S]+";
    public static readonly validMultipleEmail: any = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$";
    public static readonly validEmail: any = "^(?! )[a-zA-Z0-9_\.#$%&^`{|}~\+\-/\!\'*\+\-\/=\?\"]+@([a-zA-Z0-9-]+[.]){1,2}[a-zA-Z]+ *$";
    public static readonly validPhone: any = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,50}$";
    public static readonly removeSpecialCharacters: any = "^[a-zA-Z0-9]{4,10}$"
    public static readonly DomainRegex = "^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$";
    public static readonly MultipleValidEmail: any = /^([a-zA-Z0-9+.\-\_]+@[a-zA-Z0-9\-\_]+\.[a-zA-Z0-9.\-\_]+)((,| ,|, | , |){1}[a-zA-Z0-9+.\-\_]+@[a-zA-Z0-9\-\_]+\.[a-zA-Z0-9.\-\_]+)*$/;
  }
}
