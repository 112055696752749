import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { StorageService } from '../../service';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonService } from '../../service/common/common.service';
@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    model: {}[];
    items: MenuItem[];
    priviligeList: any;
    userDetailsSubscription: Subscription;
    private commonService: CommonService;
    constructor(public app: AppComponent, public storageService: StorageService, _commonService: CommonService) {
        this.commonService = _commonService;
    }
    ngOnInit() {
        this.userDetailsSubscription = this.commonService.getPrivelegeEvent().subscribe((res) => {
            this.priviligeList = res?.privileges;
            this.items = [
                {
                    label: 'Document Tracker',
                    icon: 'fas fa-file-signature',
                    routerLink: ['document'],
                    visible: this.priviligeList?.includes('VIEW_ALL_DOC_TRACKER_LISTS'),
                    tooltipOptions: {
                        tooltipLabel: "Document Tracker"
                    },
                    routerLinkActiveOptions:{
                        exact: true
                       }
                },
                {
                    label: 'E-Tracker',
                    icon: 'far fa-mail-bulk',
                    routerLink: ['email-tracker'],
                    visible: this.priviligeList?.includes('VIEW_ALL_E_TRACKER_EMAILS'),
                    tooltipOptions: {
                        tooltipLabel: "E-Tracker"
                    },
                    routerLinkActiveOptions:{
                        exact: true
                       }
                },
                {
                    label: 'Dashboard',
                    icon: 'fas fa-poll',
                    routerLink:['dashboard/dashboard'],
                    tooltipOptions: {
                        tooltipLabel: "Dashboard"
                    },
                    visible:true,
                    routerLinkActiveOptions:{
                        exact: true
                       }
                },
                {
                    label: 'Auto Coding Dashboard',
                    icon: 'fas fa-poll',
                    routerLink:['dashboard/autocoding-dashboard'],
                    tooltipOptions: {
                        tooltipLabel: "Auto Coding Dashboard"
                    },
                    visible:true,
                    routerLinkActiveOptions:{
                        exact: true
                       }
                },
                {
                    label: 'Master Data',
                    icon: 'fas fa-list',
                    visible: this.priviligeList?.includes('VIEW_LEGAL_ENTITY_LIST') || this.priviligeList?.includes('VIEW_VENDOR_LIST') || this.priviligeList?.includes('VIEW_CURRENCY_LIST') || this.priviligeList?.includes('VIEW_PO_LIST') || this.priviligeList?.includes('VIEW_VAT_ALIAS_LIST') || this.priviligeList?.includes('VIEW_NAME_ALIAS_LIST') || this.priviligeList?.includes('VIEW_CITY_ALIAS_LIST') || this.priviligeList?.includes('VIEW_ZIP_ALIAS_LIST') || this.priviligeList?.includes('VIEW_AP_INVOICE_LIST') || this.priviligeList?.includes('VIEW_CURRENCY_EQUIVALENT_LIST'),
                    tooltipOptions: {
                        tooltipLabel: "Master Data"
                    },
                    expanded:window.location.href.includes('master-data'),
                    id:'master',
                    target: 'self',
                    items: [
                        {
                            label: 'Legal Entities',
                            icon: 'fas fa-file-contract',
                            routerLink: ['master-data/le-management'],
                            visible: this.priviligeList?.includes('VIEW_LEGAL_ENTITY_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Legal Entities"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Purchase Orders',
                            icon: 'fas fa-money-check-edit-alt',
                            routerLink: ['master-data/po-management'],
                            visible: this.priviligeList?.includes('VIEW_PO_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Purchase Orders"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Vendors',
                            icon: 'fas fa-inventory',
                            routerLink: ['master-data/vendor-management'],
                            visible: this.priviligeList?.includes('VIEW_VENDOR_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Vendors"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'AP Invoices',
                            icon: 'fas fa-file-invoice',
                            routerLink: ['master-data/ap-invoice-export'],
                            visible: this.priviligeList?.includes('VIEW_AP_INVOICE_LIST'),
                              tooltipOptions: {
                                tooltipLabel: "AP Invoices"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        }  ,
                        {
                            label: 'Currencies',
                            icon: 'fal fa-rupee-sign',
                            routerLink: ['master-data/currency-management'],
                            visible: this.priviligeList?.includes('VIEW_CURRENCY_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Currencies"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'VAT Alias',
                            icon: 'fas fa-wallet',
                            routerLink: ['master-data/vat-alias'],
                            visible: this.priviligeList?.includes('VIEW_VAT_ALIAS_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "VAT Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Company Name Alias',
                            icon: 'fas fa-id-card',
                            routerLink: ['master-data/name-alias'],
                            visible: this.priviligeList?.includes('VIEW_NAME_ALIAS_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Company Name Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'ZIP Code Alias',
                            icon: 'far fa-map-marked-alt',
                            routerLink: ['master-data/zipcode-alias'],
                            visible: this.priviligeList?.includes('VIEW_ZIP_ALIAS_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "ZIP Code Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'City Alias',
                            icon: 'fas fa-city',
                            routerLink: ['master-data/city-alias'],
                            visible: this.priviligeList?.includes('VIEW_CITY_ALIAS_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "City Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Currency Alias',
                            icon: 'fas fa-sack-dollar',
                            routerLink: ['master-data/currencyalias-management'],
                            visible: this.priviligeList?.includes('VIEW_CURRENCY_EQUIVALENT_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Currency Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        } ,
                        {
                            label: 'Vendor Reference Lookup',
                            icon: 'fal fa-file-search',
                            routerLink: ['master-data/reference-lookup'],
                            visible: this.priviligeList?.includes('VIEW_REFERENCE_LOOKUP'),
                            tooltipOptions: {
                                tooltipLabel: "Vendor Reference Lookup"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        }
                    ]
                },
                {
                    label: 'Auto Coding ',
                    icon: 'fas fa-robot',
                    id:'autocoding',
                    tooltipOptions: {
                        tooltipLabel: "Auto Coding"
                    },
                    expanded:window.location.href.includes('autocoding'),
                    items: [
                        {
                            label: 'Procurement Categories',
                            icon: 'fas fa-sitemap',
                            routerLink: ['autocoding/procurement-categories'],
                            tooltipOptions: {
                                tooltipLabel: "Procurement Categories"
                            },
                            visible: this.priviligeList?.includes('VIEW_PROCUREMENT_CATEGORY'),
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Financial Dimensions',
                            icon: 'fas fa-wallet',
                            tooltipOptions: {
                                tooltipLabel: "Financial Dimensions"
                            },
                            expanded:window.location.href.includes('financial-dimensions'),
                            visible: this.priviligeList?.some(privilege => [
                                'VIEW_ACTIVITY',
                                'VIEW_BANK_LOAN',
                                'VIEW_BUSINESS_UNIT',
                                'VIEW_COST_CENTRE',
                                'VIEW_EQUIPMENT',
                                'VIEW_FINANCIAL_DEPARTMENT',
                                'VIEW_INTER_COMPANY',
                                'VIEW_LOCATION',
                                'VIEW_MARKET_PRODUCTS',
                                'VIEW_SEGMENT',
                                'VIEW_WORKER',
                                'VIEW_BELGIUM_LOCAL_COA',
                                'VIEW_FRANCE_LOCAL_COA',
                                'VIEW_ITALY_LOCAL_COA',
                                'VIEW_SPAIN_LOCAL_COA',
                                'VIEW_TURKEY_LOCAL_COA'
                            ].includes(privilege)),
                               items: [
                                {
                                    label: 'Activity',
                                    icon: 'fas fa-table',
                                    tooltipOptions: {
                                        tooltipLabel: "Activity"
                                    },
                                    visible:this.priviligeList?.includes('VIEW_ACTIVITY'),
                                    routerLink: ['autocoding/financial-dimensions/activity'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Bank Loans',
                                    icon: 'fas fa-piggy-bank',
                                    tooltipOptions: {
                                        tooltipLabel: "Bank Loans"
                                    },
                                    visible:this.priviligeList?.includes('VIEW_BANK_LOAN'),
                                    routerLink: ['autocoding/financial-dimensions/bank-loans'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Business Unit',
                                    icon: 'fas fa-briefcase',
                                    tooltipOptions: {
                                        tooltipLabel: "Business Unit"
                                    },
                                    visible:this.priviligeList?.includes('VIEW_BUSINESS_UNIT'),
                                    routerLink: ['autocoding/financial-dimensions/business-unit'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Cost Center',
                                    icon: 'fas fa-sack-dollar',
                                    tooltipOptions: {
                                        tooltipLabel: "Cost Center"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_COST_CENTRE'),
                                    routerLink: ['autocoding/financial-dimensions/cost-center'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Equipment',
                                    icon: 'fas fa-toolbox',
                                    tooltipOptions: {
                                        tooltipLabel: "Equipment"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_EQUIPMENT'),
                                    routerLink: ['autocoding/financial-dimensions/equipment'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Financial Department',
                                    icon: 'fad fa-coins',
                                    tooltipOptions: {
                                        tooltipLabel: "Financial Department"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_FINANCIAL_DEPARTMENT'),
                                    routerLink: ['autocoding/financial-dimensions/financial-department'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Intercompany',
                                    icon: 'fas fa-building',
                                    tooltipOptions: {
                                        tooltipLabel: "Intercompany"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_INTER_COMPANY'),
                                    routerLink: ['autocoding/financial-dimensions/intercompany'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Location',
                                    icon: 'fas fa-search-location',
                                    tooltipOptions: {
                                        tooltipLabel: "Location"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_LOCATION'),
                                    routerLink: ['autocoding/financial-dimensions/location'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Market Products',
                                    icon: 'fas fa-warehouse-alt',
                                    tooltipOptions: {
                                        tooltipLabel: "Market Products"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_MARKET_PRODUCT'),
                                    routerLink: ['autocoding/financial-dimensions/market-products'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Segment',
                                    icon: 'fas fa-page-break',
                                    tooltipOptions: {
                                        tooltipLabel: "Segment"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_SEGMENT'),
                                    routerLink: ['autocoding/financial-dimensions/segment'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Worker',
                                    icon: 'fas fa-user-hard-hat',
                                    tooltipOptions: {
                                        tooltipLabel: "Worker"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_WORKER'),
                                    routerLink: ['autocoding/financial-dimensions/worker'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Belgium Local CoA',
                                    icon: 'fas fa-users-cog',
                                    tooltipOptions: {
                                        tooltipLabel: "Belgium Local CoA"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_BELGIUM_LOCAL_COA'),
                                    routerLink: ['autocoding/financial-dimensions/belgium-local'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'France Local CoA',
                                    icon: 'fas fa-users-cog',
                                    tooltipOptions: {
                                        tooltipLabel: "France Local CoA"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_FRANCE_LOCAL_COA'),
                                    routerLink: ['autocoding/financial-dimensions/france-local'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Italy Local CoA',
                                    icon: 'fas fa-users-cog',
                                    tooltipOptions: {
                                        tooltipLabel: "Italy Local CoA"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_ITALY_LOCAL_COA'),
                                    routerLink: ['autocoding/financial-dimensions/italy-local'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Spain Local CoA',
                                    icon: 'fas fa-users-cog',
                                    tooltipOptions: {
                                        tooltipLabel: "Spain Local CoA"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_SPAIN_LOCAL_COA'),
                                    routerLink: ['autocoding/financial-dimensions/spain-local'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Turkey Local CoA',
                                    icon: 'fas fa-users-cog',
                                    tooltipOptions: {
                                        tooltipLabel: "Turkey Local CoA"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_TURKEY_LOCAL_COA'),
                                    routerLink: ['autocoding/financial-dimensions/turkey-local'],
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                }
                            ]
                        },
                        {
                            label: 'Main Accounts',
                            icon: 'fas fa-user-circle',
                            routerLink: ['autocoding/main-accounts'],
                            visible: this.priviligeList?.includes('VIEW_MAIN_ACCOUNT'),
                            tooltipOptions: {
                                tooltipLabel: "Main Accounts"
                            },
                            routerLinkActiveOptions: {
                                exact: true
                            }
                        },
                        {
                            label: 'Derived Financial Dimensions',
                            icon: 'fas fa-users-cog',
                            routerLink: ['autocoding/derived-financial-dimensions'],
                            visible: this.priviligeList?.includes('VIEW_DERIVED_FINANCIAL_DIMENSIONS'),
                            tooltipOptions: {
                                tooltipLabel: "Derived Financial Dimensions"
                            },
                            routerLinkActiveOptions: {
                                exact: true
                            }
                        },
                        {
                            label: 'VAT Codes',
                            icon: 'fas fa-envelope-open-dollar',
                            tooltipOptions: {
                                tooltipLabel: "VAT Codes"
                            },
                            routerLinkActiveOptions: {
                                exact: true
                            },
                            visible: this.priviligeList?.some(priv => ['VIEW_TAX_GROUP', 'VIEW_ITEM_SALES_TAX_GROUPS'].includes(priv)),
                            items: [
                                {
                                    label: 'Tax Groups',
                                    icon: 'fas fa-poll-people',
                                    tooltipOptions: {
                                        tooltipLabel: "Sales Tax Groups"
                                    },
                                    routerLink: ['autocoding/sales-tax-group'],
                                    visible: this.priviligeList?.includes('VIEW_TAX_GROUP'),
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                },
                                {
                                    label: 'Item Sales Tax Groups',
                                    icon: 'fas fa-people-arrows',
                                    tooltipOptions: {
                                        tooltipLabel: "Item Sales Tax Groups"
                                    },
                                    routerLink: ['autocoding/item-sales-tax-group'],
                                    visible: this.priviligeList?.includes('VIEW_ITEM_SALES_TAX_GROUPS'),
                                    routerLinkActiveOptions: {
                                        exact: true
                                    }
                                }
                            ]
                        },
                        {
                            label: 'Number Sequence Groups',
                            icon: 'fas fa-list-ol',
                            tooltipOptions: {
                                tooltipLabel: "Number Sequence Groups"
                            },
                            routerLink: ['autocoding/number-sequence-groups'],
                            visible: this.priviligeList?.includes('VIEW_NUMBER_SEQUENCE_GROUP'),
                            routerLinkActiveOptions: {
                                exact: true
                            }
                        }
                        ,
                        {
                            label: 'Approvers',
                            icon: 'fas fa-thumbs-up',
                            tooltipOptions: {
                                tooltipLabel: "Approvers"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               },
                               items: [
                                {
                                    label: 'Single Approvers',
                                    icon: 'fas fa-user-check',
                                    tooltipOptions: {
                                        tooltipLabel: "Single Approvers"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_SINGLE_APPROVER'),
                                    routerLink: ['autocoding/single-approvers'],
                                    routerLinkActiveOptions:{
                                        exact: true
                                       }
                                },
                                {
                                    label: 'Group Approvers',
                                    icon: 'fas fa-users',
                                    tooltipOptions: {
                                        tooltipLabel: "Group Approvers"
                                    },
                                    visible: this.priviligeList?.includes('VIEW_GROUP_APPROVER'),
                                    routerLink: ['autocoding/group-approvers'],
                                    routerLinkActiveOptions:{
                                        exact: true
                                       }
                                },
                            ]
                        },
                    ]
                },
                {
                    label: 'Administration ',
                    icon: 'fas fa-user-shield',
                    id:'admin',
                    visible:this.priviligeList?.includes('VIEW_ALL_DOC_TRACKER_LISTS') || this.priviligeList?.includes('EMAIL_TEMPLATE_LIST_VIEW') || this.priviligeList?.includes('VIEW_APPLICATION_CONFIGURATION') || this.priviligeList?.includes('VIEW_USER_LIST') || this.priviligeList?.includes('VIEW_ANNOUNCEMENT') || this.priviligeList?.includes('VIEW_ROLE_LIST') || this.priviligeList?.includes('VIEW_PO_PREFIX_LIST') || this.priviligeList?.includes('VIEW_ERROR_CODE_LIST') || this.priviligeList?.includes('VIEW_DOCUMENT_IDENTIFIER_LIST') || this.priviligeList?.includes('VIEW_CUSTOM_PROFILE_LIST') || this.priviligeList?.includes('VIEW_ACTION_LIST') || this.priviligeList?.includes('VIEW_USER_ACTIVITY_LIST') || this.priviligeList?.includes('BLACKLIST_EMAIL_LIST_VIEW'),
                    tooltipOptions: {
                        tooltipLabel: "Administration"
                    },
                    expanded:window.location.href.includes('administration'),
                    items: [
                        {
                            label: 'Users',
                            icon: 'fas fa-users-cog',
                            routerLink: ['administration/user-management'],
                            visible: this.priviligeList?.includes('VIEW_USER_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Users"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Roles',
                            icon: 'fas fa-user-check',
                            routerLink: ['administration/role-management'],
                            visible: this.priviligeList?.includes('VIEW_ROLE_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Roles"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'PO Prefix',
                            icon: 'fas fa-money-check',
                            routerLink: ['administration/poprefix-management'],
                            visible: this.priviligeList?.includes('VIEW_PO_PREFIX_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "PO Prefix "
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Document Actions',
                            icon: 'fas fa-file-alt',
                            routerLink: ['administration/docstatus-management'],
                            visible: this.priviligeList?.includes('VIEW_ACTION_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Document Actions "
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Error Code List',
                            icon: 'far fa-exclamation-square',
                            routerLink: ['administration/errocodelist'],
                            visible: this.priviligeList?.includes('VIEW_ERROR_CODE_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Error Code List"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Language Intent',
                            icon: 'fas fa-language',
                            routerLink: ['administration/languageintent-alias'],
                            visible: this.priviligeList?.includes('VIEW_DOCUMENT_IDENTIFIER_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Language Intent"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Custom Profile List',
                            icon: 'fas fa-id-badge',
                            routerLink: ['administration/customprofile-list'],
                            visible: this.priviligeList?.includes('VIEW_CUSTOM_PROFILE_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Custom Profile List"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Document Type Management',
                            icon:'fas fa-money-check-edit',
                            routerLink: ['administration/document-type'],
                            visible:  this.priviligeList?.includes('VIEW_ALL_DOC_TRACKER_LISTS'),
                            tooltipOptions: {
                                tooltipLabel: "Document Type Management"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Announcement',
                            icon: 'fas fa-megaphone',
                            routerLink: ['administration/announcement'],
                            visible: this.priviligeList?.includes('VIEW_ANNOUNCEMENT'),
                            tooltipOptions: {
                                tooltipLabel: "Announcement"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Application Configuration',
                            icon: 'fas fa-cogs',
                            routerLink: ['administration/app-config'],
                            visible: this.priviligeList?.includes('VIEW_APPLICATION_CONFIGURATION'),
                            tooltipOptions: {
                                tooltipLabel: "Application Configuration"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Custom Email Template',
                            icon:'fas fa-mail-bulk',
                            title:'Custom Email Template',
                            visible: this.priviligeList?.includes('EMAIL_TEMPLATE_AUTO_LIST_VIEW') ||  this.priviligeList?.includes('EMAIL_TEMPLATE_MANUAL_LIST_VIEW'),
                            items: [
                                {
                                    label: 'Auto Email Template',
                                    icon:'far fa-envelope-square',
                                    routerLink: ['administration/email-template'],
                                    visible: this.priviligeList?.includes('EMAIL_TEMPLATE_AUTO_LIST_VIEW'),
                                    tooltipOptions: {
                                        tooltipLabel: "Auto Email Template"
                                    },
                                    routerLinkActiveOptions:{
                                        exact: true
                                       }
                                },
                                {
                                    label: 'Manual Email Template',
                                    icon:'fas fa-envelope',
                                    routerLink: ['administration/manual-email-template'],
                                    visible: this.priviligeList?.includes('EMAIL_TEMPLATE_MANUAL_LIST_VIEW'),
                                    tooltipOptions: {
                                        tooltipLabel: "Manual Email Template"
                                    },
                                    routerLinkActiveOptions:{
                                        exact: true
                                       }
                                },
                            ],
                            expanded:window.location.href.includes('administration/email-template')||window.location.href.includes('administration/manual-email-template'),
                        },
                        {
                            label: 'Blacklist Email Config',
                            icon:'fas fa-ban',
                            routerLink: ['administration/blacklist-email'],
                            visible: this.priviligeList?.includes('BLACKLIST_EMAIL_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "Blacklist Email Config"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'User Activity Log',
                            icon: 'fal fa-user-clock',
                            routerLink:['administration/activity-log'],
                            routerLinkActiveOptions:{
                             exact: true
                            },
                            visible: this.priviligeList?.includes('VIEW_USER_ACTIVITY_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "User Activity Log"
                            }
                        },
                        {
                            label: 'Data Change Logger',
                            icon: 'fas fa-typewriter',
                            routerLink:['administration/data-change-logger'],
                            routerLinkActiveOptions:{
                             exact: true
                            },
                            visible: this.priviligeList?.includes('VIEW_USER_ACTIVITY_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Data Change Logger"
                            }
                        },
                        {
                            label: 'Cron Job Logger',
                            icon: 'fal fa-archive',
                            routerLink:['administration/cron-job-logger'],
                            routerLinkActiveOptions:{
                             exact: true
                            },
                            visible: this.priviligeList?.includes('CRONJOB_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "Cron Job Logger"
                            }
                        },
                        {
                            label: 'Autocode Action Logs',
                            icon: 'fa fa-scroll',
                            routerLink:['administration/autocode-action-logs'],
                            routerLinkActiveOptions:{
                             exact: true
                            },
                            visible: this.priviligeList?.includes('AUTOCODE_CHANGE_LOGGER_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Autocode Action Logs"
                            }
                        },
                        {
                            label: 'DMS Exception Logger',
                            icon: 'fas fa-folder-times',
                            routerLink:['administration/dms-exception-logs'],
                            routerLinkActiveOptions:{
                             exact: true
                            },
                            visible: this.priviligeList?.includes('CRONJOB_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "DMS Exception Logger"
                            }
                        }
                    ]
                },
                {
                    label: 'EDI Management',
                   icon:"fas fa-file-code",
                    id:'edi',
                    tooltipOptions: {
                        tooltipLabel: "EDI Management"
                    },
                    expanded:window.location.href.includes('edi/trading-partner-setup')||window.location.href.includes('edi/code-list'),
                    visible:this.priviligeList.includes('TRADING_PARTNER_LIST_VIEW') || this.priviligeList.includes('TRADING_PARTNER_RULE_LIST_VIEW') || this.priviligeList.includes('CODELIST_LIST_VIEW') || this.priviligeList.includes('CONNECTIVITY_LIST_VIEW'),
                    items: [
                        {
                            label: 'Trading Partner Setup',
                            icon: 'far fa-handshake-alt',
                            routerLink: ['edi/trading-partner-setup'],
                            visible:this.priviligeList.includes('TRADING_PARTNER_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "Trading Partner Setup"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        {
                            label: 'Codelist Setup',
                            icon: 'far fa-code-branch ',
                            routerLink: ['edi/code-list'],
                            visible:this.priviligeList.includes('CODELIST_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "Codelist Setup"
                            },
                            routerLinkActiveOptions:{
                                exact: true
                               }
                        },
                        // {
                        //     label: 'Connectivity',
                        //     icon: 'fad fa-router ',
                        //     routerLink: ['edi/connectivity'],
                        //     visible:this.priviligeList.includes('CONNECTIVITY_LIST_VIEW'),
                        //     tooltipOptions: {
                        //         tooltipLabel: "Connectivity"
                        //     },
                        //     routerLinkActiveOptions:{
                        //         exact: true
                        //        }
                        // }
                    ]
                },

                {
                    label: 'Connectivity',
                    icon:'fad fa-router',
                    routerLink: ['connectivity'],
                    visible:this.priviligeList.includes('CONNECTIVITY_LIST_VIEW'),
                    tooltipOptions: {
                        tooltipLabel: "Connectivity"
                    },
                    routerLinkActiveOptions:{
                        exact: true
                       }
                },


                {
                    label: 'E-XML Management',
                    icon:'fas fa-laptop-code',
                    routerLink: ['xml'],
                    visible:this.priviligeList.includes('EINVOICE_PARTNER_LIST_VIEW') ,
                    tooltipOptions: {
                        tooltipLabel: "E-XML Management"
                    },
                    routerLinkActiveOptions:{
                        exact: true
                       }
                },
            ]
        })
    }
    getPriviligesList() {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (userDetails) {
            return userDetails?.privileges;
        }
    }
    ngOnDestroy() {
        this.userDetailsSubscription.unsubscribe();
    }
}

