<div class="layout-wrapper" [ngClass]="{'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple,
        'layout-static': isStatic(),
        'layout-overlay': isOverlay(),
        'layout-overlay-active': overlayMenuActive && isOverlay(),
        'layout-horizontal': isHorizontal(),
        'layout-static-active': !staticMenuDesktopInactive && isStatic(),
        'layout-mobile-active': staticMenuMobileActive,
        'layout-rtl': app.isRTL,
        'layout-rightpanel-active': rightPanelActive}"
        
    [class]="'layout-menu-'+ app.menuTheme+ ' layout-topbar-'+ app.topbarTheme" id="cozmosAppMain">

    <app-topbar></app-topbar>
    <!-- app-rightpanel can be put here -->

    <div *ngIf="!(router.url.includes('/view') || router.url.includes('/email-log') || router.url.includes('dashboard/full-screen'))" class="menu-wrapper" (click)="onMenuClick($event)" id="menuWrapper">
        <div class="layout-menu-container" >
            <app-menu></app-menu>
        </div>
    </div>

    <div  [ngClass]="!router?.url.includes('/view')?'layout-main':'layout-main layout-main-full'" >
        <app-filter-panel  *ngIf="!(router.url.includes('/view') || router.url.includes('/email-log') || router.url.includes('dashboard/full-screen') || router.url.includes('user/administration/app-config'))"></app-filter-panel>
        <app-gridconfig  *ngIf="!(router?.url.includes('/view') || router.url.includes('/master-data/extended-ap-invoice-feed') || router.url.includes('/email-log'))"></app-gridconfig>
        <div  [ngClass]="!(router?.url.includes('/view')|| router.url.includes('/email-log'))?'layout-content':'layout-content layout-content-full'" >
         
            <router-outlet></router-outlet>
          
        </div>
      
       
    </div>
    <app-footer ></app-footer>

    <div *ngIf="staticMenuMobileActive" class="layout-mask modal-in"></div>
</div>
<chat-bot></chat-bot>
