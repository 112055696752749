import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Subscription } from 'rxjs';
import { Settings } from 'src/app/modules/common/lib/app.constant';
import { FilterService } from 'src/app/modules/common/service';
import { CommonService } from 'src/app/modules/common/service/common/common.service';
import { EdiService } from 'src/app/modules/common/service/edi-management/edi.service';

@Component({
  selector: 'app-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss']
})
export class ConnectivityComponent {
  loading:boolean=true;
  isAddConnectivity:boolean=false;
  isPayload: boolean=false;
  showConnectivityModal:boolean=false;
  defaultOrderBy:String="actionOn";
  orderType:number=1;
  orderBy: String = Settings.GridConfiguration.orderBy;
  totalRecords:number=Settings.GridConfiguration.totalRecords;
  pageSizeList:any[]=Settings.GridConfiguration.pageSizeList;
  pageSize:number=Settings.GridConfiguration.pageSize;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  searchList: any;
  userDetailsSubscription: Subscription;
  refreshClickEventSubscription:Subscription;
  searchEventSubscription:Subscription;
  clickEventSubscription:Subscription;
  connectivitySetupList:{}[]=[];
connectivitySetupSchema=[
  {  header: 'Status', field: 'isActive',sortable: true},
  // {header: 'TP Profile Name', field: 'profileName',sortable: true},
  {header: 'Connectivity Profile Name', field: 'connectivityName',sortable: true},
  {  header: 'Connectivity Type', field: 'connectivityTypeId',sortable: true},
  {header: 'Message Direction', field: 'direction',sortable: true},
  {  header: 'Payload', field: 'connectivitySettings',sortable: false},
  {header: 'Created By', field: 'createdBy',sortable: true},
    {  header: 'Created On (UTC)', field: 'createdOn',sortable: true},
    {header: 'Action By', field: 'modifiedBy',sortable: true},
    {  header: 'Action On (UTC)', field: 'modifiedOn',sortable: true},
]
addConnectivityForm = new FormGroup({
  connectivityType: new FormControl(null,[Validators.required]),
  // profileName:new FormControl(null,[Validators.required]),
  connectivityName:new FormControl(null,[Validators.required]),
  status: new FormControl(true,[Validators.required]),
  direction:new FormControl(null,[Validators.required]),
  apiUrl: new FormControl(null),
  apiMethod: new FormControl(null),
  apiKey:new FormControl(null),
  params:new FormControl(null),
  payload:new FormControl(null),
  connectionType: new FormControl(null),
  connectionString:new FormControl(null),
  queueName:new FormControl(null),
  topicName:new FormControl(null),
  maxMessageCount:new FormControl(null),
  maxWaitTime:new FormControl(null),
  host: new FormControl(null),
  port: new FormControl(null),
  userId: new FormControl(null),
  password: new FormControl(null),
  sshKey: new FormControl(null),
  filePath: new FormControl(null),
  fileFilter: new FormControl(null),
  archiveFilePath: new FormControl(null),
  errorFilePath:new FormControl(null),
  smtpKey: new FormControl(null),
  folder:new FormControl(null)
})
  pageNumber:number= Settings.GridConfiguration.pageNumber;
  previousField: any;
  sortOrder: any;
  privilegeList: any;
  currentPayload: any;
  storedRecords: number;
  filterListData:any[]=[];
  private commonService:CommonService;
  private router:Router;
  private filterService:FilterService;
  searchTerm:String=null;
  storePageNumber: number;
  jumpToPageNo: string;
  isEditConnectivity: boolean=false;
  connectivityTypeList: { [x: number]: any; }[];
  showSFTP: boolean=false;
  showFTP: boolean=false;
  statusList:{}[]=[{name:"Active",value:true},{name:"InActive",value:false}];
  connectionTypeList:{}[]=[{name:"GET",id:1},{name:"PUT",id:2}];
  showAPI: boolean=false;
  showESB: boolean=false;
  showFSA: boolean=false;
  showSMTP: boolean=false;
  private ediService:EdiService;
  private messageService:MessageService;
  private confirmationService:ConfirmationService;
  exportClickEventSubscription:Subscription;
  userNameListEventSubscription:Subscription;
  messageDirectionList: { id: string; name: string; }[];
  tpProfileList: any;
  updateConnectivityId: any;
  showPassword: any;
  dropDownList: any;
  isExport: any;
  userNameDetails: any;
  constructor(_confirmationService:ConfirmationService,_messageService:MessageService,_ediService:EdiService,_commonService:CommonService,_filterService:FilterService,_router:Router){
   this.commonService=_commonService;
   this.filterService=_filterService;
   this.router=_router;
   this.ediService=_ediService;
   this.messageService=_messageService;
   this.confirmationService=_confirmationService;
  }
  ngOnInit() {
    var privilegeList = Settings.CommonFunctions.getPrivilegeList();
    if (!privilegeList) {
      this.userDetailsSubscription = this.commonService.getPrivelegeEvent().subscribe((res) => {
        this.privilegeList = res?.privileges;
      })
    }
    else {
      this.privilegeList = privilegeList;
    }
    this.userNameListEventSubscription = this.filterService.getUserDetailsJSON().subscribe((items) => {
      this.userNameDetails=items;
      })
      if(!this.userNameDetails){
      this.userNameDetails=JSON.parse(localStorage.getItem('userNameDetails'))
      }
    this.filterService.sendClearFilterEvent();
     this.getFilterEvent();
    this.getSearchEvent();
    this.getRefreshEvent();
    this.getConnectivityTypeList();
    this.getTpProfileList();
    this.getMessageDirection();
    this.getExportEvent();
  }
  getRefreshEvent() {
    this.refreshClickEventSubscription = this.commonService.getRefreshEvent().subscribe((res) => {
      if (res.includes("user/connectivity")) {
        this.refreshGrid();
      }
    })
  }
  getFilterEvent() {
    // Subscribe to filter data changes and update the filter list data and paginator page if the URL includes 'user/email-tracker'
    this.clickEventSubscription = this.filterService.getFilterData().subscribe((items) => {
      if (items?.[0].includes('user/connectivity')) {
        this.filterListData.splice(0)
        this.filterListData.push(items[1]);
        this.refreshGrid();
      }
    })
  }
  onMouseDownTogglePassword(showPassword:boolean){
    this.showPassword=showPassword;
  }
paginate(event:any){
  this.pageNumber = event?.page + 1;
  this.pageSize = event?.rows;
  this.getConnectivitySetupList();
}
onClickShowFormModal(){
  this.showConnectivityModal=true;
  this.isAddConnectivity=true;
  this.isEditConnectivity=false;
}
onClickPayloadIcon(payload:any){
  this.currentPayload=payload;
  this.isPayload=true;
  try {
    // Preprocess the string
    const preprocessedString = payload
      .replace(/'/g, '"')
      .replace(/None/g, 'null')
      .replace(/False/g, 'false')
      .replace(/True/g, 'true');
    // Parse the preprocessed string as JSON
    this.currentPayload = JSON.parse(preprocessedString);
  } catch (error) {
    this.currentPayload=payload;
  }
}
onClickEditConnectivity(activity){
  
  this.showConnectivityModal=true;
  this.isAddConnectivity=false;
  this.isEditConnectivity=true;
  this.updateConnectivityId=activity?.id;
  var connectivityType=Settings.ConnectivityType[activity?.connectivityTypeId]
  var messageDirection=activity?.direction=="IN"?"I":activity?.direction=="OUT"?"O":null;
  this.addConnectivityForm.controls['connectivityType'].setValue(connectivityType);
  this.onChangeConnectivityType();
  this.addConnectivityForm.controls['status'].setValue(activity?.isActive);
  this.addConnectivityForm.controls['direction'].setValue(messageDirection);
  // this.addConnectivityForm.controls['profileName'].setValue(activity?.tpProfileId);
  this.addConnectivityForm.controls['connectivityName'].setValue(activity?.connectivityName);
  try{
  const preprocessedString = activity?.connectivitySettings
  .replace(/'/g, '"')
  .replace(/None/g, 'null')
  .replace(/False/g, 'false')
  .replace(/True/g, 'true');
  var data= JSON.parse(preprocessedString);
  }
  catch(error){
   var data=activity?.connectivitySettings
  }
// Parse the preprocessed string as JSON
  this.populateConnectivity(data);
}
populateConnectivity(data:any){
  var convertedData=this.convertToCamelCase(data);
if(this.showFSA){
  this.addConnectivityForm.patchValue({
    filePath:convertedData?.filePath,
    errorFilePath:convertedData?.errorFilePath
  })
}
if(this.showAPI){
  this.addConnectivityForm.patchValue({
    apiUrl:convertedData?.url,
    apiMethod:convertedData?.method,
    apiKey:convertedData?.apiKey,
    userId:convertedData?.userId,
    password:convertedData?.password,
    params:convertedData?.['param(s)'],
    payload:convertedData?.payload
  })
}
if(this.showESB){
  this.addConnectivityForm.patchValue({
    connectionType:convertedData?.connectionType,
    connectionString:convertedData?.connectionString,
    queueName:convertedData?.queueName,
    topicName:convertedData?.topicName,
    maxMessageCount:convertedData?.maxMessageCount,
    maxWaitTime:convertedData?.maxWaitCount
  })
}
if(this.showFTP){
  this.addConnectivityForm.patchValue({
    host:convertedData?.['host/ip'],
    port:convertedData?.port,
    userId:convertedData?.userId,
    password:convertedData?.password,
    filePath:convertedData?.filePath,
    fileFilter:convertedData?.fileFilter,
    archiveFilePath:convertedData?.archiveFilePath,
    errorFilePath:convertedData?.errorFilePath
  })
}
if(this.showSFTP){
  this.addConnectivityForm.patchValue({
    host:convertedData?.['host/ip'],
    port:convertedData?.port,
    sshKey:convertedData?.sshKey,
    userId:convertedData?.userId,
    password:convertedData?.password,
    filePath:convertedData?.filePath,
    fileFilter:convertedData?.fileFilter,
    archiveFilePath:convertedData?.archiveFilePath,
    errorFilePath:convertedData?.errorFilePath
  })
}
if(this.showSMTP){
  this.addConnectivityForm.patchValue({
    host:convertedData?.['host/ip'],
    port:convertedData?.port,
    userId:convertedData?.userId,
    password:convertedData?.password,
    smtpKey:convertedData?.key,
    folder:convertedData?.folder,
    archiveFilePath:convertedData?.archiveFilePath
  })
}
}

getTpProfileList(){
  this.ediService.getCodeListLibraryDropdownList().subscribe({
    next: (res: any) => {
      this.dropDownList = res?.results;
      this.tpProfileList=this.dropDownList.filter(item => item.profileName !== '' && item.profileName !== null && item.profileName !== undefined);
				Settings.CommonFunctions.sortByKey(this.tpProfileList, 'profileName', 'asc');
    },
    error: (e) => { },
  });
}
getConnectivityTypeList(){
  var body={key1:"id",key2:"connectivityType"}
  this.connectivityTypeList=Settings.CommonFunctions.enumToArray(Settings.ConnectivityType,body);
}

onChangeConnectivityType(){
  this.clearFormExceptGivenValues("connectivityType","status","profileName","direction");
  var connectivityType=this.addConnectivityForm.value.connectivityType;
  this.showFTP=this.showSFTP=this.showESB=this.showAPI=this.showFSA=this.showSMTP=false;
  if(connectivityType==Settings.ConnectivityType.FTP){
    this.showFTP=true;
    this.addConnectivityForm.controls["host"].setValidators([Validators.required]);
    this.addConnectivityForm.controls["port"].setValidators([Validators.required]);
    this.addConnectivityForm.controls["filePath"].setValidators([Validators.required]);
    this.addConnectivityForm.controls["userId"].setValidators([Validators.required]);
    this.addConnectivityForm.controls["password"].setValidators([Validators.required]);
    this.addConnectivityForm.controls["host"].updateValueAndValidity();
    this.addConnectivityForm.controls["port"].updateValueAndValidity();
    this.addConnectivityForm.controls["filePath"].updateValueAndValidity();
    this.addConnectivityForm.controls["userId"].updateValueAndValidity();
    this.addConnectivityForm.controls["password"].updateValueAndValidity();
  }
  if(connectivityType==Settings.ConnectivityType.SFTP){
    this.showSFTP=true;
    this.addConnectivityForm.controls["host"].setValidators([Validators.required]);
    this.addConnectivityForm.controls["port"].setValidators([Validators.required]);
    this.addConnectivityForm.controls["filePath"].setValidators([Validators.required]);
    this.addConnectivityForm.controls["host"].updateValueAndValidity();
    this.addConnectivityForm.controls["port"].updateValueAndValidity();
    this.addConnectivityForm.controls["filePath"].updateValueAndValidity();
    // this.addConnectivityForm.controls["sshKey"].setValidators([Validators.required]);
    this.addConnectivityForm.updateValueAndValidity();
  }
if(connectivityType==Settings.ConnectivityType.API ){
  this.showAPI=true;
  this.addConnectivityForm.controls["apiUrl"].setValidators([Validators.required]);
  this.addConnectivityForm.controls["apiMethod"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["userId"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["password"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["params"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["apiKey"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["payload"].setValidators([Validators.required]);
  this.addConnectivityForm.updateValueAndValidity();
}
if(connectivityType==Settings.ConnectivityType.ESB ){
  this.showESB=true;
  // this.addConnectivityForm.controls["connectionType"].setValidators([Validators.required]);
  this.addConnectivityForm.controls["connectionString"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["queueName"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["topicName"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["maxMessageCount"].setValidators([Validators.required]);
  // this.addConnectivityForm.controls["maxWaitTime"].setValidators([Validators.required]);
  this.addConnectivityForm.updateValueAndValidity();
}
if(connectivityType==Settings.ConnectivityType.FSA ){
  this.showFSA=true;
  this.addConnectivityForm.controls["filePath"].setValidators([Validators.required]);
  this.addConnectivityForm.controls["filePath"].updateValueAndValidity();
  // this.addConnectivityForm.controls["fileFilter"].setValidators([Validators.required]);
}
if(connectivityType==Settings.ConnectivityType.SMTP ){
  this.showSMTP=true;
  this.addConnectivityForm.controls["host"].setValidators([Validators.required]);
  this.addConnectivityForm.controls["port"].setValidators([Validators.required]);
  this.addConnectivityForm.controls["userId"].setValidators([Validators.required]);
  this.addConnectivityForm.controls["password"].setValidators([Validators.required]);
  this.addConnectivityForm.updateValueAndValidity();
}
else{
}
}
clearFormExceptGivenValues(keepControlName1: string,keepControlName2:string,keepControlName3:string,keepControlName4:string) {
  const formControls = Object.keys(this.addConnectivityForm.controls);
  formControls.forEach(controlName => {
    if (controlName !== keepControlName1 && controlName!==keepControlName2 && controlName!==keepControlName3 &&  controlName!==keepControlName4) {
      this.addConnectivityForm.get(controlName).clearValidators();
      this.addConnectivityForm.get(controlName).reset();
       this.addConnectivityForm.get(controlName).updateValueAndValidity();
    }
  });
}
onClickDelete(data){
  this.confirmationService.confirm({
    message: 'Are you sure, you want to Delete this Connectivity ?',
    accept: () => {
      this.ediService.deleteConnectivity(data?.id).subscribe({
      next:()=>{
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Connectivity deleted Successfully.' });
        this.getConnectivitySetupList();
      },
      error:(error)=>{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.error?.message??"Failed to Delete Connetcivity, Please try again Later" });
      }
      })
    }
      });
}
getExportEvent(){
  this.exportClickEventSubscription = this.filterService.getClickExportEvent().subscribe((res) => {
    this.isExport = res;
    this.getConnectivitySetupList();
  })
  }
getMessageDirection(){
  this.messageDirectionList = Settings.CommonFunctions.enumCharToArray(Settings.MessageTypeEDI);
}
onClickAddConnectivity(){
  var body={}
if(this.showFSA){
  body= {"filePath": this.addConnectivityForm.value.filePath, "errorFilePath": this.addConnectivityForm.value.errorFilePath}
}
if(this.showAPI){
  body={"url": this.addConnectivityForm.value.apiUrl, "method": this.addConnectivityForm.value.apiMethod, "apiKey": this.addConnectivityForm.value.apiKey, "userId": this.addConnectivityForm.value.userId, "password": this.addConnectivityForm.value.password,
  "param(s)": this.addConnectivityForm.value.params, "payload": this.addConnectivityForm.value.payload}
}
if(this.showESB){
  body={"connectionType": this.addConnectivityForm.value.connectionType, "connectionString": this.addConnectivityForm.value.connectionString, "queueName": this.addConnectivityForm.value.queueName,
  "topicName": this.addConnectivityForm.value.topicName, "maxMessageCount": this.addConnectivityForm.value.maxMessageCount, "maxWaitCount": this.addConnectivityForm.value.maxWaitTime}
}
if(this.showFTP){
  body={"host/ip": this.addConnectivityForm.value.host, "port": this.addConnectivityForm.value.port, "userId": this.addConnectivityForm.value.userId, "password": this.addConnectivityForm.value.password, "filePath": this.addConnectivityForm.value.filePath,
  "fileFilter": this.addConnectivityForm.value.fileFilter, "archiveFilePath": this.addConnectivityForm.value.archiveFilePath, "errorFilePath": this.addConnectivityForm.value.errorFilePath}
}
if(this.showSFTP){
  body={"host/ip": this.addConnectivityForm.value.host, "port": this.addConnectivityForm.value.port, "userId": this.addConnectivityForm.value.userId, "password": this.addConnectivityForm.value.password, "sshKey": this.addConnectivityForm.value.sshKey,
  "filePath": this.addConnectivityForm.value.filePath, "fileFilter": this.addConnectivityForm.value.fileFilter, "archiveFilePath": this.addConnectivityForm.value.archiveFilePath, "errorFilePath": this.addConnectivityForm.value.errorFilePath}
}
if(this.showSMTP){
  body={"host/ip": this.addConnectivityForm.value.host, "port": this.addConnectivityForm.value.port, "userId": this.addConnectivityForm.value.userId, "password": this.addConnectivityForm.value.password, "key": this.addConnectivityForm.value.smtpKey,
  "folder": this.addConnectivityForm.value.folder, "archiveFilePath": this.addConnectivityForm.value.archiveFilePath}
}
body=this.camelCaseToSnakeCase(body);
var connectivityBody={
  // "tpProfileId":this.addConnectivityForm.value.profileName,
  "connectivity_name":this.addConnectivityForm.value.connectivityName,
  "direction": this.addConnectivityForm.value.direction,
  "connectivityTypeId": this.addConnectivityForm.value.connectivityType,
  "connectivitySettings": body,
  "isActive": this.addConnectivityForm.value.status,
  "id":this.isEditConnectivity?this.updateConnectivityId:0
}
this.ediService.addConnectivity(connectivityBody).subscribe({
  next:(res)=>{
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'New Connectivity has been saved Successfully.' });
    this.getConnectivitySetupList();
  },
  error:(error)=>{
    this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.error?.message??'Failed to add New Connectivity, Please Try again later' });
  }
})
this.onHideConnectivityModal();
}
camelCaseToSnakeCase(obj) {
  let snakeObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      snakeObj[snakeKey] = obj[key];
    }
  }
  return snakeObj;
}
getIconClass(){
  if(this.showPassword){
    return 'far fa-eye';
  }
  else{
    return 'fas fa-eye-slash';
  }
}
convertToCamelCase(obj: any): any {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => this.convertToCamelCase(item));
  }
  if (typeof obj !== 'object') {
    return obj;
  }
  const camelCaseObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const camelCaseKey = this.snakeCaseToCamelCase(key);
      camelCaseObj[camelCaseKey] = this.convertToCamelCase(value);
    }
  }
  return camelCaseObj;
}
snakeCaseToCamelCase(snakeCaseString: string): string {
  return snakeCaseString.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
}
getConnectivitySetupList(){
  
  var orderType = this.orderType == 1 ? 'desc' : 'asc';
  var activityBody = {
    "orderBy": this.orderBy,
    "orderType": orderType,
    "page": this.pageNumber,
    "pageSize": this.pageSize,
    "connectivityTypeId":this.filterListData?.[0]?.connectivityType??undefined,
    "tpProfileId":this.filterListData?.[0]?.profileName??undefined,
    "direction":this.filterListData?.[0]?.messageDirection??undefined,
    "connectivityName":this.filterListData?.[0]?.connectivityName,
    "isActive":this.filterListData?.[0]?.status,
    "export":this.isExport
  }
  if(this.searchTerm==""|| this.searchTerm==null){
  this.ediService.getConnectivitySetupList(activityBody,this.isExport).subscribe({
    next: (res: any) => {
      if(!this.isExport){
        this.connectivitySetupList = res?.results;
        this.totalRecords = res?.count;
        this.storedRecords = res?.count;
        this.storePageNumber=this.pageNumber;
        this.searchList = res?.results;
        this.loading = false;
        this.connectivitySetupList = this.connectivitySetupList.map((el: any) => {
          el.createdOn = el.createdOn ? moment.utc(el.createdOn).format(Settings.DateFormats.dateFormatFrontend) : null;
          el.modifiedOn = el.modifiedOn ? moment.utc(el.modifiedOn).format(Settings.DateFormats.dateFormatFrontend) : null;
          el.connectivityTypeId=Settings.ConnectivityType[el.connectivityTypeId];
          el.direction=Settings.MessageTypeEDI[el.direction];
          el.modifiedBy=el?.modifiedBy?this.userNameDetails[el?.modifiedBy]:el?.modifiedBy;
          el.createdBy=el?.createdBy?this.userNameDetails[el?.createdBy]:el?.createdBy
          return el;
        })
      }
      else{
        this.isExport=false;
      }
    },
    error: (error) => {
      this.isExport=false;
    }
  })
}
}
getSearchEvent() {
  this.searchEventSubscription = this.filterService.getSearchData().subscribe((searchKeyword) => {
    this.connectivitySetupList = this.filterService.localSearch(searchKeyword, this.searchList, this.connectivitySetupSchema)
    if (!(searchKeyword == "")) {
      this.totalRecords = this.connectivitySetupList?.length;
    }
    else {
      if(this.storePageNumber==this.pageNumber){
      this.totalRecords = this.storedRecords;
      }
      else{
        this.getConnectivitySetupList();
      }
    }
  })
}
onHideConnectivityModal(){
  this.showConnectivityModal=false;
  this.showFTP=this.showSFTP=this.showAPI=this.showESB=this.showFSA=this.showSMTP=false;
  this.addConnectivityForm.reset();
  this.addConnectivityForm.clearValidators();
  this.addConnectivityForm.controls['status'].setValue(true);
}
sortColumn(event:any){
  if ((this.previousField != event.sortField && this.sortOrder != event.sortOrder) || (this.previousField != event.sortField && this.sortOrder == event.sortOrder)
  || (this.previousField == event.sortField && this.sortOrder != event.sortOrder)) {
  this.previousField = event.sortField
  this.sortOrder = event.sortOrder
  this.defaultOrderBy = event?.sortField;
  this.orderBy = this.defaultOrderBy.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  this.orderType = event?.sortOrder;
  this.refreshGrid();
}
}
refreshGrid(){
  if (this.pageNumber == 1) {
    this.getConnectivitySetupList();
  }
  else {
    this.paginator.changePage(0);
  }
}
jumpToPage(event){
  if(event.target.value){
  if(event.target.value<=this.paginator.paginatorState?.pageCount){
  this.paginator.changePage((event.target.value-1));
  this.jumpToPageNo="";
  }
}
}
ngOnDestroy() {
  // Unsubscribe from the subscription when the component is destroyed
  if (this.refreshClickEventSubscription) {
    this.refreshClickEventSubscription.unsubscribe();
  }
  if(this.searchEventSubscription){
    this.searchEventSubscription.unsubscribe();
  }
  if(this.clickEventSubscription){
    this.clickEventSubscription.unsubscribe();
  }
  if(this.exportClickEventSubscription){
    this.exportClickEventSubscription.unsubscribe();
  }
}
}
