<div id="layout-topbar" class="layout-topbar">
    <div class="layout-topbar-wrapper">
        <div class="layout-topbar-left">
            <a class="menu-button" *ngIf="!(currentNav?.route.includes('view') || currentNav?.route.includes('/email-log'))" href="javascript:;"
                (click)="appMain.onMenuButtonClick($event)" aria-label="Menu Toggle Button">
                <i class="pi pi-bars"></i>
            </a>
            <div class="layout-topbar-logo ml-2" id="logolink" style="cursor: pointer; outline: none;" routerLink="/">
                <img src="../../../../../assets/layout/dfds-images/TopbarLogo/CozmosLogoTopbar.png" width="100" height="40" alt="cozmos-layout">
                <div class="environment" [ngClass]="logoClass">
                    <h5 class="mt-1">{{logoEnvironment}}</h5>
                </div>
            </div>
        </div>

        <div class="layout-topbar-right">
            <div>
                <h5 style="margin:0px;font-family: 'DFDS-Bold';">{{viewInfo}}</h5>
                <p style="font-size: 12px;">{{menuTitle}}</p>
            </div>
            <a class="p-overlay-badge topbar-icon ml-2" (click)="sendRefreshClickEvent()" href="javascript:;" pTooltip="Refresh (Ctrl+R)" aria-label="Refresh the Current Page">
                <i class="fas fa-sync-alt" [@rotatedState]='rotateState' ></i>
            </a>
            <button pButton type="button" class="p-button-lg p-button-text action-button ml-1"
                *ngIf="!(route.url.includes('view') ||  (route.url == '/user/administration/app-config') || (route.url == '/user/administration/role-management')||  (route.url == '/user/administration/docstatus-management') || (route.url == '/user/administration/announcement')  || (route.url == '/user/profile/overview'))"
             id="Reset"aria-label="Reset"  pTooltip="Reset Filter" icon="pi pi-filter-slash" (click)="sendFilterReset()"
                [disabled]="!isFilter"></button>
            <ul class="layout-topbar-actions">
                <a  href="javascript:;" *ngIf="(route.url.includes('/user/document')|| route?.url=='/user/email-tracker' || route?.url=='/user/administration/activity-log')  && !route.url.includes('/view')  "
                class="topbar-icon mr-1" (click)="displaySearchDialog('top')" pTooltip="Search"  aria-label="Search Through Grid">
                <i class="fas fa-search"></i>
            </a>
                 <li #queue  *ngIf="route.url.includes('/user/document') && !route.url.includes('/view')"  class="topbar-item user-profile"
                [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === queue}">
                <a  href="javascript:;"
                    class="topbar-icon mr-1" (click)="appMain.onTopbarItemClick($event,queue)" pTooltip="Queue Status"  aria-label="Search Through Grid">
                    <img class="queueIcon" src="../../../../../assets/layout/images/fileQueue.png" width="5" height="5" >
                </a>
                <ul class="fadeInDown">
                    <li class="layout-submenu-header">
                        <h6 class="header-text">Queue Status</h6>
                    </li>
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">File Receiver Queue Count </h6><span class="ml-1 status-badge processed count">{{queueCountObject?.fileReceiverQueue}}</span>
                        </a>
                    </li>  
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">File Receiver Poison Queue Count </h6><span class="ml-1 status-badge processed count">{{queueCountObject?.fileReceiverPoisonQueue}}</span>
                        </a>
                    </li> 
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">Azure AI Queue Count </h6><span class="ml-1 status-badge processed count">{{queueCountObject?.azureQueue}}</span>
                        </a>
                    </li>     
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">Azure AI Poison Queue Count </h6><span class="ml-1 status-badge processed count">{{queueCountObject?.azurePoisonQueue}}</span>
                        </a>
                    </li>  
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between"  (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">Custom AI Queue Count </h6> <span class="ml-1 status-badge processed count">{{queueCountObject?.customQueue}}</span>
                        </a>
                    </li>    
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between"  (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">Custom AI Poison Queue Count </h6> <span class="ml-1 status-badge processed count">{{queueCountObject?.customPoisonQueue}}</span>
                        </a>
                    </li>   
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between"  (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">EDI AI Queue Count </h6> <span class="ml-1 status-badge processed count">{{queueCountObject?.ediQueue}}</span>
                        </a>
                    </li> 
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between"  (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">EDI AI Poison Queue Count </h6> <span class="ml-1 status-badge processed count">{{queueCountObject?.ediPoisonQueue}}</span>
                        </a>
                    </li>     
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between"  (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">Auto Code Queue Count </h6> <span class="ml-1 status-badge processed count">{{queueCountObject?.autocode}}</span>
                        </a>
                    </li> 
                    <li role="menuitem" >
                        <a href="javascript:;" class="justify-content-between"  (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <h6 class="no-underline">Auto Code Poison Queue Count </h6> <span class="ml-1 status-badge processed count">{{queueCountObject?.autocodePoisonQueue}}</span>
                        </a>
                    </li>       
                </ul>
            </li>
            <li #emailQueue  *ngIf="route.url.includes('/user/email-tracker') && !(route.url.includes('/view') || route.url.includes('/email-log'))"  class="topbar-item user-profile"
            [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === emailQueue}">
            <a  href="javascript:;" class="topbar-icon mr-1" (click)="appMain.onTopbarItemClick($event,emailQueue)" pTooltip="Pending Email(s) Count"  aria-label="Email Queue Status">
                <i class="far fa-mail-bulk"></i>
            </a>
            <ul class="fadeInDown">
                <li class="layout-submenu-header">
                    <h6 class="header-text">Pending Email(s) Count</h6>
                </li>
                <li role="menuitem" >
                    <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event); onClickShowMails('MAIN')"  aria-label="Menu Toggle Button" >
                            <h6 class="no-underline">AP Email(s)</h6><span class="ml-1 status-badge processed count">{{emailInboxCount?.sourceEmailCount??0}}</span>
                    </a>
                </li>   
                <li role="menuitem" >
                    <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event);onClickShowMails('INTERCOMPANY')"  aria-label="Menu Toggle Button" >
                            <h6 class="no-underline">Intercompany Email(s)</h6><span class="ml-1 status-badge processed count">{{emailInboxCount?.intercompanyEmailCount??0}}</span>
                    </a>
                </li>        
                <li role="menuitem" >
                    <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event);onClickShowMails('VELOCITY')"  aria-label="Menu Toggle Button" >
                            <h6 class="no-underline">Velocity Email(s)</h6><span class="ml-1 status-badge processed count">{{emailInboxCount?.velocityEmailCount??0}}</span>
                    </a>
                </li>   
                <li role="menuitem" >
                    <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event);onClickShowMails('VELOCITY_INTERCOMPANY')"  aria-label="Menu Toggle Button" >
                            <h6 class="no-underline">Velocity Intercompany Email(s)</h6><span class="ml-1 status-badge processed count">{{emailInboxCount?.velocityIntercompanyEmailCount??0}}</span>
                    </a>
                </li>   
            </ul>
        </li>
        <li #sftpQueue  *ngIf="privilegeList?.includes('CONNECTIVITY_LIST_VIEW') && route.url.includes('/user/email-tracker') && !(route.url.includes('/view') || route.url.includes('/email-log'))"  class="topbar-item user-profile"
        [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === sftpQueue}">
        <a  href="javascript:;" class="topbar-icon mr-1" (click)="appMain.onTopbarItemClick($event,sftpQueue)" pTooltip="SFTP File(s)"  aria-label="SFTP Files">
            <i class="fas fa-folder-tree"></i>
        </a>
        <ul class="fadeInDown">
            <li class="layout-submenu-header">
                <h6 class="header-text">SFTP File(s)</h6>
            </li>
            <li role="menuitem" *ngFor="let sftp of sftpFilesData">
                <a href="javascript:;" class="justify-content-between" (click)="appMain.onTopbarSubItemClick($event);"  aria-label="Menu Toggle Button" >
                        <h6 class="no-underline">{{sftp?.folder}}</h6><span class="ml-1 status-badge processed count">{{sftp?.fileCount}}</span>
                </a>
                <hr>
            </li>    
            <li class="my-1 p-2" *ngIf="sftpFilesData?.length==0" class="menuitem" style="text-align: center; display: block;">
                <h6>No Files yet !</h6>
                <img style="width: 100px;" src="../../../../../assets/layout/images/corruptedFile.gif"  alt="no-notifications" />
            </li>    
        </ul>
    </li>
                <li #notifications class="topbar-item notifications"
                    [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === notifications}">
                    <a href="javascript:;" *ngIf="route.url.includes('/user/document') && !route.url.includes('/view')"
                        class="p-overlay-badge topbar-icon" (click)="displayColSettingDialog()"
                        pTooltip="Column Settings"  aria-label="Change Column View">
                        <i class="fas fa-sliders-v"></i>
                    </a>
                    <a href="javascript:;" class="p-overlay-badge topbar-icon " (click)="onClickShowAnnouncement()"  aria-label="Read Announcement">
                        <img *ngIf="isUnreadAnnouncement" class="announcement-icon p-0" alt="Announcement" pTooltip="Announcement(s)" pBadge src="../../../../../assets/layout/images/unread-announcement.PNG">
                        <img *ngIf="!isUnreadAnnouncement" class="blink-icon p-0" alt="announcement-blinking" pTooltip="Announcement(s)" pBadge src="../../../../../assets/layout/images/megaphone.gif">
                    </a>
                    <a href="javascript:;" (click)="appMain.onTopbarItemClick($event,notifications)"  aria-label="View Notifications" class="pl-0" >
                        <span class="p-overlay-badge topbar-icon">
                            <i class="fas fa-bell" *ngIf="notificationCount>0" pBadge size="large"  [value]='notificationCount' severity="danger"></i>
                            <i class="fas fa-bell"   *ngIf="notificationCount===0" [value]='notificationCount' severity="danger"></i>
                        </span>
                    </a>
                    <ul class="fadeInDown">
                        <li class="layout-submenu-header">
                            <h6 class="header-text">Notifications</h6>
                              <span class="p-badge">{{notificationCount}}</span>
                            <!-- <span > <button pButton type="button" class="p-button-text p-button-danger h-1rem" (click)="onClickClearNotification()"  pTooltip="Clear All"  tooltipPosition="top" icon="pi pi-trash" ></button></span> -->
                        </li>
                        <li role="menuitem" *ngFor="let notification of notificationList">
                            <a href="javascript:;" (click)="appMain.onTopbarSubItemClick($event)"  aria-label="Menu Toggle Button" >
                                <i *ngIf="notification?.notificationType.includes('ETRACKER')" class="pi pi-envelope"></i>
                                <i *ngIf="notification?.notificationType.includes('DOCTRACKER')" class="pi pi-file"></i>
                                <div class="notifications-item" [pTooltip]="notification?.detail" (click)="onClickNavigateToNotification(notification?.id,notification?.notificationType,notification?.action)">
                                    <h6>{{notification?.title}}</h6>
                                    <span>{{notification?.notificationReceivedOn | date}} (UTC)</span>
                                </div>
                                <button pButton type="button" class="p-button-text p-button-danger h-1rem" (click)="onClickClearNotification(notification?.id)"  pTooltip="Clear"  tooltipPosition="top" icon="pi pi-trash" ></button>
                            </a>
                        </li>         
              <li class="my-1 p-2" *ngIf="notificationList?.length==0" class="menuitem" style="text-align: center; display: block;">
                            <h6>No Notifications yet !</h6>
                            <img style="width: 100px;" src="../../../../../assets/layout/images/bell.gif"  alt="no-notifications" />
                        </li>
                        <div *ngIf="notificationList?.length!==0"  class="p-2 sticky-bottom">
                                <div class="flex justify-content-between">
                                    <button (click)="onClickNavigateToNotification()" pButton   class="cozmos-primary-button" label="Mark All as Read"></button>
                                    <button (click)="onClickClearNotification()" pButton   class="text-button ml-4" label="Clear All"></button>
                                </div>
                            </div>
                    </ul>

                </li>
                <li #profile class="topbar-item user-profile"
                    [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === profile}">
                    <a href="javascript:;"  (click)="appMain.onTopbarItemClick($event,profile)"  aria-label="View User Profile">
                        <p-avatar [label]="firstLetter" styleClass="mr-2" shape="circle"></p-avatar>
                        <div class="profile-info">
                            <h6>{{userDetails?.firstName}} {{userDetails?.lastName}}</h6>
                            <span>{{userDetails?.roles?.[0]}}</span>
                        </div>
                    </a>

                    <ul class="fadeInDown">
                        <li class="layout-submenu-header" (click)="onClickNavigateToProfilePage()">
                            <p-avatar [label]="firstLetter" styleClass="mr-2" shape="circle"></p-avatar>
                            <div class="profile-info">
                                <h6>{{userDetails?.firstName}} {{userDetails?.lastName}}</h6>
                                <span>{{userDetails?.roles?.[0]}}</span>
                            </div>
                        </li>
                        <li role="menuitem">
                            <a href="javascript:;" (click)="openHandbook()"  aria-label="Support">
                                <i class="pi pi-book"></i>
                                <h6>Cozmos Handbook</h6>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="https://d365.topdesk.net/" target="_blank"   aria-label="Support">
                                <i class="pi pi-compass"></i>
                                <h6 ><u>Support</u></h6>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="javascript:;"  (click)="logoutConfirmation = true"  aria-label="Logout">
                                <i class="pi pi-power-off"></i>
                                <h6>Logout</h6>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <p-dialog header="Announcements" [modal]="true" [resizable]="false" [draggable]="false"
            [(visible)]="showAnnouncement" styleClass="w-7">
            <div class="pb-0 announcement-dialog-box">
                <div class="details-relate-box mb-3" *ngIf="announcementList?.length>0">
                    <p-accordion [multiple]="true" *ngFor="let detail of announcementList;let i = index">
                        <p-accordionTab  [selected]="i === 0 ? true: false" >
                            <ng-template pTemplate="header">
                                <div class="displayContent">
                                    <div>{{detail?.title}}</div>
                                    <div *ngIf="detail?.fileContent">
                                        <a href="javascript:;"  aria-label="Download"  class="ml-2" pTooltip="Download Attachement" (click)="downloadPDF(detail?.fileContent,detail?.shortDesc)" tooltipPosition="top">
                                            <i class="pi pi-download"></i>
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="p-card-subtitle p-2" *ngIf="detail?.longDesc" >
                                    <div class="p-my-1"  [innerHTML]="detail?.longDesc"></div>
                                </div>
                            </ng-template>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="details-relate-box mb-3" *ngIf="announcementList?.length==0">
                   <h4> There are No Announcements.</h4>
                 </div>
            </div>
        </p-dialog>
        <p-dialog styleClass="search" header="Search" [position]="position" [draggable]="true" [resizable]="false"
            [(visible)]="displaySearch">
            <form [formGroup]="searchForm">
                <div class="p-inputgroup">
                    <input type="text" class="border-round-md" pInputText placeholder="Search"
                        formControlName="searchTerm" name="searchInput">
                    <span *ngIf="!searchForm.invalid" class="p-inputgroup-addon  border-none link-button cursor-pointer"
                        (click)="searchTable()"><i class="pi pi-search"></i></span>
                    <span *ngIf="!searchForm.invalid"
                        class="p-inputgroup-addon border-none cancel-button cursor-pointer" (click)="clearTable()"><i
                            class="pi pi-times"></i></span>
                </div>
                <small *ngIf="searchForm.get('searchTerm').touched   && searchForm.get('searchTerm')?.errors?.required"
                    class="p-error">Search Field is Required </small>
                <small *ngIf="!searchForm.get('searchTerm').touched  && searchForm.get('searchTerm')?.errors?.pattern"
                    class="p-error">Leading Whitespace is not allowed </small>
            </form>
        </p-dialog>
        <!-- ----------------column settings modal--------------- -->
        <p-dialog [style]="{width: '40vw', height:'80vh'}" [modal]="true" header="Column Settings" [resizable]="false"
            [(visible)]="displayColSetting">
            <div style="text-align: right;">
                <button pButton type="button" label="SAVE" (click)="savecolumnsettings()"
                    class="cozmos-primary-button ml-2"></button>
                <button pButton pRipple type="button" label="RESET" (click)="resetColumnSettings()"
                    class=" p-button-text text-button"></button>
            </div>
            <div class="pt-1">
                <p-table [columns]="schema" [value]="schema" [reorderableColumns]="true" [resizableColumns]="true"
                    [scrollable]="true" scrollHeight="395px" scrollDirection="vertical">
                    <ng-template pTemplate="header" let-columns>
                        <tr class="block">
                            <th class="reorder-col bg-white pb-2 pt-2" *ngFor="let col of columns; let i = index;"
                                pReorderableColumn [pReorderableColumnDisabled]="col.disabled" pResizableColumn>
                                <span class="mr-3" style="width:15px;">{{i+1}}.</span>
                                <span *ngIf="!col.disabled" class="mr-4">
                                    <p-checkbox [binary]="true" inputId="binary" [(ngModel)]="col.checked"
                                        (onChange)="unCheckSchema($event, col)"></p-checkbox>
                                </span>
                                <span *ngIf="!col.disabled" class="pi pi-bars mr-3"></span>
                                <span class="ml-3">{{col.header}}</span>
                            </th>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-dialog>
        <!-- ----------------logout confirmation modal----------- -->
        <p-dialog [(visible)]="logoutConfirmation" [closable]="true" [modal]="true" [draggable]="false"
            [resizable]="false" [style]="{width: '50vw'}" header="Logout Confirmation">
            <div>
                <span>
                    You are trying to logout from Cozmos application which uses SSO login.<br><br>
                    If you click on "YES" all your active sessions will be closed.
                    You still can close Browser/Tab to close the application.
                </span>
            </div>
            <hr />
            <div class="modalButtonAlign">
                <button pButton pRipple type="button" label="No" (click)="logoutConfirmation = false"
                    class="cozmos-secondary-button mr-2"></button>
                <button pButton pRipple type="button" label="Yes" class="cozmos-primary-button"
                    (click)="logOut()"></button>
            </div>
        </p-dialog>
    </div>
</div>