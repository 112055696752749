import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './modules/common/component';
import { AppNotfoundComponent } from './pages/notfound/app.notfound.component';
import { AppErrorComponent } from './pages/error/app.error.component';
import { AppAccessdeniedComponent } from './pages/access/app.accessdenied.component';
import { AppLoginComponent } from './pages/auth/login/app.login.component';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from './modules/common/guards';
import { AppUnconfirmedComponent } from './pages/unconfirmed/app.unconfirmed.component';
import { ServerDownComponent } from './pages/server-down/server-down.component';
import { ConnectivityComponent } from './modules/user/edi-management/components/connectivity/connectivity.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', redirectTo: 'login', pathMatch: 'full'
            },
            {
                path: 'user',
                component: AppMainComponent,
                canActivateChild:[MsalGuard],
                children: [
                    {
                        path: '',
                        redirectTo: 'document',
                        pathMatch: 'full'
                    },
                    {
                        path: 'profile',
                        loadChildren: () => import('src/app/modules/profile/profile.module').then(m => m.ProfileModule)
                    }, 
                    {
                        path: 'document',
                        loadChildren: () => import('src/app/modules/user/documenttracker/documenttracker.module').then(m => m.DocumenttrackerModule)
                    }, 
                    {
                        path: 'email-tracker',
                        loadChildren: () => import('src/app/modules/user/etracker/etracker.module').then(m => m.EtrackerModule)
                    },
                    {
                        path: 'administration',
                        loadChildren: () => import('src/app/modules/user/administration/administration.module').then(m => m.AdministrationModule)
                    },
                    {
                        path: 'edi',
                        loadChildren: () => import('src/app/modules/user/edi-management/edi-management.module').then(m => m.EdiManagementModule)
                    },
                    
                    {
                        path: 'connectivity',
                        component:ConnectivityComponent
                    },
                    {
                        path: 'xml',
                        loadChildren: () => import('src/app/modules/user/xml-management/xml-management.module').then(m => m.XmlManagementModule)
                    },
                    {
                        path: 'dashboard',
                        loadChildren: () => import('src/app/modules/user/dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    {
                        path: 'master-data',
                        loadChildren: () => import('src/app/modules/user/master-data/master-data.module').then(m => m.MasterDataModule)
                    },
                    {
                        path: 'autocoding',
                        loadChildren: () => import('src/app/modules/user/autocoding/autocoding.module').then(m => m.AutocodingModule)
                    }
                ]
            },
            { path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'unconfirmed', component: AppUnconfirmedComponent },
            { path: 'server-down', component: ServerDownComponent },
            { path: 'login', component: AppLoginComponent,canActivate:[AuthGuard] },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}