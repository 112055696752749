<div class="card">
    <p-table #dt [value]="connectivitySetupList" dataKey="id" styleClass="p-datatable-sm "
        [showCurrentPageReport]="true" [paginator]="false" [columns]="connectivitySetupSchema" scrollHeight="78vh"
        [loading]="loading" [lazy]="true" (onLazyLoad)="sortColumn($event)" [sortField]="defaultOrderBy"
        [sortOrder]="orderType" [resizableColumns]="true" [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th pFrozenColumn alignFrozen="left" class="table action-column" *ngIf="privilegeList?.includes('CONNECTIVITY_CREATE_UPDATE') || privilegeList?.includes('CONNECTIVITY_DELETE')">Action </th>
                <th alignFrozen="left" pFrozenColumn class="table" [ngClass]="col?.field"
                    *ngFor="let col of connectivitySetupSchema" pSortableColumn="{{col?.field}}"
                    [pSortableColumnDisabled]="!col?.sortable"
                    [frozen]="col?.freezed">
                    <div [ngSwitch]="col?.header">
                        <span *ngSwitchDefault>{{col?.header}}<p-sortIcon *ngIf="col?.sortable==true"
                                field="{{col?.field}}" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order">
                            </p-sortIcon>
                        </span>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-activity>
            <tr>
                <td alignFrozen="left" pFrozenColumn class="table action-column" *ngIf="privilegeList?.includes('CONNECTIVITY_CREATE_UPDATE') || privilegeList?.includes('CONNECTIVITY_DELETE')">
                    <button pButton type="button" class="p-button-text action-button h-1rem" pTooltip="Edit" *ngIf="privilegeList?.includes('CONNECTIVITY_CREATE_UPDATE')" 
                        id="{{'doc-details'+activity?.id}}" aria-label="edit-details" (click)="onClickEditConnectivity(activity)"
                        tooltipPosition="top" icon="fas fa-pen"></button>
                        <button pButton type="button" class="p-button-text action-button h-1rem"  pTooltip="Delete" *ngIf="privilegeList?.includes('CONNECTIVITY_DELETE')"  
                        id="{{'delete-details'+activity?.id}}" aria-label="delete-details" (click)="onClickDelete(activity)" tooltipPosition="top" icon="fas fa-trash"  ></button>
                </td>
                <td alignFrozen="left" pFrozenColumn *ngFor="let col of connectivitySetupSchema" class="table"
                    [ngClass]="col?.field" [frozen]="col.freezed">
                    <div [ngSwitch]="col.field">
                        <span *ngSwitchCase="'isActive'" >
                            <span *ngIf="activity['isActive']">
                                <p class="status-badge active">Active</p>
                            </span>
                            <span *ngIf="!activity['isActive']">
                                <p class="status-badge inactive">Inactive</p>
                            </span>
                        </span>
                        <span *ngSwitchCase="'connectivitySettings'" class="cursor-pointer">
                            <a class="p-button-rounded p-button-success" pTooltip="Payload" tooltipPosition="top"
                                (click)="onClickPayloadIcon(activity?.connectivitySettings)"><i class="far fa-file"></i></a>
                        </span>
                        <span *ngSwitchDefault class="table-overflow" [ngClass]="col.field"
                            [pTooltip]="activity?.[col.field]" tooltipPosition="bottom"
                            tooltipStyleClass="w-9">{{activity?.[col.field]}}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No Data found.</td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator #paginator (onPageChange)="paginate($event)" [rows]="pageSize" [showCurrentPageReport]="true"
        class="pageRow" [templateRight]="templateRight"
        currentPageReportTemplate="Showing {first} to {last} of {{totalRecords}} Records " [totalRecords]="totalRecords"
        [rowsPerPageOptions]="pageSizeList"></p-paginator>
    <ng-template #templateRight>
        <span class="flex w-full paginator-goto">
            <input pInputText [(ngModel)]="jumpToPageNo" (keyup.enter)="jumpToPage($event)" type="number"
                placeholder="Go To Page"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></span>
    </ng-template>
    <a class="float" *ngIf="privilegeList?.includes('CONNECTIVITY_CREATE_UPDATE')" pTooltip="Add Trading Partner" (click)="onClickShowFormModal()" tooltipPosition="top">
        <i class="far fa-plus my-float"></i>
    </a>
    <p-dialog [header]="isAddConnectivity?'Add Connectivity':'Update Connectivity'" [modal]="true" [draggable]="false"
        [(visible)]="showConnectivityModal" [closable]="true" styleClass="w-8" (onHide)="onHideConnectivityModal()">
        <form [formGroup]="addConnectivityForm">
            <div class="grid">
                <div class="col-4">
                    <label class="fieldLabel">Status<span class="requiredField">*</span></label>
                    <p-dropdown placeholder="Select Status" [options]="statusList" formControlName="status"
                        optionLabel="name" optionValue="value" appendTo="body" showClear="true">
                    </p-dropdown>
                    <small
                        *ngIf="addConnectivityForm.get('status').touched && addConnectivityForm.get('status')?.invalid"
                        class="p-error">Status is a required field.</small>
                </div>
                <div class="col-4">
                    <label class="fieldLabel">Connectivity Type<span class="requiredField">*</span></label>
                    <p-dropdown placeholder="Select Connectivity Type" [options]="connectivityTypeList"
                        formControlName="connectivityType" optionLabel="connectivityType" optionValue="id"
                        appendTo="body" showClear="true" (onChange)="onChangeConnectivityType()">
                    </p-dropdown>
                    <small
                        *ngIf="addConnectivityForm.get('connectivityType').touched && addConnectivityForm.get('connectivityType')?.invalid"
                        class="p-error">Connectivity Type is a required field.</small>
                </div>
                <!-- <div class="col-4">
                    <label class="fieldLabel">TP Profile Name<span class="requiredField">*</span></label>
                    <p-dropdown appendTo="body" [options]="tpProfileList" optionLabel="profileName" optionValue="id"
                    formControlName="profileName" placeholder="Select" [showClear]="true">
                </p-dropdown>
                    <small *ngIf="addConnectivityForm.get('profileName').touched && addConnectivityForm.get('profileName')?.invalid"
                        class="p-error">TP Profile Name is a required field.</small>
                </div> -->

                <div class="col-4">
                    <label class="fieldLabel">Connectivity Name</label>
                    <input type="text" autocomplete="off" pInputText formControlName="connectivityName" />
                    
                </div>

                <div class="col-4">
                    <label class="fieldLabel">Message Direction<span class="requiredField">*</span></label>
                    <p-dropdown placeholder="Message Direction" [options]="messageDirectionList"
                    formControlName="direction" optionLabel="name" optionValue="id"
                    appendTo="body" showClear="true">
                </p-dropdown>
                    <small *ngIf="addConnectivityForm.get('direction').touched && addConnectivityForm.get('direction')?.invalid"
                        class="p-error">Message Direction is a required field.</small>
                </div>

                <!-- *************** SFTP and FTP Fields *********************-->
                <div class="col-4" *ngIf="showFTP || showSMTP || showSFTP">
                    <label class="fieldLabel">HOST / IP<span class="requiredField">*</span></label>
                    <input type="text" autocomplete="off" pInputText formControlName="host" />
                    <small *ngIf="addConnectivityForm.get('host').touched && addConnectivityForm.get('host')?.invalid"
                        class="p-error">HOST/IP is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showFTP || showSMTP || showSFTP">
                    <label class="fieldLabel">Port<span class="requiredField">*</span></label>
                    <input type="text" autocomplete="off" pInputText formControlName="port" />
                    <small *ngIf="addConnectivityForm.get('port').touched && addConnectivityForm.get('port')?.invalid"
                        class="p-error">Port is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showFTP || showSMTP || showSFTP">
                    <label class="fieldLabel">User ID<span *ngIf="!showSFTP" class="requiredField">*</span></label>
                    <input type="text" autocomplete="off" pInputText formControlName="userId" />
                    <small
                        *ngIf="addConnectivityForm.get('userId').touched && addConnectivityForm.get('userId')?.invalid"
                        class="p-error">User ID is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showFTP || showSMTP || showSFTP">
                    <label class="fieldLabel">Password<span *ngIf="!showSFTP" class="requiredField">*</span></label>
                    <input [type]="showPassword ? 'text' : 'password'" autocomplete="off" pInputText formControlName="password" />
                    <i [ngClass]="getIconClass()" id="togglePassword" style="margin-left: -30px; cursor: pointer;" (mousedown)="onMouseDownTogglePassword(true)"  (mouseup)="onMouseDownTogglePassword(false)"></i>
                    <small
                        *ngIf="addConnectivityForm.get('password').touched && addConnectivityForm.get('password')?.invalid"
                        class="p-error">Password is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showSFTP">
                    <label class="fieldLabel">SSH Key</label>
                    <input type="text" autocomplete="off" pInputText formControlName="sshKey" />
                    <small
                        *ngIf="addConnectivityForm.get('sshKey').touched && addConnectivityForm.get('sshKey')?.invalid"
                        class="p-error">SSH Key is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showFTP || showSFTP">
                    <label class="fieldLabel">File Path<span class="requiredField">*</span></label>
                    <input type="text" autocomplete="off" pInputText formControlName="filePath" />
                    <small
                        *ngIf="addConnectivityForm.get('filePath').touched && addConnectivityForm.get('filePath')?.invalid"
                        class="p-error">File Path is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showFTP || showSFTP">
                    <label class="fieldLabel">File Filter</label>
                    <input type="text" autocomplete="off" pInputText formControlName="fileFilter" />
                </div>
                <!-- *************** SMTP Fields ***********************************************************************************-->
                <div class="col-4" *ngIf="showSMTP">
                    <label class="fieldLabel">Key</label>
                    <input type="text" autocomplete="off" pInputText formControlName="smtpKey" />
                    <small *ngIf="addConnectivityForm.get('smtpKey').touched && addConnectivityForm.get('smtpKey')?.invalid"
                        class="p-error">Key is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showSMTP">
                    <label class="fieldLabel">Folder</label>
                    <input type="text" autocomplete="off" pInputText formControlName="folder" />
                    <small
                        *ngIf="addConnectivityForm.get('folder').touched && addConnectivityForm.get('folder')?.invalid"
                        class="p-error">Folder is a required field.</small>
                </div>
                   <!-- **********************************************************************************************************-->
                <div class="col-4" *ngIf="showFTP || showSMTP || showSFTP">
                    <label class="fieldLabel">Archive File Path</label>
                    <input type="text" autocomplete="off" pInputText formControlName="archiveFilePath" />
                </div>
                <div class="col-4" *ngIf="showFTP || showSFTP">
                    <label class="fieldLabel">Error File Path</label>
                    <input type="text" autocomplete="off" pInputText formControlName="errorFilePath" />
                </div>
                <!-- *************** API Fields *********************-->
                <div class="col-4" *ngIf="showAPI">
                    <label class="fieldLabel">URL<span class="requiredField">*</span></label>
                    <input type="text" autocomplete="off" pInputText formControlName="apiUrl" />
                    <small
                        *ngIf="addConnectivityForm.get('apiUrl').touched && addConnectivityForm.get('apiUrl')?.invalid"
                        class="p-error">URL is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showAPI">
                    <label class="fieldLabel">Method<span class="requiredField">*</span></label>
                    <input type="text" autocomplete="off" pInputText formControlName="apiMethod" />
                    <small
                        *ngIf="addConnectivityForm.get('apiMethod').touched && addConnectivityForm.get('apiMethod')?.invalid"
                        class="p-error">Method is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showAPI">
                    <label class="fieldLabel">API Key</label>
                    <input type="text" autocomplete="off" pInputText formControlName="apiKey" />
                    <small
                        *ngIf="addConnectivityForm.get('apiKey').touched && addConnectivityForm.get('apiKey')?.invalid"
                        class="p-error">API Key is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showAPI">
                    <label class="fieldLabel">User ID</label>
                    <input type="text" autocomplete="off" pInputText formControlName="userId" />
                    <small
                        *ngIf="addConnectivityForm.get('userId').touched && addConnectivityForm.get('userId')?.invalid"
                        class="p-error">User ID is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showAPI">
                    <label class="fieldLabel">Password</label>
                    <input type="password" autocomplete="off" pInputText formControlName="password" />
                    <small
                        *ngIf="addConnectivityForm.get('password').touched && addConnectivityForm.get('password')?.invalid"
                        class="p-error">Password is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showAPI">
                    <label class="fieldLabel">Param(s)</label>
                    <input type="text" autocomplete="off" pInputText formControlName="params" />
                    <small
                        *ngIf="addConnectivityForm.get('params').touched && addConnectivityForm.get('params')?.invalid"
                        class="p-error">Param(s) is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showAPI">
                    <label class="fieldLabel">Payload</label>
                    <input type="text" autocomplete="off" pInputText formControlName="payload" />
                    <small
                        *ngIf="addConnectivityForm.get('payload').touched && addConnectivityForm.get('payload')?.invalid"
                        class="p-error">Payload is a required field.</small>
                </div>
                <!-- *************** ESB Fields *********************-->
                <div class="col-4" *ngIf="showESB">
                    <label class="fieldLabel">Connection Type</label>
                    <p-dropdown placeholder="Select Connection Type" [options]="connectionTypeList"
                        formControlName="connectionType" optionLabel="name" optionValue="id" appendTo="body"
                        showClear="true">
                    </p-dropdown>
                    <small
                        *ngIf="addConnectivityForm.get('connectionType').touched && addConnectivityForm.get('connectionType')?.invalid"
                        class="p-error">Connection Type is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showESB">
                    <label class="fieldLabel">Connection String<span class="requiredField">*</span></label>
                    <input type="text" autocomplete="off" pInputText formControlName="connectionString" />
                    <small
                        *ngIf="addConnectivityForm.get('connectionString').touched && addConnectivityForm.get('connectionString')?.invalid"
                        class="p-error">Connection String is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showESB">
                    <label class="fieldLabel">Queue Name</label>
                    <input type="text" autocomplete="off" pInputText formControlName="queueName" />
                    <small
                        *ngIf="addConnectivityForm.get('queueName').touched && addConnectivityForm.get('queueName')?.invalid"
                        class="p-error">Queue Name is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showESB">
                    <label class="fieldLabel">Topic Name</label>
                    <input type="text" autocomplete="off" pInputText formControlName="topicName" />
                    <small
                        *ngIf="addConnectivityForm.get('topicName').touched && addConnectivityForm.get('topicName')?.invalid"
                        class="p-error">Topic Name is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showESB">
                    <label class="fieldLabel">Max Message Count</label>
                    <input type="number" autocomplete="off" pInputText formControlName="maxMessageCount" />
                    <small
                        *ngIf="addConnectivityForm.get('maxMessageCount').touched && addConnectivityForm.get('maxMessageCount')?.invalid"
                        class="p-error">Max Message Count is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showESB">
                    <label class="fieldLabel">Max Wait Time</label>
                    <input type="number" autocomplete="off" pInputText formControlName="maxWaitTime" />
                    <small
                        *ngIf="addConnectivityForm.get('maxWaitTime').touched && addConnectivityForm.get('maxWaitTime')?.invalid"
                        class="p-error">Max Wait Time is a required field.</small>
                </div>
                <!-- *************** FSA Fields *********************-->
                <div class="col-4" *ngIf="showFSA">
                    <label class="fieldLabel">File Path<span class="requiredField">*</span></label>
                    <input type="text" autocomplete="off" pInputText formControlName="filePath" />
                    <small
                        *ngIf="addConnectivityForm.get('filePath').touched && addConnectivityForm.get('filePath')?.invalid"
                        class="p-error">File Path is a required field.</small>
                </div>
                <div class="col-4" *ngIf="showFSA">
                    <label class="fieldLabel">Error File Path</label>
                    <input type="text" autocomplete="off" pInputText formControlName="errorFilePath" />
                    <small
                        *ngIf="addConnectivityForm.get('errorFilePath').touched && addConnectivityForm.get('errorFilePath')?.invalid"
                        class="p-error">Error File Path is a required field.</small>
                </div>
            </div>
        </form>
        <hr />
        <div class="modalButtonAlign">
            <button pButton pRipple type="button" label="Cancel" class="cozmos-primary-button mr-2"
                (click)="onHideConnectivityModal()"></button>
            <button *ngIf="!isAddConnectivity" pButton pRipple type="button" label="Update" (click)="onClickAddConnectivity()"
                class="cozmos-primary-button" [disabled]="!addConnectivityForm.valid"></button>
            <button *ngIf="isAddConnectivity" pButton pRipple type="button" label="Add" (click)="onClickAddConnectivity()" class="cozmos-primary-button"
                [disabled]="!addConnectivityForm.valid"></button>
        </div>
    </p-dialog>
    <p-dialog header="Payload" [modal]="true" [draggable]="false" [(visible)]="isPayload" [closable]="true"
        styleClass="w-4">
        <pre>{{currentPayload | json}}</pre>
    </p-dialog>
</div>
<p-toast position="top-center"></p-toast>