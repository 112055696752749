export interface ISelectedEmailFilters {
    //email tracker
    emailStatus:Number,
    messageID:string,
    emailDateRange:string[],
    emailFrom:string,
    emailTo:string,
    emailCC:string,
    emailSubject:string,
    numOfAttachements:Number,
    numOfPdf:Number,
    emailAssignTo:string
}
export interface IPreferenceModel{
    id:Number,
    userPreferenceType:string,
    userPreferenceTypeDescription:string
}

export interface IAssignableUserModel{
    id:Number,
    email:string,
    firstName:string,
    lastName:string
}

export interface ISelectedDocumentFilters {
    //doc tracker
    docStatus:Number[];
    transactionID:any,
    docType:Number,
    legalEntityID:Number[],
    legalEntityName:string,
    vendorID:string[],
    vendorName:string,
    invoiceNo:string,
    invoiceAmount:string,
    currency:string,
    invoiceDateRange:string[],
    destApp: number;
    receivedDateRange:string[];
    testIndicator: Number;
    invoiceAssignedTo: string ;
    actionTakenBy: string;
    actionTakenAtRange:string[];
}

//---------user managemnet filters----------
export interface ISelectedUserManagementFilters{
    userStatus: Number;
    roleName:string;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    userCompany:string;
}

//---------LE managemnet filters----------
export interface ISelectedLeManagementFilters{
    leLegalEntityId: string;
    leEntityName: string;
    leCountry: string;
    leCompanyNumber: string;
    leEanNumber:string;
    leApContactEmail:string;
    leVatNumber:string;
    leDestinationHold:boolean;
    leOverruleDocumentGroup:boolean;
    leReviewFlag:boolean;
    leCozmosEnabled:boolean;
    leCity:string;
    leZipCode:string;
}

//---------Vendor managemnet filters----------
export interface ISelectedVendorManagementFilters{
    veVendorAccount: string;
    veName: string;
    veAllowFalseDuplicate:Boolean;
    veLegalEntityId:string;
    veVatNumber:string;
    vePoRequired:boolean;
    veBlocked:boolean;
    veIban:string;
    veCountry:string;
    veVendorGroup:string;
    veCurrency:string;
    vendorCity:string
    vendorZipCode:string;
    veEanNumber:string;
    cozmosIgnore:boolean;
}
//-------------currency management------------//
export interface ICurrencyManagementFilters{
    countryRegion: string;
    currencyName: string;
    code: string;
    status: Number;
}
// --------------VAT Alias-------------------//
export interface IVatAliasFilters{
    vatType: string;
    vatLookupValue: string;
    vatStatus: Number;
    vatReplaceValue: string;
    vatLeId:any
}
// --------------Name Alias-------------------//
export interface INameAliasFilters{
    nameType: string;
    nameLookupValue: string;
    nameStatus: Number;
    nameReplaceValue:string;
    nameLeId:any;
}
// --------------City Alias-------------------//
export interface ICityAliasFilters{
    cityLookupValue: string;
    cityStatus: Number;
    cityReplaceValue:string;
    cityLeId:any;
    cityType:any;
}

export interface IFinancialDimensionFilters{
    financialId:string[];
    financialName:string[];
    mainAccountId:any;
    itemTaxId:any;
    financialDepartment: string[];
    costCenter: string[];
    location: string[];
    activity: string[];
    marketProducts: string[];
    equipment: string[];
    businessUnit: string[];
    segment: string[];
    worker:string[],
    firstName:string[],
    lastName:string[],
    workflow:string[]
}
export class SelectedFilters implements IFinancialDimensionFilters, ISelectedEmailFilters,ISelectedDocumentFilters, ISelectedUserManagementFilters, ISelectedLeManagementFilters, ISelectedVendorManagementFilters,ICurrencyManagementFilters, IVatAliasFilters, INameAliasFilters, ICityAliasFilters{
    workflow: string[];
    worker: string[];
    firstName: string[];
    lastName: string[];
    financialDepartment: string[];
    costCenter: string[];
    location: string[];
    activity: string[];
    marketProducts: string[];
    equipment: string[];
    businessUnit: string[];
    segment: string[];
    mainAccountId: any;
    itemTaxId: any;
    financialId: string[];
    financialName: string[];
    leOverruleDocumentGroup: boolean;
    leApContactEmail: string;
    veEanNumber: string;
    roleName: string;
    receivedDateRange: string[];
    actionTakenAtRange: string[];
    vatDueDate:string[];
    ediControlNumber:string;
    invoiceDateRange: string[];
    emailStatus: Number;
    poNumber:string;
    messageID: string;
    emailDateRange:string[];
    emailFrom: string;
    emailTo: string;
    emailCC: string;
    emailSubject: string;
    numOfAttachements: Number;
    numOfPdf: Number;
    emailAssignTo: string;
    docStatus:Number[];
    transactionID: any;
    docType: Number;
    messageType:Number;
    legalEntityID: any[];
    legalEntityName: string;
    vendorID: string[];
    numberSequence: any[];
    vendorName: string;
    invoiceDescription: string;
    invoiceNo: string;
    invoiceAmount: string;
    currency:string;
    destApp: number;
    testIndicator: Number;
    documentProvider: string;
    invoiceAssignedTo: string;
    fileLocked:boolean;
    isEmbeddedAvailable:boolean;
    fileSource:string;
    fileType:string;
    actionTakenBy: string;
    languageDoc:string;
    errorCode:any
    isAttachmentDiscrepancy:boolean;
    remarks:string;
    processingEngine:string;
    tiNumber:string;

    userStatus: Number;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    userCompany:string;

    leLegalEntityId: string;
    leEntityName: string;
    leCountry: string;
    leCompanyNumber: string;
    leEanNumber:string;
    leVatNumber:string;
    leDestinationHold:boolean;
    leReviewFlag:boolean;
    leCozmosEnabled:boolean;
    leCity:string;
    leZipCode:string;

    veVendorAccount: string;
    veName: string;
    veAllowFalseDuplicate:Boolean;
    veLegalEntityId:string;
    veVatNumber:string;
    vePoRequired:boolean;
    veBlocked:boolean;
    veIban:string;
    veCountry:string;
    veVendorGroup:string;
    veCurrency:string;
    destinationReview:string;
    documentGroupReview:string;
    vendorCity:string
    vendorZipCode:string;
    cozmosIgnore:boolean;
    salesTaxGroup:any;
    reviewAutoCodingFlag:boolean;
    runAutoCodingFlag:boolean;
    collapseLineFlag:boolean;

    countryRegion: string;
    currencyName: string;
    code: string;
    status: Number;

    lookupValue:string;
    replaceValue:string;
    aliasStatus:number

    purchaseOrder:string;

    vatType: string;
    vatLookupValue: string;
    vatStatus: Number;
    vatReplaceValue: string;
    vatLeId:any

    nameType: string;
    nameLookupValue: string;
    nameStatus: Number;
    nameReplaceValue:string;
    nameLeId:any


    cityLookupValue: string;
    cityStatus: Number;
    cityReplaceValue:string;
    cityLeId:any;
    cityType:any;

    sourceSystem:string;
    poPrefix:string;
    poPrefixStatus:Number;

    zipLookupValue:string;
    zipReplaceValue:string;
    zipStatus:Number;
    zipType:any;
    zipLeId:any;

    ErrorType:string;
    ErrorCode:string;
    ErrorMessage:string;
    ErrorEmails:string;

    popurchaseOrder: string
    poStatus: number
    legalEntityId: string
    pool: any
    povendorName: string
    povendorAccountNumber: string
    povendorCurrency: string

    documentTypeId:string;
    keyText:string;
    language:string;

    profileName:string;
    connectivityName:string;
    uniqueKeys:string;
    profileType:number;
    customProfileStatus:number;

    moduleId:number;
    userName:string;
    userId:string;
    reference:string;
    actionType:string;
    actionOnDateRange:string[];

    voucher:string;
    description:string;

    fileFormat:string;
    senderId:string;
    receiverId:string;
    senderName:string;
    receiverName:string;
    messageVersion:string;
    messageDirection:string;
    acknowledgementFlag:boolean;
    reviewFlag:boolean;
    destinationHold:boolean;
    table:string;
    messageTypeEDI:string;
    connectivityType:number;

    docTypeDashboard:any[];
    dashBoardDateRange: string[];
    allowDuplicate:boolean;
    errorCategory:any

    documentField:any;
    beforeInput:any;
    afterInput:any;
    modifiedOn:string[];
    modifiedBy:any;

    templateName:any;
    emailType:any;
    templateType:any;

    name:string

    emailFormat:string

    countryName:string;
    pdfRequiredFlag:boolean;

    jobName:string;
    jobStatus:any;
    startedOn:string[];
    completedOn:string[];
 //Filter component of extended AP invoice
    procurementCategory: string[];
    taxGroup: string[];
    taxItemGroup: string[];
    lineMainAccount: string[];
    lineBusinessUnit: string[];
    lineActivity: string[];
    lineFinancialDepartment: string[];
    lineCostCenter: string[];
    lineEquipment: string[];
    lineMarketProducts: string[];
    lineIntercompany: string[];
    lineSegment: string[];
    lineLocation: string[];
    lineBankLoans: string[];
    lineWorker: string[];
    lineFranceLocalCoa: string[];
    lineBelgiumLocalCoa: string[];
    lineTurkeyLocalCoa: string[];
    lineSpainLocalCoa: string[];
    lineItalyLocalCoa: string[];
    lineApprover: string[];
    lineApproverName: string[];

    docTransactionId: string[];
    lineTransactionId: string[];
    fieldName: any;
    actionOn:string[];
    actionBy:any
}
