import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../../../service';
import { CommonService } from '../../../service/common/common.service';
@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(!(request.url.includes('document/hand-book')|| (request.url.includes('cozBot')) ||  (request.url.includes('stat')))){
    CommonService.showLoader();
    }
    let tokenDetails = '';
    tokenDetails = "Bearer JWT" ;
    if (!/^(http|https):/i.test(request.url)) {
      let url = '';
      url = '';
      const headers: any = {
        'Authorization': `${tokenDetails}`,
        'Cache-Control': 'no-cache',
      }
      request = request.clone({
        url,
        headers: new HttpHeaders(headers)
      });
    }
   if (request.method === 'POST' || request.method === 'PUT') {
      let body = request.body;
      if (body) {
        let newBody;
        //if it is Formdata , then change each formdata keys to snake case
        if (request.body instanceof FormData) {
          newBody = new FormData();
          request.body.forEach((value: any, key: any) => {
            newBody.append(this.toSnakeCase(key), value);
          });
        } 
        //if it is body , then change each formdata keys to snake case
        else {
          newBody = this.camelCaseToSnakeCase(request.body);
        }
        request = request.clone({
          body: newBody
        });
      }
    }
    if (this.storageService.getItem('token')!=null){
      const headers: any = {
        'Authorization': `${tokenDetails}`
      }
      let url = request.url;
    request = request.clone({
      url,
      headers: new HttpHeaders(headers)
    });
    return next.handle(request);
    }
    else{

      let url = request.url;
    request = request.clone({
      url
    });
    return next.handle(request);
    }

  }

  //function to convert Camel Case Keys to Snake Case
  camelCaseToSnakeCase(obj) {
    let snakeObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        snakeObj[snakeKey] = obj[key];
      }
    }  
    return snakeObj;
  }  
  //function to convert Camel Case String to Snake case
    toSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }
}


