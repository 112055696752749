<div id="layout-config" class="layout-config "
    *ngIf="(router.url != '/user/administration/announcement') && !(router.url.includes('user/edi/rules-setup')) && (router.url != '/user/administration/role-management') && (router.url != '/user/administration/app-config')  && (router.url != '/user/administration/docstatus-management') && (router.url != '/user/profile/overview')  && !router.url.includes('/user/xml/xml-rules')">
    <a id="filterButton" aria-label="Filter Data" class="layout-config-button " href="javascript:;" pTooltip="Filter"
        class="layout-config-button filter-button fab-button" (click)="showPanel=!showPanel">
        <i class="pi pi-filter fab-button-icon"></i>
    </a>

</div>

<p-sidebar [(visible)]="showPanel" position="right" [baseZIndex]="1000" (onHide)="closeSidebar()"
    id="cozmosFilterPanel">
    <div class="sidebar-title">
        <h5>Filters</h5>
    </div>
    <div class="cozmos-filter-panel">
        <p-dropdown #dropdown [options]="templateList" placeholder="Select Template"
            *ngIf="showTemplateSection && privilegeList?.includes('CREATE_UPDATE_USER_PREFERENCE_TEMPLATE')"
            (onChange)="onChangeGetFilterTemplateDetails($event.value)" [group]="true" optionLabel="templateName"
            [(ngModel)]="templateValue" [showClear]="true">
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span>{{group.name}}</span>
                </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
                <div class="flex align-items-center justify-content-between">
                    <span class="w-11">{{item?.templateName}}</span> <span> <button
                            (click)="onClickDeleteTemplate(item?.id)" pTooltip="Remove" pButton pRipple type="button"
                            icon="pi pi-times"
                            class="p-button-rounded p-button-text p-button-danger h-1rem"></button></span>
                </div>
            </ng-template>
        </p-dropdown>
        <div class="sidebar-header">
            <!-- Actions to perform on filter-panel -->
            <span>
                <button
                    *ngIf="showUpdate && showTemplateSection && privilegeList?.includes('CREATE_UPDATE_USER_PREFERENCE_TEMPLATE')"
                    pButton label="UPDATE" class="sidebar-button cozmos-secondary-button"
                    [disabled]="templateValue==null||disableSave" (click)="saveTemplate.toggle($event)"></button>
                <button
                    *ngIf="!showUpdate && showTemplateSection && privilegeList?.includes('CREATE_UPDATE_USER_PREFERENCE_TEMPLATE')"
                    pButton label="SAVE" class="sidebar-button cozmos-secondary-button"
                    (click)="saveTemplate.toggle($event)" [disabled]="disableSave"></button>
                <button pButton label="APPLY" class="sidebar-button cozmos-primary-button"
                    (click)="onClickApplyFilter()" [disabled]="disableSave"></button>
                <button pButton label="RESET" class="sidebar-button cozmos-secondary-button"
                    (click)="onClickReset()"></button>
            </span>
        </div>
        <!-- Save Template with Unique Template Name -->
        <p-overlayPanel #saveTemplate styleClass="w-30rem">
            <ng-template pTemplate>
                <h5 class="p-2 pb-1 mb-0 overlay-header">Save Template</h5>
                <form [formGroup]="saveTemplateForm">
                    <div class="fluid p-2">
                        <p-radioButton inputId="1" class="ml-2" name="templateSave" [value]=true
                            formControlName="isTemplateShared" label="Public"></p-radioButton>
                        <p-radioButton inputId="0" class="ml-2" name="templateSave" [value]=false
                            formControlName="isTemplateShared" label="Private"></p-radioButton>
                        <small
                            *ngIf="saveTemplateForm.get('isTemplateShared').touched && saveTemplateForm.get('isTemplateShared')?.invalid"
                            class="p-error">Please Select Whether you want to Keep this Template Private or
                            Public</small>
                        <div class="field col-12 mb-0 pb-0 pl-0 pr-0">
                            <input id="templateName" placeholder="Enter Unique Template Name" pInputText type="text"
                                formControlName="uniqueTemplateName" minlength="3" maxlength="30"
                                (input)="onInputTemplateNameRequired()"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <small
                            *ngIf="saveTemplateForm.get('uniqueTemplateName').touched && saveTemplateForm.get('uniqueTemplateName')?.invalid && saveTemplateForm.get('uniqueTemplateName')?.errors?.required"
                            class="p-error">Template Name is Required </small>
                        <small
                            *ngIf="saveTemplateForm.get('uniqueTemplateName').touched && saveTemplateForm.get('uniqueTemplateName')?.invalid && saveTemplateForm.get('uniqueTemplateName')?.errors?.minlength"
                            class="p-error">Template Name should be minimum of 3
                            characters.</small>
                    </div>
                </form>
                <div class="m-2 mt-0 flex justify-content-end">
                    <button pButton label="CANCEL" class="cozmos-secondary-button p-button-sm ml-1"
                        (click)="saveTemplate.hide($event)"></button>
                    <button pButton label="SAVE" class="cozmos-primary-button p-button-sm ml-2"
                        (click)="onClickSaveTemplate(); saveTemplate.hide($event)"
                        [disabled]="saveTemplateForm.invalid"></button>
                </div>
            </ng-template>
        </p-overlayPanel>
        <hr class="break-line">
        <div class="p-fluid cozmos-filter-wrapper">
            <!-- Doc Tracker Filter -->
            <div class="cozmos-visible-filters" id="Document" *ngIf="router.url.includes('/user/document')">

                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-multiSelect [options]="docTrackerStatusList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.docStatus" placeholder="Select" optionLabel="statusName"
                        optionValue="id" display="chip" (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Doc-Track ID</label>
                    <input pInputText type="text" placeholder="Enter Doc-Track ID"
                        [(ngModel)]="selectedFilter.transactionID" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Type</label>
                    <p-dropdown [options]="documentTypeList" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.docType" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Group</label>
                    <p-dropdown [options]="messageTypeList" optionLabel="messageTypeName" optionValue="id"
                        [(ngModel)]="selectedFilter.messageType" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-multiSelect [options]="leListId" [(ngModel)]="selectedFilter.legalEntityID" optionLabel="value"
                        optionValue="value" [filter]="true" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" display="chip">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.legalEntityName"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Account</label>
                    <p-multiSelect #vendorId (onPanelShow)="onPanelHideResetOptions(vendorId)" appendTo="body" display="chip"
                        [filter]="true" [options]="vendorAccountList" [showClear]="true" emptyFilterMessage="Enter min. 5 Characters"
                        emptyMessage="Enter min. 5 Characters" [(ngModel)]="selectedFilter.vendorID" placeholder="Select"
                        filterPlaceHolder="Enter at least 5 characters to search" (onFilter)="docTrackerFilterOptionsVendorAccount($event,vendorId)"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.vendorName"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">PO Number</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.poNumber" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">TI Number</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.tiNumber" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document ID</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.invoiceNo" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Date</label>
                    <p-calendar #myDateRangeCalendarDoc1 (onClearClick)="onClearCalendar()"
                        placeholder="Select Start and End Date" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [(ngModel)]="selectedFilter.invoiceDateRange"
                        (onSelect)="onSelectFilterDateRange()" (onTodayClick)="onTodayGetTodayValue()"
                        [hideOnDateTimeSelect]="true">
                    </p-calendar>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Currency</label>
                    <p-dropdown [(ngModel)]="selectedFilter.currency" [options]="currencyList" optionLabel="code"
                        optionValue="code" [filter]="true" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Language</label>
                    <p-dropdown placeholder="Select Language" [options]="languageList"
                        [(ngModel)]="selectedFilter.languageDoc" optionLabel="name" optionValue="name" [filter]="true"
                        appendTo="body" showClear="true" (onChange)="onChangeFilterFieldValues()">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Destination </label>
                    <p-dropdown [(ngModel)]="selectedFilter.destApp" [options]="destinationList" optionLabel="destName"
                        optionValue="destName" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Received (UTC)</label>
                    <p-calendar appendTo="body" [hideOnDateTimeSelect]="true" placeholder="Select Start and End Date"
                        [maxDate]="maxDate" (onClearClick)="onClearCalendar()" #myDateRangeCalendarDoc2
                        selectionMode="range" [showIcon]="true" showClear="true" showButtonBar="true"
                        [(ngModel)]="selectedFilter.receivedDateRange" inputId="range"
                        (onSelect)="onSelectFilterDateRange()" (onTodayClick)="onTodayGetTodayValue()">
                    </p-calendar>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Assigned To</label>
                    <p-autoComplete styleClass="p-0" placeholder="Search by Name" emptyMessage="No Data Found"
                        showEmptyMessage="true" [(ngModel)]="selectedFilter.invoiceAssignedTo"
                        [suggestions]="assignedToUserList" (completeMethod)="getAssignedToUserList($event)"
                        [forceSelection]="true" [minLength]="3" field="userName"
                        (onSelect)="onSelectActionTakenBy()"></p-autoComplete>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Action By</label>
                    <p-autoComplete styleClass="p-0" placeholder="Search by Name" emptyMessage="No Data Found"
                        showEmptyMessage="true" [(ngModel)]="selectedFilter.actionTakenBy"
                        [suggestions]="actionTakenByList" (completeMethod)="getActionTakenUserList($event)"
                        [forceSelection]="true" [minLength]="3" field="userName"
                        (onSelect)="onSelectActionTakenBy()"></p-autoComplete>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Action At</label>
                    <p-calendar appendTo="body" (onClearClick)="onClearCalendar()"
                        placeholder="Select Start and End Date" [maxDate]="maxDate" [hideOnDateTimeSelect]="true"
                        (onTodayClick)="onTodayGetTodayValue()" #myDateRangeCalendarDoc3 selectionMode="range"
                        [showIcon]="true" showClear="true" showButtonBar="true" [readonlyInput]="false"
                        [(ngModel)]="selectedFilter.actionTakenAtRange" inputId="range"
                        (onSelect)="onSelectFilterDateRange()">
                    </p-calendar>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">File Locked</label>
                    <p-dropdown [(ngModel)]="selectedFilter.fileLocked" [options]="fileLockedList" optionLabel="label"
                        optionValue="value" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Attachments</label>
                    <p-dropdown [(ngModel)]="selectedFilter.isEmbeddedAvailable" [options]="fileLockedList"
                        optionLabel="label" optionValue="value" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">File Source</label>
                    <p-dropdown [options]="fileSourceList" optionLabel="fileSourceName" optionValue="fileSourceName"
                        [(ngModel)]="selectedFilter.fileSource" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">File Type</label>
                    <p-dropdown [options]="fileTypeList" optionLabel="fileTypeName" optionValue="id"
                        [(ngModel)]="selectedFilter.fileType" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Error Code List </label>
                    <p-multiSelect [options]="errorCodeList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.errorCode" placeholder="Select" optionLabel="errorCode"
                        optionValue="errorCode" display="chip" filterBy="errorMessage"
                        (onChange)="onChangeFilterFieldValues()">
                        <ng-template let-error pTemplate="item">
                            <div class="error-item">
                                <div>{{error?.errorMessage}} ({{error?.errorCode}})</div>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Test Indicator</label>
                    <p-dropdown [(ngModel)]="selectedFilter.testIndicator" [options]="testIndicatorList"
                        optionLabel="testIndicatorName" optionValue="id" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Provider </label>
                    <p-dropdown [(ngModel)]="selectedFilter.documentProvider" [options]="documentProvider"
                        optionLabel="docProviderName" optionValue="id" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Remarks</label>
                    <input pInputText type="text" placeholder="Enter Remarks" [(ngModel)]="selectedFilter.remarks"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">VAT Due Date</label>
                    <p-calendar appendTo="body" (onClearClick)="onClearCalendar()"
                        placeholder="Select Start and End Date" [hideOnDateTimeSelect]="true"
                        (onTodayClick)="onTodayGetTodayValue()" #myDateRangeCalendarDoc4 selectionMode="range"
                        [showIcon]="true" showClear="true" showButtonBar="true" [readonlyInput]="false"
                        [(ngModel)]="selectedFilter.vatDueDate" inputId="range" (onSelect)="onSelectFilterDateRange()">
                    </p-calendar>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">EDI Reference No.</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.ediControlNumber"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Processing Engine</label>
                    <p-dropdown [options]="processingEngineList" optionLabel="name" optionValue="name"
                        [(ngModel)]="selectedFilter.processingEngine" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!-- EDI -->
            <div class="cozmos-visible-filters" id="tradingPartner"
                *ngIf="router.url.includes('/user/edi/trading-partner-setup')">

                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="currencyStatusOptions" optionLabel="statusName" optionValue="statusId"
                        [(ngModel)]="selectedFilter.status" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">File Format</label>
                    <p-dropdown [options]="fileFormatList" optionLabel="fileFormatName" optionValue="fileFormatName"
                        [(ngModel)]="selectedFilter.fileFormat" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Profile Name</label>
                    <p-dropdown [options]="profileNameList" optionLabel="profileName" optionValue="profileName"
                        [(ngModel)]="selectedFilter.profileName" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Sender ID</label>
                    <p-dropdown [options]="senderIdList" optionLabel="senderId" optionValue="senderId"
                        [(ngModel)]="selectedFilter.senderId" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Receiver ID</label>
                    <p-dropdown [options]="receiverIdList" optionLabel="receiverId" optionValue="receiverId"
                        [(ngModel)]="selectedFilter.receiverId" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Sender Name</label>
                    <p-dropdown [options]="senderNameList" optionLabel="senderName" optionValue="senderName"
                        [(ngModel)]="selectedFilter.senderName" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Receiver Name</label>
                    <p-dropdown [options]="receiverNameList" optionLabel="receiverName" optionValue="receiverName"
                        [(ngModel)]="selectedFilter.receiverName" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Message Type</label>
                    <p-dropdown [options]="messageTypeEDIList" optionLabel="messageType" optionValue="messageType"
                        [(ngModel)]="selectedFilter.messageTypeEDI" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Message Version</label>
                    <p-dropdown [options]="messageVersionList" optionLabel="messageVersion" optionValue="messageVersion"
                        [(ngModel)]="selectedFilter.messageVersion" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Message Direction</label>
                    <p-dropdown [options]="messageDirectionList" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.messageDirection" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Acknowledgement Flag</label>
                    <p-dropdown [options]="booleanList" optionLabel="name" optionValue="value"
                        [(ngModel)]="selectedFilter.acknowledgementFlag" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Allow Duplicate</label>
                    <p-dropdown appendTo="body" [options]="booleanList" optionLabel="name" optionValue="value"
                        [(ngModel)]="selectedFilter.allowDuplicate" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <!-- <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Review Flag</label>
                    <p-dropdown [options]="booleanList" optionLabel="name" optionValue="value"
                        [(ngModel)]="selectedFilter.reviewFlag" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">On Hold</label>
                    <p-dropdown appendTo="body" [options]="booleanList" optionLabel="name" optionValue="value"
                        [(ngModel)]="selectedFilter.destinationHold" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div> -->
            </div>
            <div class="cozmos-visible-filters" id="document-type"
                *ngIf="router.url.includes('/user/administration/document-type')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.name" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Processing Flag</label>
                    <p-dropdown [options]="processingFlagList" optionLabel="name" optionValue="value"
                        [(ngModel)]="selectedFilter.status" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <div class="cozmos-visible-filters" id="codelist" *ngIf="router.url=='/user/edi/code-list'">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Table Name</label>
                    <p-dropdown [options]="codelistTableList" optionLabel="name" optionValue="name"
                        [(ngModel)]="selectedFilter.table" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Description</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.description"
                        (keyup)="onSearchFieldKeyUp()">

                </div>

            </div>
            <div class="cozmos-visible-filters" id="dashboard" *ngIf="router.url=='/user/dashboard'">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Date Range<span class="requiredField">*</span></label>
                    <p-calendar #myDateRangeCalendarDoc1 placeholder="Select Start and End Date" selectionMode="range"
                        [(ngModel)]="selectedFilter.invoiceDateRange" [showIcon]="true" showClear="true"
                        showButtonBar="true" class="ml-1" styleClass="w-11" (onClearClick)="onClearCalendar()"
                        (onSelect)="onSelectFilterDateRange()" [hideOnDateTimeSelect]="true">
                    </p-calendar>
                    <small *ngIf="!selectedFilter.invoiceDateRange" class="requiredField">Date Range is required
                        field.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Type</label>
                    <p-multiSelect [options]="documentTypeList" optionLabel="name" styleClass="w-11" optionValue="id"
                        [filter]="true" placeholder="Select Document Type" class="ml-1"
                        [(ngModel)]="selectedFilter.docTypeDashboard" (onChange)="onChangeFilterFieldValues()"
                        [showClear]="true" display="chip">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Name</label>
                    <p-multiSelect [options]="vendorListName" filterPlaceholder="Enter min. 3 Characters "
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" #vendorId class="ml-1"
                        styleClass="w-11" placeholder="Select Vendor Account" [(ngModel)]="selectedFilter.vendorName"
                        (onPanelShow)="onPanelHideResetOptions(vendorId)"
                        (onFilter)="filterOptionsVendorName($event,vendorId)"
                        emptyFilterMessage="Enter minimum 3 Characters" emptyMessage="Enter minimum 3 Characters"
                        optionLabel="name" optionValue="vendorAccount" [filter]="true" [showClear]="true"
                        display="chip">
                        <ng-template let-item pTemplate="item">
                            <div class="flex align-items-center justify-content-between">
                                <span class="w-11">{{item?.name}}</span> <span>({{item?.vendorAccount}}) </span>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-multiSelect [options]="leListId" class="ml-1" [(ngModel)]="selectedFilter.legalEntityID"
                        (onChange)="onChangeFilterFieldValues()" styleClass="w-11" [filter]="true" optionValue="value"
                        optionLabel="value" placeholder="Select LE ID" [showClear]="true" display="chip">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Provider</label>
                    <p-dropdown [options]="documentProvider" optionLabel="docProviderName" class="ml-1"
                        styleClass="w-11" optionValue="id" [filter]="true" [(ngModel)]="selectedFilter.documentProvider"
                        (onChange)="onChangeFilterFieldValues()" placeholder="Select Document Provider"
                        [showClear]="true" display="chip">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">File Type</label>
                    <p-dropdown [options]="fileTypeList" optionLabel="fileTypeName" class="ml-1" styleClass="w-11"
                        optionValue="id" [filter]="true" [(ngModel)]="selectedFilter.fileType"
                        (onChange)="onChangeFilterFieldValues()" placeholder="Select File Type" [showClear]="true"
                        display="chip">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Destination</label>
                    <p-multiSelect placeholder="Select Destination" [options]="sourceSystemOptions" styleClass="w-11"
                        [(ngModel)]="selectedFilter.pool" optionLabel="name" optionValue="name" appendTo="body"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-multiSelect>
                </div>
            </div>
            <div class="cozmos-visible-filters" id="codelist-library"
                *ngIf="router.url.includes('user/edi/code-list/library')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Sender ID</label>
                    <p-dropdown [options]="senderIdList" optionLabel="senderId" optionValue="senderId"
                        [(ngModel)]="selectedFilter.senderId" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Receiver ID</label>
                    <p-dropdown [options]="receiverIdList" optionLabel="receiverId" optionValue="receiverId"
                        [(ngModel)]="selectedFilter.receiverId" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Message Type</label>
                    <p-dropdown [options]="messageTypeEDIList" optionLabel="messageType" optionValue="messageType"
                        [(ngModel)]="selectedFilter.messageTypeEDI" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">LookUp Value</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.lookupValue"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
            </div>
            <div class="cozmos-visible-filters" id="connectivity" *ngIf="router.url.includes('user/connectivity')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="currencyStatusOptions" optionLabel="statusName" optionValue="statusId"
                        [(ngModel)]="selectedFilter.status" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <!-- <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Profile Name</label>
                    <p-dropdown [options]="profileNameList" optionLabel="profileName" optionValue="id"
                        [(ngModel)]="selectedFilter.profileName" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown> -->
                <!-- </div> -->
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Connectivity Profile Name</label>
                    <input pInputText type="text" placeholder="Enter Connectivity Name"
                        [(ngModel)]="selectedFilter.connectivityName" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Connectivity Type</label>
                    <p-dropdown [options]="connectivityTypeList" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.connectivityType" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Message Direction</label>
                    <p-dropdown [options]="messageDirectionList" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.messageDirection" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!---- E-Invoice XML Management ------->
            <div class="cozmos-visible-filters" id="einvoice"
                *ngIf="router.url.includes('/user/xml') && !router.url.includes('/user/xml/xml-rules')">

                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="currencyStatusOptions" optionLabel="statusName" optionValue="statusId"
                        [(ngModel)]="selectedFilter.status" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Profile Name</label>
                    <p-dropdown [options]="eInvoiceProfileNameList" optionLabel="profileName" optionValue="profileName"
                        [(ngModel)]="selectedFilter.profileName" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Country Name</label>
                    <p-dropdown [options]="eInvoiceCountryNameList" optionLabel="countryName" optionValue="countryName"
                        [(ngModel)]="selectedFilter.countryName" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Generate XML PDF Flag</label>
                    <p-dropdown [options]="booleanList" optionLabel="name" optionValue="value"
                        [(ngModel)]="selectedFilter.pdfRequiredFlag" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!-- PO management--------- -->
            <div class="cozmos-visible-filters" id="Document"
                *ngIf="router.url.includes('user/master-data/po-management')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="poStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.poStatus" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Purchase Order</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.popurchaseOrder"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown placeholder="Select Legal Entity ID" [options]="legalIdOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true"
                        [(ngModel)]="selectedFilter.legalEntityId" optionLabel="name" optionValue="name" appendTo="body"
                        showClear="true" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Account</label>
                    <p-dropdown placeholder="Select Vendor Account" [options]="vendorAccountOptionsList"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true"
                        [(ngModel)]="selectedFilter.povendorAccountNumber" optionLabel="vendorAccount"
                        optionValue="vendorAccount" appendTo="body" showClear="true" resetFilterOnHide="true"
                        [autoDisplayFirst]="false" filterPlaceholder="Enter min. 5 Characters"
                        (onFilter)="filterVendorAccountOptions($event)">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.povendorName"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Currency</label>
                    <p-dropdown [(ngModel)]="selectedFilter.povendorCurrency" [options]="currencyListOptions"
                        optionLabel="name" optionValue="name" [filter]="true" placeholder="Select Vendor Currency"
                        [showClear]="true" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Destination (Pool)</label>
                    <p-dropdown placeholder="Select Pool type" [options]="sourceSystemOptions"
                        [(ngModel)]="selectedFilter.pool" optionLabel="name" optionValue="name" appendTo="body"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!-- Email Tracker Filter -->
            <div class="cozmos-visible-filters" id="Email" *ngIf="router.url.includes('/user/email-tracker')">

                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="eTrackerStatusList" [(ngModel)]="selectedFilter.emailStatus"
                        placeholder="Select" optionLabel="statusName" optionValue="statusId" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">E-Track ID</label>
                    <input pInputText type="text" placeholder="Enter E-Track ID" [(ngModel)]="selectedFilter.messageID"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Received (UTC)</label>
                    <p-calendar #myDateRangeCalendar selectionMode="range" [maxDate]="maxDate"
                        placeholder="Select Start and End Date" [hideOnDateTimeSelect]="true"
                        (onClearClick)="onClearCalendar()" [showIcon]="true" showClear="true" showButtonBar="true"
                        (onTodayClick)="onTodayGetTodayValue()" [(ngModel)]="selectedFilter.emailDateRange"
                        (onSelect)="onSelectFilterDateRange()">
                    </p-calendar>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">From</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailFrom" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">To</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailTo" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">CC</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailCC" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Subject</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailSubject"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Attachments</label>
                    <input pInputText type="number" [(ngModel)]="selectedFilter.numOfAttachements"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">PDFs</label>
                    <input pInputText type="number" [(ngModel)]="selectedFilter.numOfPdf"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Assign To</label>
                    <p-autoComplete styleClass="p-0" placeholder="Search by Name" emptyMessage="No Data Found"
                        showEmptyMessage="true" [(ngModel)]="selectedFilter.emailAssignTo"
                        [suggestions]="assignedToUserList" (completeMethod)="getAssignedToUserList($event)"
                        [forceSelection]="true" [minLength]="3" field="userName"
                        (onSelect)="onSelectActionTakenBy()"></p-autoComplete>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Test Indicator</label>
                    <p-dropdown [(ngModel)]="selectedFilter.testIndicator" [options]="testIndicatorList"
                        optionLabel="testIndicatorName" optionValue="id" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Remarks</label>
                    <input pInputText type="text" placeholder="Enter Remarks" [(ngModel)]="selectedFilter.remarks"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Attachment Discrepancies</label>
                    <p-dropdown [options]="booleanList" optionLabel="name" optionValue="value"
                        [(ngModel)]="selectedFilter.isAttachmentDiscrepancy" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!-- ---User management Filter- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/administration/user-management')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" [(ngModel)]="selectedFilter.userStatus"
                        placeholder="Select status" optionLabel="name" optionValue="code"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Email</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.userEmail" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">First Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.userFirstName"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Last Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.userLastName"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Role</label>
                    <p-dropdown [options]="roleNameOptions" [(ngModel)]="selectedFilter.roleName"
                        placeholder="Select Role" optionLabel="roleName" optionValue="roleName"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Company Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.userCompany"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-multiSelect [options]="leListId" [(ngModel)]="selectedFilter.legalEntityID" optionLabel="value"
                        optionValue="value" [filter]="true" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" display="chip">
                    </p-multiSelect>
                </div>
            </div>
            <!-- ---LE management Filter- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/master-data/le-management')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="leStatusOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.leCozmosEnabled" placeholder="Select Status" optionLabel="name"
                        optionValue="code" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown placeholder="Select Legal Entity ID" [options]="legalIdOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true"
                        [(ngModel)]="selectedFilter.leLegalEntityId" optionLabel="value" optionValue="value"
                        appendTo="body" showClear="true" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Entity Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.leEntityName"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.leEntityName?.length >255" class="p-error">Enity Name should be maximum
                        of 255
                        characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Zip Code</label>
                    <p-dropdown placeholder="Select Zip Code" [options]="zipOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true" [(ngModel)]="selectedFilter.leZipCode"
                        optionLabel="value" optionValue="value" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">City</label>
                    <p-dropdown placeholder="Select City" [options]="cityOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true" [(ngModel)]="selectedFilter.leCity"
                        optionLabel="value" optionValue="value" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Country Code</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.leCountry" (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.leCountry?.length > 2" class="p-error">Country Code should be maximum
                        of 2
                        characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">VAT Number</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.leVatNumber"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.leVatNumber?.length >20" class="p-error">VAT Number should be maximum
                        of 20
                        characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Company Number</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.leCompanyNumber"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.leCompanyNumber?.length >50" class="p-error">Company Number should be
                        maximum of 50
                        characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">EAN Number</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.leEanNumber"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.leEanNumber?.length >50" class="p-error">EAN Number should be maximum
                        of 50
                        characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">AP Contact Email</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.leApContactEmail"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Review Flag</label>
                    <p-dropdown [options]="sourceSystem" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.leReviewFlag" placeholder="Select Review Flag"
                        optionLabel="sourceSystemName" optionValue="id" (onChange)="onChangeFilterFieldValues()"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Destination Hold</label>
                    <p-dropdown [options]="sourceSystem" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.leDestinationHold" placeholder="Select Destination Hold"
                        optionLabel="sourceSystemName" optionValue="id" (onChange)="onChangeFilterFieldValues()"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Overrule Document Group Flag</label>
                    <p-dropdown [options]="sourceSystem" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.leOverruleDocumentGroup" placeholder="Select Overrule Document Group" 
                        optionLabel="sourceSystemName" optionValue="id" (onChange)="onChangeFilterFieldValues()"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>

            </div>
            <!-- ---Vendor management Filter- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/master-data/vendor-management')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="vendorStatusOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.veBlocked" placeholder="Select Status" optionLabel="name"
                        optionValue="code" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown placeholder="Select Legal Entity ID" [options]="legalIdOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true"
                        [(ngModel)]="selectedFilter.veLegalEntityId" optionLabel="name" optionValue="name"
                        appendTo="body" showClear="true" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Account</label>
                    <p-dropdown placeholder="Select Vendor Account" [options]="vendorAccountOptionsList"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true"
                        [(ngModel)]="selectedFilter.veVendorAccount" optionLabel="vendorAccount"
                        optionValue="vendorAccount" appendTo="body" showClear="true" resetFilterOnHide="true"
                        [autoDisplayFirst]="false" filterPlaceholder="Enter min. 5 Characters"
                        (onFilter)="filterVendorAccountOptions($event)">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.veName" (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.veName?.length >255" class="p-error">Vendor Name should be maximum
                        of 255 characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">EAN Number</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.veEanNumber"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Zip Code</label>
                    <p-dropdown placeholder="Select Zip Code" [options]="zipOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true"
                        [(ngModel)]="selectedFilter.vendorZipCode" optionLabel="name" optionValue="name" appendTo="body"
                        showClear="true" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">City</label>
                    <p-dropdown placeholder="Select City" [options]="cityOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true" [(ngModel)]="selectedFilter.vendorCity"
                        optionLabel="name" optionValue="name" appendTo="body" showClear="true" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Country Code</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.veCountry" (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.veCountry?.length >2" class="p-error">Country Code should be maximum of
                        2
                        characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Currency</label>
                    <p-dropdown [(ngModel)]="selectedFilter.veCurrency" [options]="currencyListOptions"
                        optionLabel="name" optionValue="name" [filter]="true" placeholder="Select Currency"
                        [showClear]="true" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Sales Tax Group</label>
                    <p-dropdown placeholder="Select Sales Tax Group" [options]="dropwDownOptions.salesTaxGroup" 
                        optionLabel="value"  [(ngModel)]="selectedFilter.salesTaxGroup" optionValue="value" appendTo="body" (onChange)="onChangeFilterFieldValues()" showClear="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Review Auto Coding</label>
                    <p-dropdown [options]="leVendorOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.reviewAutoCodingFlag" placeholder="Select Review Auto Coding" optionLabel="name"
                        optionValue="code" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Run Auto Coding</label>
                    <p-dropdown [options]="leVendorOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.runAutoCodingFlag" placeholder="Select Run Auto Coding" optionLabel="name"
                        optionValue="code" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Collapse Line Flag</label>
                    <p-dropdown [options]="leVendorOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.collapseLineFlag" placeholder="Select Collapse Line Flag" optionLabel="name"
                        optionValue="code" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">VAT Number</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.veVatNumber"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.veVatNumber?.length >20" class="p-error">VAT Number should be maximum
                        of 20
                        characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">IBAN</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.veIban" (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.veIban?.length >50" class="p-error">IBAN should be maximum of 50
                        characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Group</label>
                    <p-dropdown [(ngModel)]="selectedFilter.veVendorGroup" [options]="vendorGroupOptions"
                        optionLabel="name" optionValue="name" [filter]="true" placeholder="Select Vendor Group"
                        [showClear]="true" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">PO Required</label>
                    <p-dropdown [options]="leVendorOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.vePoRequired" placeholder="Select PO Required" optionLabel="name"
                        optionValue="code" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Allow Duplicate</label>
                    <p-dropdown [options]="leVendorOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.veAllowFalseDuplicate" placeholder="Allow Duplicate"
                        optionLabel="name" optionValue="code" (onChange)="onChangeFilterFieldValues()"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Group Review</label>
                    <p-dropdown [options]="messageTypeList" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.documentGroupReview" placeholder="Select Document Group Review"
                        optionLabel="messageTypeName" optionValue="id" (onChange)="onChangeFilterFieldValues()"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Destination Review</label>
                    <p-dropdown [options]="destinationList" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.destinationReview" placeholder="Select Destination Review"
                        optionLabel="destName" optionValue="id" (onChange)="onChangeFilterFieldValues()"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Source System</label>
                    <p-multiSelect [(ngModel)]="selectedFilter.sourceSystem" [options]="sourceSystem"
                        optionLabel="sourceSystemName" optionValue="id" [filter]="true" placeholder="Select"
                        [showClear]="true" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Cozmos Ignore</label>
                    <p-dropdown [options]="booleanList" optionLabel="name" optionValue="value"
                        [(ngModel)]="selectedFilter.cozmosIgnore" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Unsupported Formats</label>
                    <p-dropdown [options]="fileTypeList" optionLabel="fileTypeName" optionValue="id"
                        [(ngModel)]="selectedFilter.fileType" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Remark</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.remarks" (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.remarks?.length >250" class="p-error">Remark should be maximum of 250
                        characters.</small>
                </div>
            </div>
            <!-- ---Email Template Filter- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/administration/email-template')|| router.url.includes('/user/administration/manual-email-template')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="leStatusOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.emailStatus" placeholder="Select Status" optionLabel="name"
                        optionValue="code" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Template Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.templateName"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs" *ngIf="router.url.includes('administration/manual-email-template')">
                    <label class="font font-color-dark">Template Type</label>
                    <p-dropdown [options]="templateTypeList" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.templateType" placeholder="Select Template Type" optionLabel="name"
                        optionValue="key" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs" *ngIf="router.url.includes('administration/email-template')">
                    <label class="font font-color-dark">Email Type</label>
                    <p-dropdown [options]="emailTypeOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.emailType" placeholder="Select Email Type" optionLabel="name"
                        optionValue="id" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Email To</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailTo" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Email CC</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailCC" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Subject</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailSubject"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
            </div>
            <!-- ---Email Template Filter- -->
            <div class="cozmos-visible-filters" id="usage-count"
                *ngIf="router.url.includes('/user/administration/usage-count')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">From User</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailFrom" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">To User</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailTo" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Doc-Track ID</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.transactionID"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
            </div>
            <!-- ---Email Template Filter- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/administration/emailtemplate-controller')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="leStatusOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.emailStatus" placeholder="Select Status" optionLabel="name"
                        optionValue="code" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown [options]="leIdOptions" [(ngModel)]="selectedFilter.legalEntityId" [filter]="true"
                        placeholder="Select Legal Entity ID" appendTo="body" showClear="true" optionLabel="name"
                        optionValue="name" (onChange)="onChangeFilterFieldValues();" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Account</label>
                    <p-multiSelect #vendorId (onPanelShow)="onPanelHideResetOptions(vendorId)" appendTo="body"
                        display="chip" [filter]="true" [options]="vendorAccountList" [showClear]="true"
                        emptyFilterMessage="Enter min. 3 Characters" emptyMessage="Enter min. 3 Characters"
                        [(ngModel)]="selectedFilter.vendorID" filterPlaceholder="Enter min. 3 Characters "
                        (onFilter)="filterOptionsVendorAccount($event,vendorId)"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Error Code List </label>
                    <p-dropdown [options]="errorCodeList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.errorCode" placeholder="Select" optionLabel="errorCode"
                        optionValue="errorCode" filterBy="errorMessage" (onChange)="onChangeFilterFieldValues()">
                        <ng-template let-error pTemplate="item">
                            <div class="error-item">
                                <div>{{error?.errorMessage}} ({{error?.errorCode}})</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <!-- --currency management--- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/master-data/currency-management')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="currencyStatusOptions" (onChange)="onChangeFilterFieldValues()"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" optionLabel="statusName"
                        optionValue="statusId" appendTo="body" showClear="true" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Country Region</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.countryRegion"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.countryRegion?.length >150" class="p-error">Country Region should
                        be maximum of 150 characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Currency Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.currencyName"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.currencyName?.length >20" class="p-error">Currency Name should
                        be maximum of 20 characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Currency Code</label>
                    <p-dropdown [(ngModel)]="selectedFilter.code" [options]="currencyListOptions" optionLabel="name"
                        optionValue="name" [filter]="true" placeholder="Select Currency Code" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!----- Ap Invoice Export ----->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/master-data/ap-invoice-export')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Invoice Number</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.invoiceNo" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Invoice Date</label>
                    <p-calendar #myDateRangeCalendarDoc1 appendTo="body" placeholder="Select Start and End Date"
                        (onClearClick)="onClearCalendar()" [maxDate]="maxDate" [hideOnDateTimeSelect]="true"
                        (onTodayClick)="onTodayGetTodayValue()" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [readonlyInput]="false" [(ngModel)]="selectedFilter.invoiceDateRange"
                        inputId="range" (onSelect)="onSelectFilterDateRange()">
                    </p-calendar>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Purchase Order</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.purchaseOrder"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Number Sequence Groups</label>
                    <p-dropdown [(ngModel)]="selectedFilter.numberSequence" [options]="financialDimensionNameList" optionLabel="code"
                        optionValue="code" [filter]="true" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Invoice Description</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.invoiceDescription"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Currency</label>
                    <p-dropdown [(ngModel)]="selectedFilter.currency" [options]="currencyList" optionLabel="code"
                        optionValue="code" [filter]="true" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown [options]="leListId" [(ngModel)]="selectedFilter.legalEntityID" optionLabel="value"
                        optionValue="value" [filter]="true" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Account</label>
                    <p-dropdown placeholder="Select Vendor Account" [options]="vendorAccountOptionsList"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true" [(ngModel)]="selectedFilter.vendorID"
                        optionLabel="vendorAccount" optionValue="vendorAccount" appendTo="body" showClear="true"
                        resetFilterOnHide="true" [autoDisplayFirst]="false" filterPlaceholder="Enter min. 5 Characters"
                        (onFilter)="filterVendorAccountOptions($event)">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Voucher</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.voucher" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Source System</label>
                    <p-dropdown [(ngModel)]="selectedFilter.sourceSystem" [options]="sourceSystem"
                        optionLabel="sourceSystemName" optionValue="sourceSystemName" [filter]="true"
                        placeholder="Select" [showClear]="true" (onChange)="onChangeFilterFieldValues()"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!-- ---VAT Alias Filter- -->
            <div class="cozmos-visible-filters" id="user" *ngIf="router.url.includes('/user/master-data/vat-alias')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" [(ngModel)]="selectedFilter.vatStatus"
                        placeholder="Select status" optionLabel="name" optionValue="code"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Type</label>
                    <p-dropdown [options]="typeOptions" [(ngModel)]="selectedFilter.vatType" placeholder="Select Type"
                        appendTo="body" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues(); aliasLeIdOptions();" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown [options]="leIdOptions" [(ngModel)]="selectedFilter.vatLeId" [filter]="true"
                        placeholder="Select Legal Entity ID" appendTo="body" showClear="true" optionLabel="name"
                        optionValue="name" (onChange)="onChangeFilterFieldValues(); getReplaceValueOptions('vat');"
                        resetFilterOnHide="true">
                    </p-dropdown>
                    <small *ngIf="!selectedFilter.vatType" class="infoText">Note: For this, Please select
                        the Type first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">VAT Replace Value</label>
                    <p-dropdown placeholder="Select Replace Value" [options]="replaceValueOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.vatReplaceValue" optionLabel="name" optionValue="name"
                        resetFilterOnHide="true">
                    </p-dropdown>
                    <small *ngIf="!selectedFilter.vatLeId" class="infoText">Note: For this, Please select
                        the Type & Legal Entity ID first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">VAT Lookup Value</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.vatLookupValue"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.vatLookupValue?.length >100" class="p-error">VAT Lookup Value should be
                        maximum of 100 characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Last Time Used</label>
                    <p-calendar appendTo="body" #myDateRangeCalendarDoc4 (onClearClick)="onClearCalendar()"
                        placeholder="Select Start and End Date" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [(ngModel)]="selectedFilter.actionOnDateRange" [maxDate]="maxDate"
                        (onSelect)="onSelectFilterDateRange()" (onTodayClick)="onTodayGetTodayValue()"
                        [hideOnDateTimeSelect]="true">
                    </p-calendar>
                </div>
            </div>
            <!-- ---Name Alias Filter- -->
            <div class="cozmos-visible-filters" id="user" *ngIf="router.url.includes('/user/master-data/name-alias')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" [(ngModel)]="selectedFilter.nameStatus"
                        placeholder="Select status" optionLabel="name" optionValue="code"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Type</label>
                    <p-dropdown [options]="typeOptions" [(ngModel)]="selectedFilter.nameType" placeholder="Select Type"
                        appendTo="body" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues(); aliasLeIdOptions();" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown [options]="leIdOptions" [(ngModel)]="selectedFilter.nameLeId" [filter]="true"
                        placeholder="Select Legal Entity ID" appendTo="body" showClear="true" optionLabel="name"
                        optionValue="name" (onChange)="onChangeFilterFieldValues(); getReplaceValueOptions('name');"
                        resetFilterOnHide="true">
                    </p-dropdown>
                    <small *ngIf="!selectedFilter.nameType" class="infoText">Note: For this, Please select
                        the Type first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Name Replace Value</label>
                    <p-dropdown placeholder="Select Replace Value" [options]="replaceValueOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.nameReplaceValue" optionLabel="name" optionValue="name"
                        resetFilterOnHide="true">
                    </p-dropdown>
                    <small *ngIf="!selectedFilter.nameLeId" class="infoText">Note: For this, Please select
                        the Type & Legal Entity ID first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Name Lookup Value</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.nameLookupValue"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.nameLookupValue?.length >100" class="p-error">Name Lookup Value should
                        be maximum of 100 characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Last Time Used</label>
                    <p-calendar appendTo="body" #myDateRangeCalendarDoc4 (onClearClick)="onClearCalendar()"
                        placeholder="Select Start and End Date" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [(ngModel)]="selectedFilter.actionOnDateRange" [maxDate]="maxDate"
                        (onSelect)="onSelectFilterDateRange()" (onTodayClick)="onTodayGetTodayValue()"
                        [hideOnDateTimeSelect]="true">
                    </p-calendar>
                </div>
            </div>
            <!-- ---City Alias Filter- -->
            <div class="cozmos-visible-filters" id="user" *ngIf="router.url.includes('/user/master-data/city-alias')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" [(ngModel)]="selectedFilter.cityStatus"
                        placeholder="Select status" optionLabel="name" optionValue="code"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Type</label>
                    <p-dropdown [options]="typeOptions" [(ngModel)]="selectedFilter.cityType" placeholder="Select Type"
                        appendTo="body" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues(); aliasLeIdOptions();" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown [options]="leIdOptions" [(ngModel)]="selectedFilter.cityLeId" [filter]="true"
                        placeholder="Select Legal Entity ID" appendTo="body" showClear="true" optionLabel="name"
                        optionValue="name" (onChange)="onChangeFilterFieldValues(); getReplaceValueOptions('city');"
                        resetFilterOnHide="true">
                    </p-dropdown>
                    <small *ngIf="!selectedFilter.cityType" class="infoText">Note: For this, Please select
                        the Type first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">City Replace Value</label>
                    <p-dropdown placeholder="Select Replace Value" [options]="replaceValueOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.cityReplaceValue" optionLabel="name" optionValue="name"
                        resetFilterOnHide="true">
                    </p-dropdown>
                    <small *ngIf="!selectedFilter.cityLeId" class="infoText">Note: For this, Please select
                        the Type & Legal Entity ID first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">City Lookup Value</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.cityLookupValue"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.cityLookupValue?.length >100" class="p-error">City Lookup Value should
                        be maximum of 100 characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Last Time Used</label>
                    <p-calendar appendTo="body" #myDateRangeCalendarDoc4 (onClearClick)="onClearCalendar()"
                        placeholder="Select Start and End Date" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [(ngModel)]="selectedFilter.actionOnDateRange" [maxDate]="maxDate"
                        (onSelect)="onSelectFilterDateRange()" (onTodayClick)="onTodayGetTodayValue()"
                        [hideOnDateTimeSelect]="true">
                    </p-calendar>
                </div>
            </div>
            <!-- Currency Alias Management -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/master-data/currencyalias-management')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.aliasStatus" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Currency Lookup Value</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.lookupValue"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Currency Replace Value</label>
                    <p-dropdown [(ngModel)]="selectedFilter.replaceValue" [options]="currencyListOptions"
                        optionLabel="name" optionValue="name" [filter]="true"
                        placeholder="Select Currency Replace Value" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!-- Vendor Alias Management -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('/user/master-data/reference-lookup')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown [options]="leIdOptions" [(ngModel)]="selectedFilter.legalEntityId" [filter]="true"
                        placeholder="Select Legal Entity ID" appendTo="body" showClear="true" optionLabel="value"
                        optionValue="value" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Currency</label>
                    <p-dropdown [options]="currencyList" [(ngModel)]="selectedFilter.currency" [filter]="true"
                        placeholder="Select Currency" appendTo="body" showClear="true" optionLabel="code"
                        optionValue="code" (onChange)="onChangeFilterFieldValues();" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Lookup Value</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.lookupValue"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Vendor Replace Value</label>
                    <p-dropdown [(ngModel)]="selectedFilter.replaceValue" [options]="vendorAccountOptionsList"
                        optionLabel="vendorAccount" optionValue="vendorAccount" [filter]="true"
                        placeholder="Enter min. 5 Characters" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" filterPlaceholder="Enter min. 5 Characters"
                        resetFilterOnHide="true" (onFilter)="filterVendorAccountOptions($event)"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!-------PO prefix management---- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('user/administration/poprefix-management')">

                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" [(ngModel)]="selectedFilter.poPrefixStatus"
                        placeholder="Select status" optionLabel="name" optionValue="code"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Source System</label>
                    <p-dropdown placeholder="Select type" [options]="sourceSystemOptions"
                        [(ngModel)]="selectedFilter.sourceSystem" optionLabel="name" optionValue="id" appendTo="body"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">PO Prefix</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.poPrefix" (keyup)="onSearchFieldKeyUp()">
                </div>
            </div>
            <!-- ---------zip code alias---------- -->
            <div class="cozmos-visible-filters" id="user" *ngIf="router.url.includes('user/master-data/zipcode-alias')">

                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" [(ngModel)]="selectedFilter.zipStatus"
                        placeholder="Select status" optionLabel="name" optionValue="code"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Type</label>
                    <p-dropdown [options]="typeOptions" [(ngModel)]="selectedFilter.zipType" placeholder="Select Type"
                        appendTo="body" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues(); aliasLeIdOptions();" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown [options]="leIdOptions" [(ngModel)]="selectedFilter.zipLeId" [filter]="true"
                        placeholder="Select Legal Entity ID" appendTo="body" showClear="true" optionLabel="name"
                        optionValue="name" (onChange)="onChangeFilterFieldValues(); getReplaceValueOptions('zip');"
                        resetFilterOnHide="true">
                    </p-dropdown>
                    <small *ngIf="!selectedFilter.zipType" class="infoText">Note: For this, Please select
                        the Type first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Zip Replace Value</label>
                    <p-dropdown placeholder="Select Zip Replace Value" [options]="replaceValueOptions"
                        (onChange)="onChangeFilterFieldValues()" [filter]="true"
                        [(ngModel)]="selectedFilter.zipReplaceValue" optionLabel="name" optionValue="name"
                        appendTo="body" showClear="true" resetFilterOnHide="true">
                    </p-dropdown>
                    <small *ngIf="!selectedFilter.zipLeId" class="infoText">Note: For this, Please select
                        the Type & Legal Entity ID first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Zip Lookup Value</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.zipLookupValue"
                        (keyup)="onSearchFieldKeyUp()">
                    <small *ngIf="selectedFilter.zipLookupValue?.length >100" class="p-error">Zip Lookup Value should
                        be maximum of 100 characters.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Last Time Used</label>
                    <p-calendar appendTo="body" #myDateRangeCalendarDoc4 (onClearClick)="onClearCalendar()"
                        placeholder="Select Start and End Date" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [(ngModel)]="selectedFilter.actionOnDateRange" [maxDate]="maxDate"
                        (onSelect)="onSelectFilterDateRange()" (onTodayClick)="onTodayGetTodayValue()"
                        [hideOnDateTimeSelect]="true">
                    </p-calendar>
                </div>
            </div>
            <!-- ---------error code list------- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('user/administration/errocodelist')">

                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Type</label>
                    <p-dropdown placeholder="Select Error type" [options]="ErrorTypes"
                        [(ngModel)]="selectedFilter.ErrorType" optionLabel="name" optionValue="name" appendTo="body"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true"></p-dropdown>

                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Error Code</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.ErrorCode" (keyup)="onSearchFieldKeyUp()">

                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Error Category</label>
                    <p-dropdown placeholder="Select Error Category" [options]="errorCategoryList"
                        [(ngModel)]="selectedFilter.errorCategory" optionLabel="errorCategory" optionValue="id"
                        appendTo="body" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true"></p-dropdown>

                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Error Message</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.ErrorMessage"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Emails</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.ErrorEmails"
                        (keyup)="onSearchFieldKeyUp()">

                </div>

            </div>
            <!----------------Data Change Logger-->
            <div class="cozmos-visible-filters" id="data-change"
                *ngIf="router.url.includes('user/administration/data-change-logger')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Reference ID</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.transactionID"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Module</label>
                    <p-dropdown [options]="moduleList" [filter]="true" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.moduleId" placeholder="Select Module" [showClear]="true"
                        (onChange)="onChangeGetSummaryKeysValue($event)" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Field</label>
                    <p-dropdown placeholder="Enter Document Field" [filter]="true" [options]="documentFieldList"
                        [(ngModel)]="selectedFilter.documentField" [showClear]="true" optionLabel="display"
                        optionValue="value" (onChange)="onChangeFilterFieldValues()" appendTo="body"
                        resetFilterOnHide="true"></p-dropdown>
                    <small *ngIf="!selectedFilter.documentField" class="infoText">Note: For this, Please select
                        the Module first.</small>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Before Input</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.beforeInput"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">After Input</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.afterInput"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Modified On</label>
                    <p-calendar #myDateRangeCalendarDataLogger appendTo="body" placeholder="Select Start and End Date"
                        (onClearClick)="onClearCalendar()" [maxDate]="maxDate" [hideOnDateTimeSelect]="true"
                        (onTodayClick)="onTodayGetTodayValue()" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [readonlyInput]="false" [(ngModel)]="selectedFilter.modifiedOn"
                        inputId="range" (onSelect)="onSelectFilterDateRange()">
                    </p-calendar>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Modified By</label>
                    <p-autoComplete styleClass="p-0" placeholder="Search by Name" emptyMessage="No Data Found"
                        showEmptyMessage="true" [(ngModel)]="selectedFilter.modifiedBy"
                        [suggestions]="assignedToUserList" (completeMethod)="getAssignedToUserList($event)"
                        [forceSelection]="true" [minLength]="3" field="userName"
                        (onSelect)="onSelectActionTakenBy()"></p-autoComplete>
                </div>
            </div>

            <!----------------Data Change Logger-->
            <div class="cozmos-visible-filters" id="data-change"
                *ngIf="router.url.includes('user/administration/dms-exception-logs')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">User</label>
                    <p-autoComplete styleClass="p-0" placeholder="Search by Name" emptyMessage="No Data Found"
                        showEmptyMessage="true" [(ngModel)]="selectedFilter.modifiedBy"
                        [suggestions]="assignedToUserList" (completeMethod)="getAssignedToUserList($event)"
                        [forceSelection]="true" [minLength]="3" field="userName"
                        (onSelect)="onSelectActionTakenBy()"></p-autoComplete>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Doc-Track ID</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.transactionID"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Module</label>
                    <p-dropdown [options]="moduleList" [filter]="true" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.moduleId" placeholder="Select Module" [showClear]="true"
                        (onChange)="onChangeGetSummaryKeysValue($event)" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Action</label>
                    <p-dropdown [options]="actionTypeOptions" [filter]="true" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.actionType" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeGetSummaryKeysValue($event)" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Action On</label>
                    <p-calendar #myDateRangeCalendarDataLogger appendTo="body" placeholder="Select Start and End Date"
                        (onClearClick)="onClearCalendar()" [maxDate]="maxDate" [hideOnDateTimeSelect]="true"
                        (onTodayClick)="onTodayGetTodayValue()" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [readonlyInput]="false" [(ngModel)]="selectedFilter.modifiedOn"
                        inputId="range" (onSelect)="onSelectFilterDateRange()">
                    </p-calendar>
                </div>
            </div>

            <!----------------Cron Job Logger-->
            <div class="cozmos-visible-filters" id="cron-change"
                *ngIf="router.url.includes('user/administration/cron-job-logger')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Job Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.jobName" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Job Status</label>
                    <p-dropdown [options]="docTrackerStatusList" [filter]="true" optionLabel="statusName"
                        optionValue="id" [(ngModel)]="selectedFilter.jobStatus" placeholder="Select Job Status"
                        [showClear]="true" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Started On</label>
                    <p-calendar #myDateRangeCalendarDoc4 appendTo="body" placeholder="Select Start and End Date"
                        (onClearClick)="onClearCalendar()" [maxDate]="maxDate" [hideOnDateTimeSelect]="true"
                        (onTodayClick)="onTodayGetTodayValue()" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [readonlyInput]="false" [(ngModel)]="selectedFilter.startedOn"
                        inputId="range" (onSelect)="onSelectFilterDateRange()">
                    </p-calendar>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Completed On</label>
                    <p-calendar #myDateRangeCalendarDataLogger appendTo="body" placeholder="Select Start and End Date"
                        (onClearClick)="onClearCalendar()" [maxDate]="maxDate" [hideOnDateTimeSelect]="true"
                        (onTodayClick)="onTodayGetTodayValue()" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [readonlyInput]="false" [(ngModel)]="selectedFilter.completedOn"
                        inputId="range" (onSelect)="onSelectFilterDateRange()">
                    </p-calendar>
                </div>
            </div>
            <!-- ---------language Intent Alias list------- -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('user/administration/languageintent-alias')">

                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Document Type</label>

                    <p-dropdown placeholder="Enter Document Type" [options]="documentTypeList"
                        [(ngModel)]="selectedFilter.documentTypeId" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" optionLabel="name" optionValue="id" appendTo="body"
                        resetFilterOnHide="true"></p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Synonym</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.keyText" (keyup)="onSearchFieldKeyUp()">

                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Language</label>
                    <p-dropdown placeholder="Select Language" [options]="languageList"
                        [(ngModel)]="selectedFilter.language" optionLabel="name" optionValue="name" [filter]="true"
                        appendTo="body" showClear="true" (onChange)="onChangeFilterFieldValues()"></p-dropdown>
                </div>
            </div>
            <!-- Activity Log Filter -->
            <div class="cozmos-visible-filters" id="activity"
                *ngIf="router.url.includes('/user/administration/activity-log')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">User Email ID</label>
                    <p-autoComplete styleClass="p-0" placeholder="Search by Email" emptyMessage="No Data Found"
                        showEmptyMessage="true" [(ngModel)]="selectedFilter.userId" [suggestions]="assignedToUserList"
                        (completeMethod)="getAssignedToUserListByEmail($event)" [forceSelection]="true" [minLength]="3"
                        field="email" (onSelect)="onSelectActionTakenBy()"></p-autoComplete>
                    <!-- <input pInputText type="number" [(ngModel)]="selectedFilter.userId" (keyup)="onSearchFieldKeyUp()"> -->
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Username</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.userName" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Module</label>
                    <p-dropdown [options]="moduleList" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.moduleId" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Reference</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.reference" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Action</label>
                    <p-dropdown [options]="actionTypeOptions" optionLabel="name" optionValue="id"
                        [(ngModel)]="selectedFilter.actionType" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Action On (UTC)</label>
                    <p-calendar appendTo="body" #myDateRangeCalendarDoc4 (onClearClick)="onClearCalendar()"
                        placeholder="Select Start and End Date" selectionMode="range" [showIcon]="true" showClear="true"
                        showButtonBar="true" [(ngModel)]="selectedFilter.actionOnDateRange" [maxDate]="maxDate"
                        (onSelect)="onSelectFilterDateRange()" (onTodayClick)="onTodayGetTodayValue()"
                        [hideOnDateTimeSelect]="true">
                    </p-calendar>
                </div>
            </div>
            <!-- Blacklist Email Log Filter -->
            <div class="cozmos-visible-filters" id="blacklist"
                *ngIf="router.url.includes('/user/administration/blacklist-email')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Email Format</label>
                    <p-dropdown [options]="emailFormatOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.emailFormat" placeholder="Select Email Format" optionLabel="name"
                        optionValue="id" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Name / Value</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.emailTo" (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Email Type</label>
                    <p-dropdown [options]="emailTypeOptions" appendTo="body" showClear="true"
                        [(ngModel)]="selectedFilter.emailType" placeholder="Select Email Type" optionLabel="name"
                        optionValue="id" (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>
            <!-- ----custom profile------ -->
            <div class="cozmos-visible-filters" id="user"
                *ngIf="router.url.includes('user/administration/customprofile-list')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" [(ngModel)]="selectedFilter.customProfileStatus"
                        placeholder="Select status" optionLabel="name" optionValue="code"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Profile Type</label>
                    <p-dropdown placeholder="Enter Profile Type" [options]="ProfileTypeOptions" optionLabel="Name"
                        optionValue="id" appendTo="body" (onChange)="onChangeFilterFieldValues()"
                        [(ngModel)]="selectedFilter.profileType" showClear="true" resetFilterOnHide="true"></p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Profile Name</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.profileName"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Description</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.description"
                        (keyup)="onSearchFieldKeyUp()">
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-dropdown [options]="leListIdProfile" [(ngModel)]="selectedFilter.legalEntityID"
                        optionLabel="value" optionValue="id" [filter]="true" placeholder="Select" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Unique Keys</label>
                    <input pInputText type="text" [(ngModel)]="selectedFilter.uniqueKeys"
                        (keyup)="onSearchFieldKeyUp()">
                </div>

            </div>

            <!----------------Auto coding Activity----------------->
            <div class="cozmos-visible-filters" id="autocoding-activity"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/activity')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Activity ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true" optionLabel="reference" optionValue="reference"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Activity Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
            <!----------------Auto coding Bank Loan----------------->
            <div class="cozmos-visible-filters" id="autocoding-bank-loan"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/bank-loans')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Bank Loan ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Bank Loan Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
            <!----------------Auto coding Business Unit----------------->
            <div class="cozmos-visible-filters" id="autocoding-business-unit"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/business-unit')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Business Unit ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true" optionLabel="reference" optionValue="reference"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Business Unit Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true" optionLabel="name" optionValue="name"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
            <!----------------Auto coding Cost Center----------------->
            <div class="cozmos-visible-filters" id="autocoding-cost-center"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/cost-center')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Cost Center ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Cost Center Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
            <!----------------Auto coding Equipment----------------->
            <div class="cozmos-visible-filters" id="autocoding-equipment"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/equipment')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Equipment ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Equipment Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
            <!----------------Auto coding Financial Department----------------->
            <div class="cozmos-visible-filters" id="autocoding-financial-department"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/financial-department')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Financial Department ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Financial Department Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true" optionLabel="name" optionValue="name"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
            <!----------------Auto coding Intercompany----------------->
            <div class="cozmos-visible-filters" id="autocoding-intercompany"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/intercompany')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Intercompany ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true" optionLabel="reference" optionValue="reference"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Intercompany Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true" optionLabel="name" optionValue="name"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
            <!----------------Auto coding Location----------------->
            <div class="cozmos-visible-filters" id="autocoding-location"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/location')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Location ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()"> 
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Location Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>

            <!----------------Auto coding Market Product----------------->
            <div class="cozmos-visible-filters" id="autocoding-market-products"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/market-products')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Market Product ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Market Product Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
            <!----------------Auto coding Segment----------------->
            <div class="cozmos-visible-filters" id="autocoding-segment"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/segment')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Segment ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Segment Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>

            <!----------------Auto coding Worker----------------->
            <div class="cozmos-visible-filters" id="autocoding-worker"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/worker')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Worker ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()"> 
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Worker Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>

            <!----------------Auto coding LocalCoa----------------->
            <div class="cozmos-visible-filters" id="autocoding-local-coa"
                *ngIf="router.url.includes('/user/autocoding/financial-dimensions/') && router.url.includes('local')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">LocalCoA ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">LocalCoA Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>

            <!----------------Auto coding Main Account----------------->
            <div class="cozmos-visible-filters" id="autocoding-main-account"
                *ngIf="router.url.includes('/user/autocoding/main-accounts')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Main Account ID</label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Main Account Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>

            <!----------------Auto coding Number Sequnce----------------->
            <div class="cozmos-visible-filters" id="autocoding-number-sequence"
                *ngIf="router.url.includes('/user/autocoding/number-sequence-groups')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Number Sequence Groups ID </label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Number Sequence Groups Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()"> 
                    </p-multiSelect>
                </div>
            </div>

            <!----------------Auto coding Sales Tax Groups ----------------->
            <div class="cozmos-visible-filters" id="autocoding-sales-tax-groups"
                *ngIf="router.url.includes('/user/autocoding/sales-tax-group')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Sales Tax Groups ID </label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Sales Tax Groups Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true" optionLabel="name" optionValue="name"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-multiSelect [options]="leIdOptions" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.legalEntityId"  resetFilterOnHide="true" placeholder="Select" display="chip" optionLabel="name"
                        optionValue="name" (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>

            <!----------------Auto coding Item Sales Tax Groups ----------------->
            <div class="cozmos-visible-filters" id="autocoding-item-sales-tax-groups"
                *ngIf="router.url.includes('/user/autocoding/item-sales-tax-group')">
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Status</label>
                    <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
                        [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
                        (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
                        resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Sales Tax Groups ID </label>
                    <p-multiSelect [options]="financialDimensionIdList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialId" placeholder="Select" display="chip" optionLabel="reference" optionValue="reference"
                        (onChange)="onChangeFilterFieldValues()"> 
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Sales Tax Groups Name</label>
                    <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
                        (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
                <div class="p-field filter-inputs">
                    <label class="font font-color-dark">Legal Entity ID</label>
                    <p-multiSelect [options]="leIdOptions" appendTo="body" [showClear]="true"
                        [(ngModel)]="selectedFilter.legalEntityId"  resetFilterOnHide="true" placeholder="Select" display="chip" optionLabel="name"
                        optionValue="name" (onChange)="onChangeFilterFieldValues()">
                    </p-multiSelect>
                </div>
            </div>
     <!----------------Auto coding Single Approvers ----------------->
     <div class="cozmos-visible-filters" id="single-approvers"
     *ngIf="router.url.includes('/user/autocoding/single-approvers')">
     <div class="p-field filter-inputs">
         <label class="font font-color-dark">Status</label>
         <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
             [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
             (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
             resetFilterOnHide="true">
         </p-dropdown>
     </div>
     <div class="p-field filter-inputs">
        <label class="font font-color-dark">User ID</label>
        <p-multiSelect  placeholder="Select User ID"  [(ngModel)]="selectedFilter.financialId" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.singleApproverId" [filter]="true" display="chip" [showClear]="true" 
        optionLabel="label" optionValue="value" appendTo="body">
    </p-multiSelect>
      </div>
      <div class="p-field filter-inputs">
        <label class="font font-color-dark">Worker ID</label>
        <p-multiSelect  placeholder="Select Worker ID"  [(ngModel)]="selectedFilter.worker" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.worker" [filter]="true" display="chip" [showClear]="true" 
        optionLabel="value" optionValue="value" appendTo="body">
    </p-multiSelect>
      </div>
      <div class="p-field filter-inputs">
        <label class="font font-color-dark">First Name</label>
        <p-multiSelect  placeholder="Select First Name"  [(ngModel)]="selectedFilter.firstName" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.singleApproverFirstName" [filter]="true" display="chip" [showClear]="true" 
        optionLabel="value" optionValue="value" appendTo="body">
    </p-multiSelect>
      </div>
      <div class="p-field filter-inputs">
        <label class="font font-color-dark">Last Name</label>
        <p-multiSelect  placeholder="Select Last Name"  [(ngModel)]="selectedFilter.lastName" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.singleApproverLastName" [filter]="true" display="chip" [showClear]="true" 
        optionLabel="value" optionValue="value" appendTo="body">
    </p-multiSelect>
      </div>

 </div> 

      <!----------------Auto coding Group Approvers ----------------->
      <div class="cozmos-visible-filters" id="group-approvers"
      *ngIf="router.url.includes('/user/autocoding/group-approvers')">
      <div class="p-field filter-inputs">
          <label class="font font-color-dark">Status</label>
          <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
              [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
              (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
              resetFilterOnHide="true">
          </p-dropdown>
      </div>
      <div class="p-field filter-inputs">
        <label class="font font-color-dark">Equipment</label>
        <p-multiSelect  placeholder="Select Equipment"  [(ngModel)]="selectedFilter.equipment" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.equipment" [filter]="true" display="chip" [showClear]="true" 
        optionLabel="value" optionValue="value" appendTo="body">
    </p-multiSelect>
      </div>
      <div class="p-field filter-inputs">
       <label class="font font-color-dark">Location</label>
       <p-multiSelect  placeholder="Select Location"  [(ngModel)]="selectedFilter.location" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.location" [filter]="true" display="chip" [showClear]="true" 
       optionLabel="value" optionValue="value" appendTo="body">
   </p-multiSelect>
     </div>
      <div class="p-field filter-inputs">
         <label class="font font-color-dark">User Group ID</label>
         <p-multiSelect  placeholder="Select User ID"  [(ngModel)]="selectedFilter.financialId" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.groupApproverId" [filter]="true" display="chip" [showClear]="true" 
         optionLabel="value" optionValue="value" appendTo="body">
     </p-multiSelect>
       </div>
       <div class="p-field filter-inputs">
        <label class="font font-color-dark">Group Name</label>
        <p-multiSelect  placeholder="Select Group Name"  [(ngModel)]="selectedFilter.financialName" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.groupApproverName" [filter]="true" display="chip" [showClear]="true" 
        optionLabel="value" optionValue="value" appendTo="body">
    </p-multiSelect>
      </div>
       <div class="p-field filter-inputs">
         <label class="font font-color-dark">Workflow</label>
         <p-multiSelect  placeholder="Select Workflow"  [(ngModel)]="selectedFilter.workflow" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.workflow" [filter]="true" display="chip" [showClear]="true" 
         optionLabel="value" optionValue="value" appendTo="body">
     </p-multiSelect>
       </div>
 
  </div> 
     <!----------------Auto coding Item Procurement Categories ----------------->
     <div class="cozmos-visible-filters" id="procurement-categories"
     *ngIf="router.url.includes('/user/autocoding/procurement-categories')">
     <div class="p-field filter-inputs">
         <label class="font font-color-dark">Status</label>
         <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
             [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
             (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
             resetFilterOnHide="true">
         </p-dropdown>
     </div>
     <div class="p-field filter-inputs">
         <label class="font font-color-dark">Procurement Category Name</label>
         <p-multiSelect [options]="financialDimensionNameList" appendTo="body" [showClear]="true"
             [(ngModel)]="selectedFilter.financialName" placeholder="Select" display="chip" optionLabel="name" optionValue="name"
             (onChange)="onChangeFilterFieldValues()">
         </p-multiSelect>
     </div>
     <div class="p-field filter-inputs">
         <label class="font font-color-dark">Main Account ID</label>
         <p-dropdown [options]="dropwDownOptions.mainAccount" appendTo="body" [showClear]="true"
             [(ngModel)]="selectedFilter.mainAccountId"  resetFilterOnHide="true" placeholder="Select"  optionLabel="value"
             optionValue="value" (onChange)="onChangeFilterFieldValues()">
         </p-dropdown>
     </div>
     <div class="p-field filter-inputs">
        <label class="font font-color-dark">Item Tax ID</label>
        <p-dropdown [options]="dropwDownOptions.itemSalesTaxGroup" appendTo="body" [showClear]="true"
            [(ngModel)]="selectedFilter.itemTaxId"  resetFilterOnHide="true" placeholder="Select" optionLabel="value"
            optionValue="value" (onChange)="onChangeFilterFieldValues()">
        </p-dropdown>
    </div>
    <div class="p-field filter-inputs">
        <label class="font font-color-dark">Legal Entity ID</label>
        <p-dropdown [options]="leIdOptions" appendTo="body" [showClear]="true"
            [(ngModel)]="selectedFilter.legalEntityId"  resetFilterOnHide="true" placeholder="Select" optionLabel="name"
            optionValue="name" (onChange)="onChangeFilterFieldValues()">
        </p-dropdown>
    </div>
 </div> 
      <!----------------Auto coding Item Derived Dimensions ----------------->
      <div class="cozmos-visible-filters" id="derived-financial-dimensions"
      *ngIf="router.url.includes('/user/autocoding/derived-financial-dimensions')">
      <div class="p-field filter-inputs">
          <label class="font font-color-dark">Status</label>
          <p-dropdown [options]="userStatusOptions" optionLabel="name" optionValue="code"
              [(ngModel)]="selectedFilter.status" placeholder="Select Status" [showClear]="true"
              (onChange)="onChangeFilterFieldValues()" appendTo="body" showClear="true"
              resetFilterOnHide="true">
          </p-dropdown>
      </div>
      <div class="p-field filter-inputs">
        <label class="font font-color-dark">Financial Department</label>
        <p-multiSelect  placeholder="Select Financial Department"  [(ngModel)]="selectedFilter.financialDepartment" (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.financialDepartment" [filter]="true" display="chip" [showClear]="true" 
        optionLabel="value" optionValue="value" appendTo="body">
    </p-multiSelect>
      </div>

      <div class="p-field filter-inputs">
        <label class="font font-color-dark">Cost Center</label>
        <p-multiSelect placeholder="Select Cost Center" [(ngModel)]="selectedFilter.costCenter" 
                       (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.costCenter" 
                       [filter]="true" display="chip" [showClear]="true" optionLabel="value" 
                       optionValue="value" appendTo="body">
        </p-multiSelect>
    </div>
    
    <div class="p-field filter-inputs">
        <label class="font font-color-dark">Location</label>
        <p-multiSelect placeholder="Select Location" [(ngModel)]="selectedFilter.location" 
                       (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.location" 
                       [filter]="true" display="chip" [showClear]="true" optionLabel="value" 
                       optionValue="value" appendTo="body">
        </p-multiSelect>
    </div>
    
    <div class="p-field filter-inputs">
        <label class="font font-color-dark">Activity</label>
        <p-multiSelect placeholder="Select Activity" [(ngModel)]="selectedFilter.activity" 
                       (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.activity" 
                       [filter]="true" display="chip" [showClear]="true" optionLabel="value" 
                       optionValue="value" appendTo="body">
        </p-multiSelect>
    </div>
    
    <div class="p-field filter-inputs">
        <label class="font font-color-dark">Market Products</label>
        <p-multiSelect placeholder="Select Market Products" [(ngModel)]="selectedFilter.marketProducts" 
                       (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.marketProducts" 
                       [filter]="true" display="chip" [showClear]="true" optionLabel="value" 
                       optionValue="value" appendTo="body">
        </p-multiSelect>
    </div>
    
    <div class="p-field filter-inputs">
        <label class="font font-color-dark">Equipment</label>
        <p-multiSelect placeholder="Select Equipment" [(ngModel)]="selectedFilter.equipment" 
                       (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.equipment" 
                       [filter]="true" display="chip" [showClear]="true" optionLabel="value" 
                       optionValue="value" appendTo="body">
        </p-multiSelect>
    </div>
    
    <div class="p-field filter-inputs">
        <label class="font font-color-dark">Business Unit</label>
        <p-multiSelect placeholder="Select Business Unit" [(ngModel)]="selectedFilter.businessUnit" 
                       (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.businessUnit" 
                       [filter]="true" display="chip" [showClear]="true" optionLabel="value" 
                       optionValue="value" appendTo="body">
        </p-multiSelect>
    </div>
    
    <div class="p-field filter-inputs">
        <label class="font font-color-dark">Segment</label>
        <p-multiSelect placeholder="Select Segment" [(ngModel)]="selectedFilter.segment" 
                       (onChange)="onChangeFilterFieldValues()" [options]="dropwDownOptions.segment" 
                       [filter]="true" display="chip" [showClear]="true" optionLabel="value" 
                       optionValue="value" appendTo="body">
        </p-multiSelect>
    </div>
  </div>                      
        </div>
    </div>
</p-sidebar>