import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FilterService, INavigationData, MasterdataService, NavigationService } from '../../service';
import { SubSink } from 'subsink';
import { Router } from "@angular/router";
import * as moment from "moment";
import { SelectedFilters } from "src/app/models/selected-filters";
import { CommonService } from "../../service/common/common.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { Settings } from "../../lib/app.constant";
import { Calendar } from "primeng/calendar";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CurrencyManagementService } from "../../service/currency-management/currency-management.service";
import { settings } from "cluster";
import { CurrencyAliaManagementService } from "../../service/currencyalias-management/currencyalias-management.service";
import { VendorManagementService } from "../../service/vendor-management/vendor-management.service";
import { LegalEntityManagementService } from "../../service/legal-entity-management/legal-entity-management.service";
import langaugeData from '../../../../../assets/layout/data/language.json';
import { POManagementService } from "../../service/po-management/po-management.service";
import { RoleManagementService } from "../../service/role-management/role-management.service";
import { EdiService } from "../../service/edi-management/edi.service";
import { DocumentService } from "../../service/document/document.service";
@Component({
    selector: "app-filter-panel",
    templateUrl: "./filter-panel.component.html",
    styleUrls: ["./filter-panel.component.scss"],
})
export class FilterPanelComponent implements OnInit, OnDestroy {
    saveTemplateForm = new FormGroup({
        isTemplateShared: new FormControl(false, [Validators.required]),
        uniqueTemplateName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
    })
    dropwDownOptions: any={
        mainAccount:[],
        itemSalesTaxGroup:[],
        financialDepartment: [],
        costCenter: [],
        location: [],
        activity: [],
        marketProducts: [],
        equipment: [],
        businessUnit: [],
        segment: [],
        worker:[],
        singleApproverFirstName:[],
        singleApproverId:[],
        singleApproverLastName:[],
        workflow:[],
        groupApproverId:[],
        groupApproverName:[],
        salesTaxGroup:[]
      };
    processingFlagList: {}[] = [{ name: "OFF", value: 0 },{ name: "PARTIAL", value: 2 }, { name: "ON", value: 1 }]
    languageList: {}[] = langaugeData
    ProfileTypeOptions = [{ "id": 1, Name: "AZURE" }, { "id": 2, Name: "CUSTOM" }]
    DocumentTypeList = []
    aliasStatus: any;
    public eTrackerStatusList: {}[] = Settings.Status.eTrackerStatusList;
    public currencyStatusOptions = [{ statusId: 1, statusName: "Active" }, { statusId: 0, statusName: "Inactive" }]
    @ViewChild('myDateRangeCalendar') calendar: Calendar;
    @ViewChild('myDateRangeCalendarDoc1') calendarDoc1: Calendar;
    @ViewChild('myDateRangeCalendarDoc2') calendarDoc2: Calendar;
    @ViewChild('myDateRangeCalendarDoc3') calendarDoc3: Calendar;
    @ViewChild('myDateRangeCalendarDoc4') calendarDoc4: Calendar;
    @ViewChild('myDateRangeCalendarDataLogger') calendarDoc5: Calendar;
    userDetailsSubscription: Subscription;
    public showPanel: boolean = false;
    private filterSubscription = new SubSink();
    private clearfilterEvent: Subscription;
    public templateList: {}[] = [];
    filterSelection: {}[] = [];
    assignableUserList: {}[];
    public selectedFilter: SelectedFilters;
    templateId: number = 0;
    disableSave: boolean = true;
    currentRouteUrl: any;
    preferenceListFilter: {}[];
    preferenceListColumnSettings: {}[];
    templateFilterData: any;
    preferenceId: Number;
    isFilter: boolean = false;
    showUpdate: boolean = false;
    userStatusOptions = [{ name: 'Active', code: 1 }, { name: 'Inactive', code: 0 }]
    showTemplateSection: boolean = true;
    booleanList = [{ name: 'No', flag: 'OFF', value: false }, { name: 'Yes', flag: 'ON', value: true }]
    leVendorOptions = [{ name: 'No', code: false }, { name: 'Yes', code: true }]
    ErrorTypes = [{ name: 'INFO' }, { name: 'WARNING' }, { name: 'ERROR' }];
    docTrackerStatusList: {}[];
    documentTypeList: {}[];
    messageTypeList: {}[];
    leListName: {}[];
    leListId: {}[];
    vendorList: {}[];
    vendorListName: any[];
    vendorListId: {}[];
    currencyList: {}[];
    destinationList: {}[];
    actionTakenByList: {}[];
    templateValue: any;
    typeOptions = [{ name: 'Legal Entity' }, { name: 'Vendor' }];
    replaceValueOptions = [];
    maxDate = new Date();
    leIdOptions = []

    public router: Router;
    private filterServices: FilterService;
    private common: CommonService;
    private messageService: MessageService;
    private currencyDataService: CurrencyManagementService;
    private vendorService: VendorManagementService
    private legalEntityService: LegalEntityManagementService
    private poService: POManagementService

    privilegeList: any;
    documentProvider: { [x: number]: any; }[];
    sourceSystem: {[x: number]: any; }[];
    testIndicatorList: { [x: number]: any; }[];
    fileLockedList: {}[] = [{ label: "NO", value: false }, { label: "YES", value: true }]
    leStatusOptions = [{ name: 'Active', code: true }, { name: 'Inactive', code: false }];
    actionTypeOptions: {}[] = [];
    vendorStatusOptions = [{ name: 'Active', code: false }, { name: 'Inactive', code: true }];
    moduleList: {}[];
    poStatusOptions = [{ name: 'Active', code: 0 }, { name: 'Inactive', code: 1 }]
    sourceSystemOptions = []
    vendorAccountOptions = []
    currencyListOptions = []
    errorCodeList: any;
    assignedToUserList: any;
    zipOptions = []
    cityOptions = []
    documentFieldList: any[] = [];
    enterKeyPressed: boolean;
    legalIdOptions = []
    vendorGroupOptions = []
    leListIdProfile: any;
    roleNameOptions: any;
    roleService: RoleManagementService;
    messageDirectionList: { id: string; name: string; }[];
    fileFormatList: { [x: number]: any; }[];
    private ediService: EdiService;
    codeListDropdownList: any;
    connectivityTypeList: { [x: number]: any; }[];
    fileSourceList: any[];
    senderIdList: any[];
    receiverIdList: any[];
    receiverNameList: any;
    senderNameList: any;
    messageTypeEDIList: any;
    profileNameList: any;
    fileTypeList: any[];
    templateTypeList = [{ name: 'Public', key: true}, { name: 'Private', key: false}];
    codelistTableList: any;
    messageVersionList: any;
    isDeleting: boolean;
    private confirmationService: ConfirmationService;
    private vendorManagementService: VendorManagementService;
    private documentService: DocumentService;
    vendorNameFilter: any;
    errorCategoryList: any[];
    emailTypeOptions: {}[];
    vendorAccountList: any[]=[];
    vendorListData: any = [];
    vendorAccountOptionsList = [];
    emailFormatOptions: { [x: number]: any; }[];
    eInvoiceCountryNameList: any;
    eInvoiceProfileNameList: any;
    processingEngineList: any[];
    financialDimensionIdList: any;
    financialDimensionNameList: any;
    constructor(_documentService: DocumentService, _confirmationService: ConfirmationService, _vendorManagementService: VendorManagementService, _ediService: EdiService, _router: Router, _filterServices: FilterService, _common: CommonService, _messageService: MessageService, _currencyDataService: CurrencyManagementService, _vendorService: VendorManagementService, private currencyaliasService: CurrencyAliaManagementService, _legalEntityService: LegalEntityManagementService, _poService: POManagementService, _roleService: RoleManagementService) {
        this.selectedFilter = new SelectedFilters();
        this.filterServices = _filterServices;
        this.common = _common;
        this.messageService = _messageService;
        this.router = _router;
        this.currencyDataService = _currencyDataService;
        this.vendorService = _vendorService;
        this.legalEntityService = _legalEntityService;
        this.poService = _poService;
        this.roleService = _roleService;
        this.ediService = _ediService;
        this.documentService = _documentService;
        this.confirmationService = _confirmationService;
        this.vendorManagementService = _vendorManagementService;
    }
    ngOnInit() {
        this.maxDate.setDate(this.maxDate.getDate());
        this.saveTemplateForm.valueChanges.subscribe(event => {
            this.saveTemplateForm.controls['isTemplateShared'].setValue(event.isTemplateShared);
        });
        //to clear the filter on route change
        this.clearfilterEvent = this.filterServices.getClearFilterEvent().subscribe((res) => {
            if (res) {
                this.getDropDownValue();
                this.getMasterPreferencesList();
            }
            else {
                this.onClickClearFilter();
            }
            this.currentRouteUrl = this.router.url;
            if (this.router.url.includes("user/document") || this.router.url.includes("user/dashboard") || this.router.url.includes('user/email-tracker')) {
                this.showTemplateSection = true;
            } else {
                this.showTemplateSection = false;
            }

            Object.keys(this.selectedFilter).map(key => {
                if (this.selectedFilter[key] instanceof Array) this.selectedFilter[key] = undefined
                else this.selectedFilter[key] = undefined
            })
            if (this.templateValue) {
                this.resetSaveTemplateForm();
                this.templateValue = null;
            }
            this.disableSave = true;
            this.checkRequiredFilterFields();
        })
    }

    //get masterdata according to url
    getDropDownValue() {
        if (this.router.url.includes("user/autocoding/financial-dimensions/activity")) {
          this.getFinancialDimensionList('activity');
        }
        if (this.router.url.includes("user/autocoding/financial-dimensions/bank-loan")) {
            this.getFinancialDimensionList('bank-loans');
          }
          if (this.router.url.includes("user/autocoding/financial-dimensions/business-unit")) {
            this.getFinancialDimensionList('business-unit');
          }  
          if (this.router.url.includes("user/autocoding/financial-dimensions/cost-center")) {
            this.getFinancialDimensionList('cost-center');
          }   
          if (this.router.url.includes("user/autocoding/financial-dimensions/equipment")) {
            this.getFinancialDimensionList('equipment');
          } 
          if (this.router.url.includes("user/autocoding/financial-dimensions/financial-department")) {
            this.getFinancialDimensionList('financial-department');
          }   
          if (this.router.url.includes("user/autocoding/financial-dimensions/intercompany")) {
            this.getFinancialDimensionList('intercompany');
          }  
          if (this.router.url.includes("user/autocoding/financial-dimensions/location")) {
            this.getFinancialDimensionList('location');
          }  
          if (this.router.url.includes("user/autocoding/financial-dimensions/segment")) {
            this.getFinancialDimensionList('segment');
          } 
          if (this.router.url.includes("user/autocoding/financial-dimensions/worker")) {
            this.getFinancialDimensionList('worker');
          }      
          if (this.router.url.includes("user/autocoding/financial-dimensions/market-product")) {
            this.getFinancialDimensionList('market-products');
          }      
          if (this.router.url.includes("user/autocoding/financial-dimensions/belgium-local")) {
            this.getFinancialDimensionList('belgium-local-coa');
          } 
          if (this.router.url.includes("user/autocoding/financial-dimensions/france-local")) {
            this.getFinancialDimensionList('france-local-coa');
          }   
          if (this.router.url.includes("user/autocoding/financial-dimensions/spain-local")) {
            this.getFinancialDimensionList('spain-local-coa');
          }   
          if (this.router.url.includes("user/autocoding/financial-dimensions/italy-local")) {
            this.getFinancialDimensionList('italy-local-coa');
          }   
          if (this.router.url.includes("user/autocoding/financial-dimensions/turkey-local")) {
            this.getFinancialDimensionList('turkey-local-coa');
          }  
          if (this.router.url.includes("user/autocoding/number-sequence-groups")) {
            this.getFinancialDimensionList('number-sequence-groups');
          }  
          if (this.router.url.includes("user/autocoding/sales-tax-group")) {
            this.getLegalEntity('le', 'legal_entity_id')
            this.getFinancialDimensionList('sales-tax-groups');
          }  
          if (this.router.url.includes("user/autocoding/item-sales-tax-group")) {
            this.getLegalEntity('le', 'legal_entity_id')
            this.getFinancialDimensionList('item-sales-tax-groups');
          }  
          if (this.router.url.includes("user/autocoding/main-accounts")) {
            this.getFinancialDimensionList('main-account');
          }  
          if (this.router.url.includes("user/autocoding/group-accounts")) {
            this.getFinancialDimensionDropdownList('equipment');
            this.getFinancialDimensionDropdownList('location');
          }  
          if (this.router.url.includes("user/autocoding/procurement-categories")) {
            this.getFinancialDimensionList('procurement');
            this.getLegalEntity('le', 'legal_entity_id')
            this.getFinancialDimensionDropdownList('main-account');
            this.getFinancialDimensionDropdownList('item-sales-tax-groups');
          }  
          if (this.router.url.includes("user/autocoding/single-approvers")) {
            this.getFinancialDimensionDropdownList('single-approver');
            // this.getFinancialDimensionDropdownList('worker');
          }  
          if (this.router.url.includes("user/autocoding/group-approvers")) {
            this.getFinancialDimensionDropdownList('group-approver');
            // this.getFinancialDimensionDropdownList('worker');
          }  
          if (this.router.url.includes("user/autocoding/derived-financial-dimensions"))  {
            this.getFinancialDimensionDropdownList('financial-department');
            this.getFinancialDimensionDropdownList('cost-center');
            this.getFinancialDimensionDropdownList('location');
            this.getFinancialDimensionDropdownList('activity');
            this.getFinancialDimensionDropdownList('market-products');
            this.getFinancialDimensionDropdownList('equipment');
            this.getFinancialDimensionDropdownList('business-unit');
            this.getFinancialDimensionDropdownList('segment');
          }
        if (this.router.url.includes("user/administration/activity-log")) {
            this.getModuleList();
            this.getActionTypeList();
        }
        if (this.router.url.includes("user/master-data/reference-lookup")) {
            this.leIdOptions=[];
            this.currencyList=[]
            this.common.getLeVendorCustomData('le', 'legal_entity_id', null).subscribe({
                next: (res: any) => {
                  this.leIdOptions = res?.legalEntityId;
                }
              })
            this.currencyDataService.getAllCurrencyList({ "isActive": true, "uniqueCode": true }).subscribe({
                next: (res: any) => {
                    this.currencyList = res?.results;
                },
                error: (error: any) => {
                }
            })
        }
        if (this.router.url.includes("user/administration/dms-exception-logs")) {
            var body = { key1: "id", key2: "name" }
            this.moduleList = Settings.CommonFunctions.enumToArray(Settings.DMSModule, body);
            this.actionTypeOptions = Settings.CommonFunctions.enumCharToArray(Settings.DMSAction);
        }
        if (this.router.url.includes("user/administration/errocodelist")) {
            this.getErrorCategoryList();
        }
        if (this.router.url.includes('user/dashboard')) {
            this.vendorListName = [];
            this.getDocumentTypeList();
            this.getUserLegalEntityList();
            this.getDocumentProvider();
            this.getFileTypeList();
            this.getDestinationType();
        }
        if (this.router.url.includes('user/edi/trading-partner-setup')) {
            this.getFileFormat();
            this.getMessageDirection();
            this.getTradingPartnerDropdownValue();
        }
        if (this.router.url.includes('user/xml')) {
            this.getEInvoiceList();
        }
        if (this.router.url.includes('user/edi/code-list/library')) {
            this.getTradingPartnerDropdownValue();
        }
        if (this.router.url == '/user/edi/code-list') {
            this.getCodeList();
        }
        if (this.router.url.includes('user/connectivity')) {
            this.getConnectivityUniqueList();
            this.getMessageDirection();
            this.getConnectivityType();
        }
        if (this.router.url.includes("user/administration/user-management")) {
            this.getRoleList();
            this.getLegalEntityList();
        }
        if (this.router.url.includes('user/administration/email-template')) {
            this.getEmailTypeOptions();
        }
        if (this.router.url.includes('user/administration/blacklist-email')) {
            this.getEmailTypeOptions();
            this.getEmailFormatOptions();
        }
        if (this.router.url.includes('user/administration/emailtemplate-controller')) {
            this.getLegalEntity('le', 'legal_entity_id');
            this.currencyaliasService.getSortErrorCodeList().subscribe({
                next: (res: any) => {
                    this.errorCodeList = res?.results?.filter((item: any) => item.errorCode.startsWith('E'));
                },
                error: () => {
    
                }
            })
        }
        if (this.router.url.includes('user/administration/data-change-logger')) {
            this.moduleList = [{ name: 'DOCUMENT_TRACKER', id: 10 }, { name: 'DOCUMENT_TRACKER_LINE', id: 230 }];
        }
        if (this.router.url.includes("user/master-data/ap-invoice-export")) {
            this.getLegalEntityList();
            // this.getVendorList();
            this.getCurrencyList();
            this.getsourceSystem();
        }
        if (this.router?.url.includes("user/administration/customprofile-list")) {
            this.common.getCustomLegalEntityList().subscribe({
                next: (res) => {
                    this.leListIdProfile = res?.results.filter(item => item !== '' && item !== null && item !== undefined).map(item => ({ id: item, value: item }));
                },
                error: () => {

                }
            })
        }
        if (this.router.url.includes("user/email-tracker")) {
            this.getTestIndicator();
        }
        if (this.router.url.includes("user/document")) {
            this.getDocumentTypeList();
            this.getMessageTypeList();
            this.getDocTrackerStatusList();
            this.getLegalEntityList();
            this.getProcessingEngineList();
            this.getCurrencyList();
            this.getErrorCodeList();
            this.getDestinationType();
            this.getDocumentProvider();
            this.getTestIndicator();
            this.getFileSourceList();
            this.getFileTypeList();
        }
        if(this.router.url.includes('user/administration/cron-job-logger')){
            var statusBody = { key1: "id", key2: "statusName" }
            const includedStatuses = [10, 15, 40, 20, 35];
            this.docTrackerStatusList = Settings.CommonFunctions.enumToArray(Settings.DocStatus, statusBody)
    .filter((status: any) => includedStatuses.includes(status.id))
    .map((status: any) => {
        if (status.id === 40) {
            return {
                ...status,
                statusName: 'ERROR'
            };
        }
        return status;
    });
        }
        if (this.router.url.includes("user/administration/languageintent-alias")) {
            this.getDocumentTypeList();
        }
        this.currencyListOptions = []
        this.zipOptions = []
        this.cityOptions = []
        this.legalIdOptions = []
        this.vendorAccountOptions = []
        this.vendorGroupOptions = []
        this.vendorAccountOptionsList = []
        if (this.router.url.includes("/user/master-data/currencyalias-management") || this.router.url.includes("/user/master-data/currency-management")) {
            this.currencyDataService.getAllCurrencyList({ "uniqueCode": true }).subscribe({
                next: (res: any) => {
                    res?.results?.map((data) => {
                        this.currencyListOptions.push({ "name": data?.code })
                    })
                }
            })
        }
        if (this.router.url.includes("/user/administration/poprefix-management") || this.router.url.includes("/user/dashboard")|| this.router.url.includes("/user/master-data/po-management")) {
            var body = { key1: "id", key2: "name" }
            this.sourceSystemOptions = Settings.CommonFunctions.enumToArray(Settings.SourceSystem, body);
        }
        if (this.router.url.includes("/user/master-data/po-management")) {
            this.poService.getPoFilterData().subscribe({
                next: (res: any) => {
                    // -------legal entity id-----------
                    res?.legalEntityId?.map((data) => {
                        this.legalIdOptions.push({ "name": data })
                    })
                    const unique = [...new Map(this.legalIdOptions.map(item => [item['name'], item])).values()]
                    this.legalIdOptions = unique.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
                    this.legalIdOptions = this.legalIdOptions.sort((a, b) => a.name - b.name)
                    res?.currency?.map((data) => {
                        this.currencyListOptions.push({ "name": data })
                    })
                    const unique2 = [...new Map(this.currencyListOptions.map(item => [item['name'], item])).values()]
                    this.currencyListOptions = unique2.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
                    this.sortByKey(this.currencyListOptions, 'name', 'asc');
                }
            })
        }
        if (this.router.url.includes("/user/master-data/le-management")) {
            this.getsourceSystem();
            this.common.getLeVendorCustomData('le', 'city,zip,legal_entity_id', null).subscribe({
                next: (res: any) => {
                    this.cityOptions = res?.city;
                    this.zipOptions = res?.zip
                    this.legalIdOptions = res?.legalEntityId
                }
            })
        }
        if (this.router.url.includes("/user/master-data/vendor-management")) {
            this.getDestinationType();
            this.getMessageTypeList();
            this.getsourceSystem();
            this.getFileTypeList();
            this.vendorService.getVendorFilterData().subscribe({
                next: (res: any) => {
                    // -------legal entity id-----------
                    res?.legalEntityId?.map((data) => {
                        this.legalIdOptions.push({ "name": data })
                    })
                    const unique = [...new Map(this.legalIdOptions.map(item => [item['name'], item])).values()]
                    this.legalIdOptions = unique.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
                    this.legalIdOptions = this.legalIdOptions.sort((a, b) => a.name - b.name)
                    // ---------zip code----------
                    res?.zipCode?.map((data) => {
                        this.zipOptions.push({ "name": data })
                    })
                    const unique2 = [...new Map(this.zipOptions.map(item => [item['name'], item])).values()]
                    this.zipOptions = unique2.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
                    this.sortByKey(this.zipOptions, 'name', 'asc');
                    // ---------City----------
                    res?.city?.map((data) => {
                        this.cityOptions.push({ "name": data })
                    })
                    const unique3 = [...new Map(this.cityOptions.map(item => [item['name'], item])).values()]
                    this.cityOptions = unique3.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
                    const uniqueValues = new Set();
                    this.cityOptions = this.cityOptions.filter((item) => {
                        // Check if the item's name is not empty, null, or undefined
                        if (item.name && item.name.trim() !== '') {
                            const currentCity = item.name;
                            // Use a case-insensitive check to determine uniqueness
                            const isUnique = !Array.from(uniqueValues).some((existingValue: any) =>
                                existingValue.toLowerCase() === currentCity.toLowerCase()
                            );
                            if (isUnique) {
                                uniqueValues.add(currentCity);
                                return true;
                            }
                        }
                        return false;
                    });
                    this.sortByKey(this.cityOptions, 'name', 'asc');
                    // ---------currency code----------
                    res?.currency?.map((data) => {
                        this.currencyListOptions.push({ "name": data })
                    })
                    const unique4 = [...new Map(this.currencyListOptions.map(item => [item['name'], item])).values()]
                    this.currencyListOptions = unique4.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
                    this.sortByKey(this.currencyListOptions, 'name', 'asc');
                    // ---------vendor group----------
                    res?.vendorGroup?.map((data) => {
                        this.vendorGroupOptions.push({ "name": data })
                    })
                    const unique5 = [...new Map(this.vendorGroupOptions.map(item => [item['name'], item])).values()]
                    this.vendorGroupOptions = unique5.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
                    this.sortByKey(this.vendorGroupOptions, 'name', 'asc');
                }
            })
            this.getFinancialDimensionDropdownList('sales-tax-groups');
        }
    }

    getFinancialDimensionList(module){
        this.common.getFinancialDimensionList(module).subscribe({
            next:(res)=>{
            let data=res?.results;
            let idData = data.filter(item => item?.reference != null && item?.reference !== undefined).sort((a, b) => a.reference.localeCompare(b.reference));
            let nameData= data.filter(item => item?.name != null && item?.name !== undefined).sort((a, b) => a.name.localeCompare(b.name));
            this.financialDimensionIdList=idData;
            this.financialDimensionNameList=nameData;
            },
            error:()=>{
                this.financialDimensionIdList=[];
                this.financialDimensionNameList=[];
            }
        })
    }
    getFinancialDimensionDropdownList(module: string) {
        this.common.getFinancialDimensionList(module).subscribe({
            next: (res) => {
                this.dropwDownOptions = res?.results;
                switch (module) {
                        case 'single-approver':
                            this.dropwDownOptions['worker']= res?.results?.filter(item => item?.worker != null && item?.worker !== undefined)?.sort((a, b) => a.worker.localeCompare(b.worker))?.map(item => ({
                                value: item.worker 
                              }));
                            this.dropwDownOptions['singleApproverFirstName'] =  res?.results?.filter(item => item?.firstName != null && item?.firstName !== undefined)?.sort((a, b) => a.firstName.localeCompare(b.firstName))?.map(item => ({
                                value: item.firstName 
                              }));
                            this.dropwDownOptions['singleApproverLastName'] =  res?.results?.filter(item => item?.lastName != null && item?.lastName !== undefined)?.sort((a, b) => a.lastName.localeCompare(b.lastName))?.map(item => ({
                                value: item.lastName 
                              }));
                            this.dropwDownOptions['singleApproverId'] =  res?.results?.filter(item => item?.reference != null && item?.reference !== undefined)?.sort((a, b) => a.reference.toString().localeCompare(b.reference.toString()))?.map(item => ({
                                label: item.reference.toString(),
                            value: item.reference 
                            }));
                            break;   
                        case 'group-approver':
                            this.dropwDownOptions['workflow']= res?.results?.filter(item => item?.workflow != null && item?.workflow !== undefined)?.sort((a, b) => a.workflow.localeCompare(b.workflow))?.map(item => ({
                                value: item.workflow 
                              }));
                            this.dropwDownOptions['location']= res?.results?.filter(item => item?.location != null && item?.location !== undefined)?.sort((a, b) => a.location.localeCompare(b.location))?.map(item => ({
                                value: item.location 
                              }));
                            this.dropwDownOptions['equipment']= res?.results?.filter(item => item?.equipment != null && item?.equipment !== undefined)?.sort((a, b) => a.equipment.localeCompare(b.equipment))?.map(item => ({
                                value: item.equipment 
                              }));
                            this.dropwDownOptions['groupApproverId'] =  res?.results?.filter(item => item?.reference != null && item?.reference !== undefined)?.sort((a, b) => a.reference.localeCompare(b.reference))?.map(item => ({
                                value: item.reference 
                              }));
                            this.dropwDownOptions['groupApproverName'] =  res?.results?.filter(item => item?.name != null && item?.name !== undefined)?.sort((a, b) => a.name.localeCompare(b.name))?.map(item => ({
                                value: item.name 
                              }));
                            break;   
                    default:
                        break;
                }

                const options = res?.results?.filter(item => item?.reference != null && item?.reference !== undefined)?.sort((a, b) => a.reference.localeCompare(b.reference))?.map(item => ({
                  value: item.reference 
                }));

                switch (module) {
                    case 'main-account':
                        this.dropwDownOptions['mainAccount'] = options;
                        break;
                    case 'sales-tax-groups':
                            this.dropwDownOptions['salesTaxGroup'] = options;
                            break;
                    case 'item-sales-tax-groups':
                        this.dropwDownOptions['itemSalesTaxGroup'] = options;
                        break;
                        case 'financial-department':
                            this.dropwDownOptions['financialDepartment'] = options;
                            break;
                        case 'cost-center':
                            this.dropwDownOptions['costCenter'] = options;
                            break;
                        case 'location':
                            this.dropwDownOptions['location'] = options;
                            break;
                        case 'activity':
                            this.dropwDownOptions['activity'] = options;
                            break;
                        case 'market-products':
                            this.dropwDownOptions['marketProducts'] = options;
                            break;
                        case 'equipment':
                            this.dropwDownOptions['equipment'] = options;
                            break;
                        case 'business-unit':
                            this.dropwDownOptions['businessUnit'] = options;
                            break;
                        case 'segment':
                            this.dropwDownOptions['segment'] = options;
                            break; 
                        case 'worker':
                            this.dropwDownOptions['worker'] = options;
                            break;       
                    default:
                        break;
                }
            },
            error: () => {
                this.dropwDownOptions[module] = [];
            }
        });
    }

    // ------------------get vendor acc. no. - options list------------------
    filterVendorAccountOptions(e) {
        if (this.router.url.includes("/user/master-data/vendor-management") || this.router.url.includes("/user/master-data/reference-lookup") || this.router.url.includes("master-data/ap-invoice-export") || this.router.url.includes("/user/master-data/po-management")) {
            if (e?.filter.length > 4) {
                this.getVendorAccList(e?.filter)
            } else {
                this.vendorAccountOptionsList = []
            }
        }
    }
    getVendorAccList(e) {
        let body = {
            "vendorAccount": e,
        }
        this.vendorManagementService.getVendorSearchList(body).subscribe({
            next: (res: any) => {
                this.vendorListData = res?.results;
                const uniqueIds = new Set();
                this.vendorListData = this.vendorListData.filter(item => {
                    if (!uniqueIds.has(item?.vendorAccount)) {
                        uniqueIds.add(item?.vendorAccount);
                        return true; // Include the item in the result list
                    }
                    return false; // Exclude the item (already encountered this id before)
                });
                this.vendorAccountOptionsList = this.vendorListData.filter(item => item.vendorAccount !== '' && item.vendorAccount !== null && item.vendorAccount !== undefined).sort((a, b) => {
                    return a.vendorAccount - b.vendorAccount;
                });
            },
            error: () => {
            }
        })
    }
    getEInvoiceList(){
              this.ediService.getEInvoiceSetupSortDropdownList().subscribe({
                next: (res: any) => {
                    this.eInvoiceCountryNameList=res?.results?.filter(item => item.countryName !== '' && item.countryName !== null && item.countryName !== undefined);
                    this.sortByKey(this.eInvoiceCountryNameList, 'countryName', 'asc');
                    this.eInvoiceProfileNameList=res?.results?.filter(item => item.profileName !== '' && item.profileName !== null && item.profileName !== undefined);
                    this.sortByKey(this.eInvoiceProfileNameList, 'profileName', 'asc');
                },
                error: (error) => {
                }
              })
    }
    getEmailFormatOptions(){
        var body = { key1: "id", key2: "name" }
        this.emailFormatOptions = Settings.CommonFunctions.enumToArray(Settings.EmailTemplateFormat, body);
      }
    docTrackerFilterOptionsVendorAccount(event, vendorId) {
        if (event?.filter.length > 4) {
            var body = {
                "orderBy": 'vendor_account',
                "orderType": 'asc',
                "vendorAccount": event?.filter
            }
            this.vendorManagementService.getVendorSearchList(body).subscribe({
                next: (res: any) => {
                    const uniqueVendorAccountSet = new Set(res?.results.map(item => item?.vendorAccount));
                    const uniqueVendorAccountList = Array.from(uniqueVendorAccountSet);
                    this.selectedFilter.vendorID.forEach(vendor => {
                        if (!uniqueVendorAccountSet.has(vendor)) {
                            uniqueVendorAccountList.push(vendor);
                        }
                    });
                    this.vendorAccountList = uniqueVendorAccountList;
                    vendorId._filteredOptions = uniqueVendorAccountList;
                },
                error: () => {
                    const uniqueVendorAccountList = [];
                    this.selectedFilter.vendorID.forEach(vendor => {
                        if (!uniqueVendorAccountList.includes(vendor)) {
                            uniqueVendorAccountList.push(vendor);
                        }
                    });
                    this.vendorAccountList = uniqueVendorAccountList;
                    vendorId._filteredOptions = uniqueVendorAccountList;
                }
            })
        }
        else {
            const uniqueVendorAccountList = [];
            this.selectedFilter.vendorID.forEach(vendor => {
                if (!uniqueVendorAccountList.includes(vendor)) {
                    uniqueVendorAccountList.push(vendor);
                }
            });
            this.vendorAccountList = uniqueVendorAccountList;
            vendorId._filteredOptions = uniqueVendorAccountList;
        }
    }
    // ----------------------------------------------------------------------
    getEmailTypeOptions() {
        this.common.getEmailTypeList().subscribe({
            next: (res: any) => {
                this.emailTypeOptions = res?.results;
            },
            error: () => {

            }
        })
    }
    sortByKey(array: any[], key: string, order: 'asc' | 'desc') {
        return array.sort((a, b) => {
            const valueA = a[key];
            const valueB = b[key];

            if (typeof valueA === 'number' && typeof valueB === 'number') {
                return order === 'asc' ? valueA - valueB : valueB - valueA;
            } else if (typeof valueA === 'string' && typeof valueB === 'string') {
                const nameA = valueA.toLowerCase();
                const nameB = valueB.toLowerCase();
                if (nameA < nameB) {
                    return order === 'asc' ? -1 : 1;
                } else if (nameA > nameB) {
                    return order === 'asc' ? 1 : -1;
                }
            }

            return 0;
        });
    }
    onChangeGetSummaryKeysValue(event) {
        this.selectedFilter.documentField = null;
        if (event.value == Settings.Module.DOCUMENT_TRACKER) {
            this.documentFieldList = Settings.GridConfiguration.DocTrackerModuleKey;
        }
        else if (event.value == Settings.Module.DOCUMENT_TRACKER_LINE) {
            this.documentFieldList = Settings.GridConfiguration.DocTrackerLineModuleKey;
        }
        else {
            this.documentFieldList = []
        }
        this.checkRequiredFilterFields();
    }
    getCodeList() {
        var activityBody = {
            "orderBy": 'name',
            "orderType": 'asc',
            "page": 1,
            "pageSize": 1000,
        }
        this.ediService.getCodeList(activityBody).subscribe({
            next: (res: any) => {
                this.codelistTableList = res?.results;
                this.codelistTableList = this.codelistTableList.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
            },
            error: (error) => {
            }
        })
    }
    getConnectivityType() {
        var tempBody = { key1: "id", key2: "name" }
        this.connectivityTypeList = Settings.CommonFunctions.enumToArray(Settings.ConnectivityType, tempBody);
    }
    getDocumentTypeList() {
            this.documentTypeList = Settings.CommonFunctions.getDocumentTypeList();
    }
    getConnectivityUniqueList() {
        this.ediService.getConnectivityUniqueList().subscribe({
            next: (res: any) => {
                var connectivityList = res?.results?.profiles;
                this.profileNameList = connectivityList.filter(item => item?.profileName !== '' && item?.profileName !== null && item?.profileName !== undefined);
                this.sortByKey(this.profileNameList, 'profileName', 'asc')
            },
            error: (e) => { },
        });
    }
    getTradingPartnerDropdownValue() {
        this.ediService.getCodeListLibraryDropdownList().subscribe({
            next: (res: any) => {
                this.codeListDropdownList = [];
                this.codeListDropdownList = res?.results;
                this.senderIdList = this.codeListDropdownList.filter(item => item.senderId !== '' && item.senderId !== null && item.senderId !== undefined);
                this.sortByKey(this.senderIdList, 'senderId', 'asc');
                this.receiverIdList = this.codeListDropdownList.filter(item => item.receiverId !== '' && item.receiverId !== null && item.receiverId !== undefined);
                this.sortByKey(this.receiverIdList, 'receiverId', 'asc');
                this.receiverNameList = this.codeListDropdownList.filter(item => item.receiverName !== '' && item.receiverName !== null && item.receiverName !== undefined);
                this.sortByKey(this.receiverNameList, 'receiverName', 'asc');
                this.senderNameList = this.codeListDropdownList.filter(item => item.senderName !== '' && item.senderName !== null && item.senderName !== undefined);
                this.sortByKey(this.senderNameList, 'senderName', 'asc');
                this.messageTypeEDIList = this.codeListDropdownList.filter(item => item.messageType !== '' && item.messageType !== null && item.messageType !== undefined);
                this.sortByKey(this.messageTypeEDIList, 'messageType', 'asc');
                this.messageVersionList = this.codeListDropdownList.filter(item => item.messageVersion !== '' && item.messageVersion !== null && item.messageVersion !== undefined);
                this.sortByKey(this.messageVersionList, 'messageVersion', 'asc');
                this.profileNameList = this.codeListDropdownList.filter(item => item.profileName !== '' && item.profileName !== null && item.profileName !== undefined);
                this.sortByKey(this.profileNameList, 'profileName', 'asc');
            },
            error: (e) => { },
        });
    }
    getRoleList() {
        let body = { "includePrivilegeData": false }
        this.roleService.getAllRoleList(body).subscribe({
            next: (res: any) => {
                this.roleNameOptions = res?.results
            },
            error: (e) => { },
        });
    }
    getFileFormat() {
        var fileFormatBody = { key1: "id", key2: "fileFormatName" }
        this.fileFormatList = Settings.CommonFunctions.enumToArray(Settings.FileFormatEDI, fileFormatBody);
    }
    getMessageDirection() {
        this.messageDirectionList = Settings.CommonFunctions.enumCharToArray(Settings.MessageTypeEDI);
    }
    getModuleList() {
        var docTypeBody = { key1: "id", key2: "name" }
        this.moduleList = Settings.CommonFunctions.enumToArray(Settings.Module, docTypeBody);
    }
    getActionTypeList() {
        this.actionTypeOptions = Settings.CommonFunctions.enumCharToArray(Settings.ActionType);
    }
    getDocumentProvider() {
        var docProviderBody = { key1: "id", key2: "docProviderName" }
        this.documentProvider = Settings.CommonFunctions.enumToArray(Settings.DocumentProvider, docProviderBody);
    }
    getErrorCategoryList(){
        this.common.getErrorCategoryList().subscribe({
          next:(res:any)=>{
            this.errorCategoryList=res;
          },
          error:()=>{
            this.errorCategoryList=Settings.CommonFunctions.enumToArray(Settings.ErrorCategory,{key1:"id",key2:"errorCategory"});
          }
        })
      }
    getTestIndicator() {
        var testIndicatorBody = { key1: "id", key2: "testIndicatorName" }
        this.testIndicatorList = Settings.CommonFunctions.enumToArray(Settings.TestIndicator, testIndicatorBody);
    }
    getFileSourceList() {
        var body = { key1: "id", key2: "fileSourceName" }
        this.fileSourceList = Settings.CommonFunctions.enumToArray(Settings.FileSource, body);
    }
    getFileTypeList() {
        var body = { key1: "id", key2: "fileTypeName" }
        this.fileTypeList = Settings.CommonFunctions.enumToArray(Settings.FileType, body);
    }
    getMessageTypeList() {
        var messsageTypeBody = { key1: "id", key2: "messageTypeName" }
        this.messageTypeList = Settings.CommonFunctions.enumToArray(Settings.MessageType, messsageTypeBody);
    }
    getProcessingEngineList() {
        var body = { key1: "id", key2: "name" }
        this.processingEngineList = Settings.CommonFunctions.enumToArray(Settings.ProcessingEngine, body);
    }
    getErrorCodeList() {
        this.currencyaliasService.getSortErrorCodeList().subscribe({
            next: (res: any) => {
                this.errorCodeList = res?.results;
            },
            error: () => {

            }
        })
    }
    getUserLegalEntityList() {
        this.common.getUserLegalEntity().subscribe({
            next: (res) => {
                this.leListId = res?.results;
            },
            error: () => {

            }
        })
    }
    getLegalEntityList() {
        this.common.getLeVendorCustomData('le', 'name,legal_entity_id', null).subscribe({
            next: (res) => {
                this.leListName = res?.name;
                this.leListId = res?.legalEntityId;
                if (this.router.url.includes("user/document")) {
                    this.leListId.unshift({ value: 'EMPTY' });
                }
            },
            error: () => {

            }
        })
    }
    getVendorList() {
        this.common.getLeVendorCustomData('vendor', 'name,vendor_id', null).subscribe({
            next: (res) => {
                this.vendorListName = res?.name;
                this.vendorListId = res?.vendorId;
            },
            error: () => {

            }
        })
    }
    getDocTrackerStatusList() {
        var statusBody = { key1: "id", key2: "statusName" }
        this.docTrackerStatusList = Settings.CommonFunctions.enumToArray(Settings.DocStatus, statusBody);
    }
    getDestinationType() {
        var destBody = { key1: "id", key2: "destName" }
        this.destinationList = Settings.CommonFunctions.enumToArray(Settings.DestinationType, destBody);
    }
    getCurrencyList() {
        this.currencyDataService.getAllCurrencyList({ "uniqueCode": true }).subscribe({
            next: (res: any) => {
                this.currencyList = res?.results;
            },
            error: (error: any) => {
            }
        })
    }

    getsourceSystem(){
        var sourceSystemBody = { key1: "id", key2: "sourceSystemName" }
        this.sourceSystem = Settings.CommonFunctions.enumToArray(Settings.SourceSystem, sourceSystemBody);
    }
    //getAssignableUser
    getAssignableUserList() {
        var module;
        if (this.router.url.includes('user/email-tracker')) {
            module = "e_tracker"
        }
        if (this.router.url.includes('user/document')) {
            module = "doc_tracker"
        }
        this.common.getAssignableUserList(module).subscribe({
            next: (res: any) => {
                this.assignableUserList = res?.results;
                this.assignableUserList = this.assignableUserList.map((el: any) => {
                    el.userName = el.firstName + " " + el.lastName;
                    return el;
                })
                this.filterServices.sendAssignUserList(this.assignableUserList);
            },
            error: () => {

            }
        })
    }
    filterOptionsVendorName(event, vendorId) {
        this.vendorNameFilter = event?.filter;
        if (event.filter.length > 2) {
            var body = {
                "name": this.vendorNameFilter,
                "userLe": true
            }
            this.vendorManagementService.getVendorSearchList(body).subscribe({
                next: (res: any) => {
                    this.vendorListName = res?.results;
                    const uniqueIds = new Set();
                    this.vendorListName = this.vendorListName.filter(item => {
                        if (!uniqueIds.has(item?.vendorAccount)) {
                            uniqueIds.add(item?.vendorAccount);
                            return true; // Include the item in the result list
                        }
                        return false; // Exclude the item (already encountered this id before)
                    });
                    this.vendorListName = this.vendorListName.filter(item => item.name !== '' && item.name !== null && item.name !== undefined);
                    this.sortByKey(this.vendorListName, 'name', 'asc');
                    vendorId._filteredOptions = this.vendorListName;
                },
                error: () => {
                }
            })
        }
        else if (event.filter.length == 0) {
            vendorId._filteredOptions = [];
            this.vendorListName = [];
        }
    }
    filterOptionsVendorAccount(event, vendorId) {
        if (event?.filter.length > 2) {
            var body = {
                "orderBy": 'vendor_account',
                "orderType": 'asc',
                "vendorAccount": event?.filter
            }
            this.vendorManagementService.getVendorSearchList(body).subscribe({
                next: (res: any) => {
                    const uniqueVendorAccountSet = new Set(res?.results.map(item => item?.vendorAccount));
                    const uniqueVendorAccountList = Array.from(uniqueVendorAccountSet);
                    this.vendorAccountList = uniqueVendorAccountList;
                    vendorId._filteredOptions = uniqueVendorAccountList;
                },
                error: () => {

                }
            })
        }
        else {
            this.vendorAccountList = [];
        }
    }
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        // Check if the shortcut key combination is pressed   
        if (event?.key == 'Enter' && !event?.shiftKey) {
            if (this.showPanel) {
                if (!this.disableSave) {
                    this.onClickApplyFilter(); // Call your function
                }
            }
        }
    }
    //to submit filter according to route urls
    onClickApplyFilter() {
        this.filterSelection = [];
        this.isFilter = !this.disableSave;
        if (this.currentRouteUrl?.includes("user/email-tracker")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.emailDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                emailStatus: this.selectedFilter?.emailStatus,
                messageID: this.selectedFilter?.messageID,
                emailFrom: this.selectedFilter?.emailFrom,
                emailTo: this.selectedFilter?.emailTo,
                emailCC: this.selectedFilter?.emailCC,
                emailSubject: this.selectedFilter?.emailSubject,
                numOfAttachements: this.selectedFilter?.numOfAttachements ?? null,
                numOfPdf: this.selectedFilter?.numOfPdf ?? null,
                emailAssignTo: this.selectedFilter?.emailAssignTo,
                emailDateStart: dates?.startDate ?? null,
                emailDateEnd: dates?.endDate ?? null,
                testIndicator: this.selectedFilter?.testIndicator,
                isAttachmentDiscrepancy:this.selectedFilter?.isAttachmentDiscrepancy,
                remarks: this.selectedFilter?.remarks,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("administration/usage-count")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.emailDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                transactionId: this.selectedFilter?.transactionID,
                emailFrom: this.selectedFilter?.emailFrom,
                emailTo: this.selectedFilter?.emailTo,
                // emailDateStart: dates?.startDate ?? null,
                // emailDateEnd: dates?.endDate ?? null,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes('user/administration/blacklist-email')) {
            this.filterSelection.push(this.currentRouteUrl, {
                email: this.selectedFilter?.emailTo,
                emailTypeIds: this.selectedFilter?.emailType,
                emailFormat:this.selectedFilter?.emailFormat,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/document")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.receivedDateRange);
            var invoiceDates = this.getDatesFromDateRange(this.selectedFilter.invoiceDateRange);
            var actionTakenAtDates = this.getDatesFromDateRange(this.selectedFilter.actionTakenAtRange);
            var vatDueDates = this.getDatesFromDateRange(this.selectedFilter.vatDueDate);
            let newArray = this.selectedFilter.legalEntityID ? this.selectedFilter.legalEntityID.map(item => item === 'EMPTY' ? "NOT_IDENTIFY" : item) : [];
            this.filterSelection.push(this.currentRouteUrl, {
                "documentStatus": this.selectedFilter.docStatus,
                "transId": this.selectedFilter?.transactionID ? this.selectedFilter?.transactionID?.split(',') : null,
                "docType": this.selectedFilter.docType,
                "messageType": this.selectedFilter.messageType,
                "tiNumber":this.selectedFilter.tiNumber,
                "legalEntityID": newArray,
                "legalEntityName": this.selectedFilter.legalEntityName ?? null,
                "vendorID": this.selectedFilter.vendorID,
                "vendorName": this.selectedFilter.vendorName,
                "invoiceNo": this.selectedFilter.invoiceNo,
                "ediControlNumber": this.selectedFilter.ediControlNumber,
                "poNumber": this.selectedFilter.poNumber,
                "invoiceDateStart": invoiceDates?.startDate ?? null,
                "invoiceDateEnd": invoiceDates?.endDate ?? null,
                "currency": this.selectedFilter.currency,
                "destApp": this.selectedFilter.destApp,
                "processingEngine": this.selectedFilter.processingEngine,
                "receivedStartDate": dates.startDate ?? null,
                "receivedEndDate": dates?.endDate ?? null,
                "testIndicator": this.selectedFilter?.testIndicator,
                "remarks": this.selectedFilter?.remarks,
                "isLocked": this.selectedFilter?.fileLocked,
                "isEmbeddedAvailable":this.selectedFilter?.isEmbeddedAvailable,
                "fileSource": this.selectedFilter?.fileSource,
                "fileType": this.selectedFilter?.fileType,
                "documentProvider": this.selectedFilter?.documentProvider,
                "language": this.selectedFilter?.languageDoc,
                "invoiceAssignedTo": this.selectedFilter.invoiceAssignedTo,
                "errorCodes": this.selectedFilter?.errorCode?.join(','),
                "actionTakenBy": this.selectedFilter.actionTakenBy,
                "actionTakenAtStartDate": actionTakenAtDates.startDate ?? null,
                "actionTakenAtEndDate": actionTakenAtDates.endDate ?? null,
                "vatDueDateStartDate":vatDueDates.startDate??null,
                "vatDueDateEndDate":vatDueDates.endDate??null,
                isFilter: this.isFilter
            });

        } if (this.router.url.includes('user/connectivity')) {
            this.filterSelection.push(this.currentRouteUrl, {
                status: this.selectedFilter?.status,
                // profileName: this.selectedFilter?.profileName,
                connectivityName: this.selectedFilter?.connectivityName,
                messageDirection: this.selectedFilter?.messageDirection,
                connectivityType: this.selectedFilter?.connectivityType,
                isFilter: this.isFilter
            });
        }
        if (this.router.url.includes('user/administration/data-change-logger')) {
            var modifiedOnDates = this.getDatesFromDateRange(this.selectedFilter.modifiedOn);
            this.filterSelection.push(this.currentRouteUrl, {
                transactionID: this.selectedFilter?.transactionID,
                moduleId: this.selectedFilter?.moduleId,
                documentField: this.selectedFilter?.documentField,
                beforeInput: this.selectedFilter?.beforeInput,
                afterInput: this.selectedFilter?.afterInput,
                modifiedBy: this.selectedFilter?.modifiedBy,
                modifiedOnStartDate: modifiedOnDates?.startDate,
                modifiedOnEndDate: modifiedOnDates?.endDate,
                isFilter: this.isFilter
            });
        }
        if (this.router.url.includes('user/administration/dms-exception-logs')) {
            var modifiedOnDates = this.getDatesFromDateRange(this.selectedFilter.modifiedOn);
            this.filterSelection.push(this.currentRouteUrl, {
                transactionID: this.selectedFilter?.transactionID,
                moduleId: this.selectedFilter?.moduleId,
                actionType:this.selectedFilter?.actionType,
                userId: this.selectedFilter?.modifiedBy,
                modifiedOnStartDate: modifiedOnDates?.startDate,
                modifiedOnEndDate: modifiedOnDates?.endDate,
                isFilter: this.isFilter
            });
        }
        if (this.router.url.includes('user/autocoding')) {
            this.filterSelection.push(this.currentRouteUrl, {
                status: this.selectedFilter?.status,
                financialId: this.selectedFilter?.financialId,
                financialName: this.selectedFilter?.financialName,
                legalEntityId: this.selectedFilter?.legalEntityId,
                mainAccountId: this.selectedFilter?.mainAccountId,
                itemTaxId:this.selectedFilter?.itemTaxId,
                financialDepartment:this.selectedFilter?.financialDepartment,
                costCenter: this.selectedFilter?.costCenter,
                location: this.selectedFilter?.location,
                activity: this.selectedFilter?.activity,
                marketProducts: this.selectedFilter?.marketProducts,
                equipment: this.selectedFilter?.equipment,
                businessUnit: this.selectedFilter?.businessUnit,
                segment: this.selectedFilter?.segment,
                worker:this.selectedFilter?.worker,
                firstName:this.selectedFilter?.firstName,
                lastName:this.selectedFilter?.lastName,
                workflow:this.selectedFilter?.workflow,
                isFilter: this.isFilter
            });
        }
        if (this.router.url.includes("user/dashboard")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.invoiceDateRange);
            let pools=this.selectedFilter.pool?.length>0?this.selectedFilter.pool:[];
            this.filterSelection.push(this.currentRouteUrl, {
                "startDate": dates?.startDate ?? null,
                "endDate": dates?.endDate ?? null,
                "docTypeDashboard": this.selectedFilter?.docTypeDashboard,
                "vendorName": this.selectedFilter?.vendorName,
                "legalEntityID": this.selectedFilter.legalEntityID,
                "documentProvider": this.selectedFilter?.documentProvider,
                "pool": pools?.length>0?pools?.map(pool => pool === 'Velocity' ? 'VELOCITY' : pool):undefined,
                "fileType": this.selectedFilter?.fileType,
                "isFilter": this.isFilter
            });
        }
        if (this.currentRouteUrl.includes('user/edi/trading-partner-setup')) {
            this.filterSelection.push(this.currentRouteUrl, {
                status: this.selectedFilter?.status,
                fileFormat: this.selectedFilter?.fileFormat,
                profileName: this.selectedFilter?.profileName,
                senderId: this.selectedFilter?.senderId,
                receiverId: this.selectedFilter?.receiverId,
                senderName: this.selectedFilter?.senderName,
                receiverName: this.selectedFilter?.receiverName,
                messageTypeEDI: this.selectedFilter?.messageTypeEDI,
                messageVersion: this.selectedFilter?.messageVersion,
                messageDirection: this.selectedFilter?.messageDirection,
                allowDuplicate: this.selectedFilter?.allowDuplicate,
                acknowledgementFlag: this.selectedFilter?.acknowledgementFlag,
                reviewFlag: this.selectedFilter?.reviewFlag,
                destinationHold: this.selectedFilter?.destinationHold,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl.includes('user/xml')) {
            this.filterSelection.push(this.currentRouteUrl, {
                status: this.selectedFilter?.status,
                fileFormat: this.selectedFilter?.fileFormat,
                profileName: this.selectedFilter?.profileName,
                countryName: this.selectedFilter?.countryName,
                pdfRequiredFlag: this.selectedFilter?.pdfRequiredFlag,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl == '/user/edi/code-list') {
            this.filterSelection.push(this.currentRouteUrl, {
                tableName: this.selectedFilter?.table,
                description: this.selectedFilter?.description,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl.includes('user/edi/code-list/library')) {
            this.filterSelection.push(this.currentRouteUrl, {
                senderId: this.selectedFilter?.senderId,
                receiverId: this.selectedFilter?.receiverId,
                messageType: this.selectedFilter?.messageTypeEDI,
                lookupValue: this.selectedFilter?.lookupValue,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/administration/user-management")) {
            this.filterSelection.push(this.currentRouteUrl, {
                userStatus: this.selectedFilter?.userStatus,
                roleName: this.selectedFilter?.roleName,
                userEmail: this.selectedFilter?.userEmail,
                userFirstName: this.selectedFilter?.userFirstName,
                userLastName: this.selectedFilter?.userLastName,
                userCompany: this.selectedFilter?.userCompany,
                legalEntityID: this.selectedFilter?.legalEntityID,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/administration/email-template")||this.currentRouteUrl.includes('user/administration/manual-email-template')) {
            this.filterSelection.push(this.currentRouteUrl, {
                status: this.selectedFilter?.emailStatus,
                templateName: this.selectedFilter?.templateName,
                templateType: this.selectedFilter?.templateType,
                to: this.selectedFilter?.emailTo,
                emailType:this.selectedFilter?.emailType,
                cc: this.selectedFilter?.emailCC,
                subject: this.selectedFilter?.emailSubject,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/administration/document-type")) {
            this.filterSelection.push(this.currentRouteUrl, {
                processingFlag: this.selectedFilter?.status,
                name: this.selectedFilter?.name,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/administration/emailtemplate-controller")) {
            this.filterSelection.push(this.currentRouteUrl, {
                status: this.selectedFilter?.emailStatus,
                legalEntityId: this.selectedFilter?.legalEntityId,
                vendorAccount: this.selectedFilter?.vendorID ? this.selectedFilter?.vendorID.join(',') : null,
                errorCode: this.selectedFilter?.errorCode ,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/administration/activity-log")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.actionOnDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                userName: this.selectedFilter?.userName,
                userId: this.selectedFilter?.userId,
                reference: this.selectedFilter?.reference,
                moduleId: this.selectedFilter?.moduleId,
                actionType: this.selectedFilter?.actionType,
                actionOnStartDate: dates?.startDate,
                actionOnEndDate: dates?.endDate,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/administration/cron-job-logger")) {
            var completedOnDates = this.getDatesFromDateRange(this.selectedFilter?.completedOn);
            var startedOnDates = this.getDatesFromDateRange(this.selectedFilter?.startedOn);
            this.filterSelection.push(this.currentRouteUrl, {
                jobName: this.selectedFilter?.jobName,
                jobStatus: this.selectedFilter?.jobStatus,
                completedOnStartDate: completedOnDates?.startDate,
                completedOnEndDate: completedOnDates?.endDate,
                startedOnStartDate: startedOnDates?.startDate,
                startedOnEndDate: startedOnDates?.endDate,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/le-management")) {
            this.filterSelection.push(this.currentRouteUrl, {
                leLegalEntityId: this.selectedFilter?.leLegalEntityId,
                leEntityName: this.selectedFilter?.leEntityName,
                leCountry: this.selectedFilter?.leCountry,
                leCompanyNumber: this.selectedFilter?.leCompanyNumber,
                leEanNumber: this.selectedFilter?.leEanNumber,
                apContactEmail:this.selectedFilter?.leApContactEmail,
                leVatNumber: this.selectedFilter?.leVatNumber,
                leDestinationHold: this.selectedFilter?.leDestinationHold,
                leOverruleDocumentGroup:this.selectedFilter?.leOverruleDocumentGroup,
                leReviewFlag: this.selectedFilter?.leReviewFlag,
                leCozmosEnabled: this.selectedFilter?.leCozmosEnabled,
                leZipCode: this.selectedFilter?.leZipCode,
                leCity: this.selectedFilter?.leCity,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/vendor-management")) {
            this.filterSelection.push(this.currentRouteUrl, {
                veVendorAccount: this.selectedFilter?.veVendorAccount,
                veName: this.selectedFilter?.veName,
                veAllowFalseDuplicate: this.selectedFilter?.veAllowFalseDuplicate,
                veLegalEntityId: this.selectedFilter?.veLegalEntityId,
                veVatNumber: this.selectedFilter?.veVatNumber,
                veEanNumber: this.selectedFilter?.veEanNumber,
                vePoRequired: this.selectedFilter?.vePoRequired,
                veBlocked: this.selectedFilter?.veBlocked,
                veIban: this.selectedFilter?.veIban,
                veCountry: this.selectedFilter?.veCountry,
                veVendorGroup: this.selectedFilter?.veVendorGroup,
                veCurrency: this.selectedFilter?.veCurrency,
                remark: this.selectedFilter?.remarks,
                destinationReview:this.selectedFilter?.destinationReview,
                documentGroupReview:this.selectedFilter?.documentGroupReview,
                vendorZipCode: this.selectedFilter?.vendorZipCode,
                vendorCity: this.selectedFilter?.vendorCity,
                cozmosIgnore:this.selectedFilter?.cozmosIgnore,
                sourceSystem:this.selectedFilter?.sourceSystem,
                fileType:this.selectedFilter?.fileType,
                salesTaxGroup:this.selectedFilter?.salesTaxGroup,
                reviewAutoCodingFlag:this.selectedFilter?.reviewAutoCodingFlag,
                runAutoCodingFlag:this.selectedFilter?.runAutoCodingFlag,
                collapseLineFlag:this.selectedFilter?.collapseLineFlag,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/currency-management")) {
            this.filterSelection.push(this.currentRouteUrl, {
                countryRegion: this.selectedFilter?.countryRegion,
                code: this.selectedFilter?.code,
                currencyName: this.selectedFilter?.currencyName,
                status: this.selectedFilter?.status,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/ap-invoice-export")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.invoiceDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                invoiceNo: this.selectedFilter?.invoiceNo,
                purchaseOrder: this.selectedFilter?.purchaseOrder,
                currency: this.selectedFilter?.currency,
                legalEntityId: this.selectedFilter?.legalEntityID,
                vendorAccount: this.selectedFilter?.vendorID,
                numberSequence: this.selectedFilter?.numberSequence,
                invoiceDescription: this.selectedFilter?.invoiceDescription,
                voucher: this.selectedFilter?.voucher,
                invoiceDateEndDate: dates?.startDate,
                invoiceDateStartDate: dates?.endDate,
                sourceSystem: this.selectedFilter?.sourceSystem,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("/user/master-data/vat-alias")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.actionOnDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                vatType: this.selectedFilter?.vatType,
                vatLeId: this.selectedFilter?.vatLeId,
                vatLookupValue: this.selectedFilter?.vatLookupValue,
                vatStatus: this.selectedFilter?.vatStatus,
                vatReplaceValue: this.selectedFilter?.vatReplaceValue,
                lastUsedOnStartDate: dates?.startDate,
                lastUsedOnEndDate: dates?.endDate,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/name-alias")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.actionOnDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                nameType: this.selectedFilter?.nameType,
                nameLeId: this.selectedFilter?.nameLeId,
                nameLookupValue: this.selectedFilter?.nameLookupValue,
                nameStatus: this.selectedFilter?.nameStatus,
                nameReplaceValue: this.selectedFilter?.nameReplaceValue,
                lastUsedOnStartDate: dates?.startDate,
                lastUsedOnEndDate: dates?.endDate,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/city-alias")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.actionOnDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                cityLookupValue: this.selectedFilter?.cityLookupValue,
                cityStatus: this.selectedFilter?.cityStatus,
                cityReplaceValue: this.selectedFilter?.cityReplaceValue,
                cityLeId: this.selectedFilter?.cityLeId,
                cityType: this.selectedFilter?.cityType,
                lastUsedOnStartDate: dates?.startDate,
                lastUsedOnEndDate: dates?.endDate,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/reference-lookup")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.actionOnDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                lookupValue: this.selectedFilter?.lookupValue,
                status: this.selectedFilter?.status,
                replaceValue: this.selectedFilter?.replaceValue,
                currency: this.selectedFilter?.currency,
                legalEntityId: this.selectedFilter?.legalEntityId,
                lastUsedOnStartDate: dates?.startDate,
                lastUsedOnEndDate: dates?.endDate,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/currencyalias-management")) {
            this.filterSelection.push(this.currentRouteUrl, {
                lookupValue: this.selectedFilter?.lookupValue,
                replaceValue: this.selectedFilter?.replaceValue,
                aliasStatus: this.selectedFilter?.aliasStatus,
                isFilter: this.isFilter
            });

        }
        if (this.currentRouteUrl?.includes("user/administration/poprefix-management")) {
            this.filterSelection.push(this.currentRouteUrl, {
                poPrefix: this.selectedFilter?.poPrefix,
                sourceSystem: this.selectedFilter?.sourceSystem,
                poPrefixStatus: this.selectedFilter?.poPrefixStatus,
                isFilter: this.isFilter
            });

        }

        if (this.currentRouteUrl?.includes("user/master-data/zipcode-alias")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.actionOnDateRange);
            this.filterSelection.push(this.currentRouteUrl, {
                zipLookupValue: this.selectedFilter?.zipLookupValue,
                zipReplaceValue: this.selectedFilter?.zipReplaceValue,
                zipStatus: this.selectedFilter?.zipStatus,
                zipType: this.selectedFilter?.zipType,
                zipLeId: this.selectedFilter?.zipLeId,
                lastUsedOnStartDate: dates?.startDate,
                lastUsedOnEndDate: dates?.endDate,
                isFilter: this.isFilter
            });


        }
        if (this.currentRouteUrl?.includes("user/administration/errocodelist")) {
            this.filterSelection.push(this.currentRouteUrl, {
                ErrorType: this.selectedFilter?.ErrorType,
                ErrorCode: this.selectedFilter?.ErrorCode,
                ErrorMessage: this.selectedFilter?.ErrorMessage,
                ErrorEmails: this.selectedFilter?.ErrorEmails,
                errorCategory: this.selectedFilter?.errorCategory,
                isFilter: this.isFilter
            });
        }
        if (this.currentRouteUrl?.includes("user/master-data/po-management")) {
            this.filterSelection.push(this.currentRouteUrl, {
                "popurchaseOrder": this.selectedFilter?.popurchaseOrder,
                "poStatus": this.selectedFilter?.poStatus,
                "legalEntityId": this.selectedFilter?.legalEntityId,
                "pool": this.selectedFilter?.pool,
                "povendorAccountNumber": this.selectedFilter?.povendorAccountNumber,
                "povendorName": this.selectedFilter?.povendorName,
                "povendorCurrency": this.selectedFilter?.povendorCurrency,
                isFilter: this.isFilter
            });
        }

        if (this.currentRouteUrl?.includes("/user/administration/languageintent-alias")) {
            this.filterSelection.push(this.currentRouteUrl, {
                documentTypeId: this.selectedFilter?.documentTypeId,
                keyText: this.selectedFilter?.keyText,
                language: this.selectedFilter?.language,
                isFilter: this.isFilter
            });
        }

        if (this.currentRouteUrl?.includes("user/administration/customprofile-list")) {
            this.filterSelection.push(this.currentRouteUrl, {
                customProfileStatus: this.selectedFilter?.customProfileStatus,
                profileName: this.selectedFilter?.profileName,
                description: this.selectedFilter?.description,
                legalEntityId: this.selectedFilter?.legalEntityID,
                uniqueKeys: this.selectedFilter?.uniqueKeys,
                profileType: this.selectedFilter?.profileType,
                isFilter: this.isFilter
            });
        }
        if(this.currentRouteUrl?.includes("user/document")){
            const selectedFilter = {
                transactionID: this.selectedFilter?.transactionID,
                legalEntityName: this.selectedFilter.legalEntityName ?? null,
                vendorName: this.selectedFilter.vendorName,
                invoiceNo: this.selectedFilter.invoiceNo,
                ediControlNumber:  this.selectedFilter.ediControlNumber,
                poNumber:  this.selectedFilter.poNumber,
                remarks: this.selectedFilter?.remarks
              };
              let isMultiExclusion=Settings.CommonFunctions.hasMultipleExclusionsInFields(selectedFilter);
              if(isMultiExclusion){
                this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Please use only one exclusion at the time as result might not be as expected for multiple exclusions',life:5000 });
              }
              this.filterServices.sendFilterData(this.filterSelection);
        }else{
            this.filterServices.sendFilterData(this.filterSelection);
        }
        this.closeSidebar();

    }
    getActionTakenUserList(event: any) {
        if (event.query.length >= 3) {
            this.common.getAssignableUserList("", event.query).subscribe({
                next: (res) => {
                    this.actionTakenByList = res?.results;
                    this.actionTakenByList = this.actionTakenByList.map((el: any) => {
                        el.userName = el.firstName + " " + el.lastName;
                        return el;
                    })
                },
                error: (error) => {

                }
            })
        }
        else {
            this.actionTakenByList = [];
        }
    }
    getAssignedToUserList(event: any) {
        if (event.query.length >= 3) {
            this.common.getAssignableUserList("", event.query).subscribe({
                next: (res) => {
                    this.assignedToUserList = res?.results;
                    this.assignedToUserList = this.assignedToUserList.map((el: any) => {
                        el.userName = el.firstName + " " + el.lastName;
                        return el;
                    })
                },
                error: (error) => {

                }
            })
        }
        else {
            this.assignedToUserList = [];
        }
    }
    getAssignedToUserListByEmail(event: any) {
        if (event.query.length >= 3) {
            this.common.getAssignableUserList("", "", event.query).subscribe({
                next: (res) => {
                    this.assignedToUserList = res?.results;
                    this.assignedToUserList = this.assignedToUserList.map((el: any) => {
                        el.userName = el.firstName + " " + el.lastName;
                        return el;
                    })
                },
                error: (error) => {

                }
            })
        }
        else {
            this.assignedToUserList = [];
        }
    }
    onSelectActionTakenBy() {
        this.checkRequiredFilterFields();
    }
    onSelectFilterDateRange() {
        this.checkRequiredFilterFields();
        if (this.currentRouteUrl.includes('user/document')) {
            if (this.selectedFilter.receivedDateRange[0] && this.selectedFilter.receivedDateRange[1]) {
                this.calendarDoc2.overlayVisible = false;
            }
            if (this.selectedFilter.invoiceDateRange[0] && this.selectedFilter.invoiceDateRange[1]) {
                this.calendarDoc1.overlayVisible = false;
            }
            if (this.selectedFilter.actionTakenAtRange[0] && this.selectedFilter.actionTakenAtRange[1]) {
                this.calendarDoc3.overlayVisible = false;
            }
            if(this.selectedFilter.vatDueDate[0] && this.selectedFilter.vatDueDate[1]){
                this.calendarDoc4.overlayVisible=false;
            }

        }
        if (this.currentRouteUrl.includes('user/email-tracker')) {
            if (this.selectedFilter.emailDateRange[0] && this.selectedFilter.emailDateRange[1]) {
                this.calendar.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/data-change-logger')) {
            if (this.selectedFilter.modifiedOn[0] && this.selectedFilter.modifiedOn[1]) {
                this.calendarDoc5.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/dashboard')) {
            if (this.selectedFilter.invoiceDateRange[0] && this.selectedFilter.invoiceDateRange[1]) {
                this.calendarDoc1.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/cron-job-logger')) {
            if (this.selectedFilter.startedOn[0] && this.selectedFilter.startedOn[1]) {
                this.calendarDoc4.overlayVisible = false;
            }
            if (this.selectedFilter.completedOn[0] && this.selectedFilter.completedOn[1]) {
                this.calendarDoc5.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/activity-log')) {
            if (this.selectedFilter.actionOnDateRange[0] && this.selectedFilter.actionOnDateRange[1]) {
                this.calendarDoc4.overlayVisible = false;
            }
            if (this.currentRouteUrl.includes('user/master-data/ap-invoice-export')) {
                if (this.selectedFilter.actionOnDateRange[0] && this.selectedFilter.actionOnDateRange[1]) {
                    this.calendarDoc1.overlayVisible = false;
                }
            }
        }
        
    }
    onTodayGetTodayValue() {
        this.checkRequiredFilterFields();
        if (this.currentRouteUrl.includes('user/document')) {
            if (this.selectedFilter.invoiceDateRange[0] && !this.selectedFilter.invoiceDateRange[1]) {
                this.selectedFilter.invoiceDateRange[1] = this.selectedFilter.invoiceDateRange[0];
                this.calendarDoc1.overlayVisible = false;
            }
            else{
                this.calendarDoc1.overlayVisible = false;
            }
            if (this.selectedFilter.receivedDateRange[0] && !this.selectedFilter.receivedDateRange[1]) {
                this.selectedFilter.receivedDateRange[1] = this.selectedFilter.receivedDateRange[0];
                this.calendarDoc2.overlayVisible = false;
            }
            else{
                this.calendarDoc2.overlayVisible = false;
            }
            if (this.selectedFilter.actionTakenAtRange[0] && !this.selectedFilter.actionTakenAtRange[1]) {
                this.selectedFilter.actionTakenAtRange[1] = this.selectedFilter.actionTakenAtRange[0];
                this.calendarDoc3.overlayVisible = false;
            }
            else{
                this.calendarDoc3.overlayVisible = false;
            }
            if (this.selectedFilter.vatDueDate[0] && !this.selectedFilter.vatDueDate[1]) {
                this.selectedFilter.vatDueDate[1] = this.selectedFilter.vatDueDate[0];
                this.calendarDoc4.overlayVisible = false;
            }
            else{
                this.calendarDoc4.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/email-tracker')) {
            if (this.selectedFilter.emailDateRange[0] && !this.selectedFilter.emailDateRange[1]) {
                this.selectedFilter.emailDateRange[1] = this.selectedFilter.emailDateRange[0];
                this.calendar.overlayVisible = false;
            }else{
                this.calendar.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/data-change-logger')) {
            if (this.selectedFilter.modifiedOn[0] && !this.selectedFilter.modifiedOn[1]) {
                this.selectedFilter.modifiedOn[1] = this.selectedFilter.modifiedOn[0];
                this.calendarDoc5.overlayVisible = false;
            }else{
                this.calendarDoc5.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/cron-job-logger')) {
            if (this.selectedFilter.completedOn[0] && !this.selectedFilter.completedOn[1]) {
                this.selectedFilter.completedOn[1] = this.selectedFilter.completedOn[0];
                this.calendarDoc5.overlayVisible = false;
            }else{
                this.calendarDoc5.overlayVisible = false;
            }
            if (this.selectedFilter.startedOn[0] && !this.selectedFilter.startedOn[1]) {
                this.selectedFilter.startedOn[1] = this.selectedFilter.startedOn[0];
                this.calendarDoc4.overlayVisible = false;
            }else{
                this.calendarDoc4.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/activity-log')) {
            if (this.selectedFilter.actionOnDateRange[0] && !this.selectedFilter.actionOnDateRange[1]) {
                this.selectedFilter.actionOnDateRange[1] = this.selectedFilter.actionOnDateRange[0];
                this.calendarDoc4.overlayVisible = false;
            }else{
                this.calendarDoc4.overlayVisible = false;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/ap-invoice-export')) {
            if (this.selectedFilter.invoiceDateRange[0] && !this.selectedFilter.invoiceDateRange[1]) {
                this.selectedFilter.invoiceDateRange[1] = this.selectedFilter.invoiceDateRange[0];
                this.calendarDoc1.overlayVisible = false;
            }else{
                this.calendarDoc1.overlayVisible = false;
            }
        }
    }
    onSearchFieldKeyUp() {
        this.checkRequiredFilterFields();
    }

    onChangeFilterFieldValues() {
        this.checkRequiredFilterFields();
    }

    //check if any field of etracker filter is filled
    checkRequiredFilterFields() {
        if (this.currentRouteUrl.includes('user/email-tracker')) {
            if (this.selectedFilter.emailStatus || this.selectedFilter?.isAttachmentDiscrepancy==true || this.selectedFilter?.isAttachmentDiscrepancy==false || this.selectedFilter.messageID || this.selectedFilter?.remarks || this.selectedFilter.emailDateRange || this.selectedFilter.emailFrom
                || this.selectedFilter?.testIndicator || this.selectedFilter.emailTo || this.selectedFilter.emailCC || this.selectedFilter.emailSubject || this.selectedFilter.numOfAttachements === 0 ? "0" : this.selectedFilter.numOfAttachements || this.selectedFilter.numOfPdf === 0 ? "0" : this.selectedFilter.numOfPdf || this.selectedFilter.emailAssignTo) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/autocoding')) {
            if (this.selectedFilter.status!=null ||this.selectedFilter.mainAccountId || this.selectedFilter.itemTaxId || this.selectedFilter.financialId && this.selectedFilter.financialId.length>0 || this.selectedFilter.legalEntityId && this.selectedFilter.legalEntityId.length>0 || this.selectedFilter.financialName && this.selectedFilter.financialName.length>0 ||
                (this.selectedFilter.costCenter && this.selectedFilter.costCenter.length > 0) ||
                (this.selectedFilter.location && this.selectedFilter.location.length > 0) ||    (this.selectedFilter.financialDepartment && this.selectedFilter.financialDepartment.length > 0) ||
                (this.selectedFilter.activity && this.selectedFilter.activity.length > 0) ||
                (this.selectedFilter.marketProducts && this.selectedFilter.marketProducts.length > 0) ||
                (this.selectedFilter.equipment && this.selectedFilter.equipment.length > 0) ||
                (this.selectedFilter.businessUnit && this.selectedFilter.businessUnit.length > 0) ||
                (this.selectedFilter.segment && this.selectedFilter.segment.length > 0)||
                (this.selectedFilter.worker && this.selectedFilter.worker.length > 0)||
                (this.selectedFilter.firstName && this.selectedFilter.firstName.length > 0)||
                (this.selectedFilter.lastName && this.selectedFilter.lastName.length > 0)||
                (this.selectedFilter.workflow && this.selectedFilter.workflow.length > 0)
            ) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/dms-exception-logs')) {
            if (this.selectedFilter.modifiedBy || this.selectedFilter.transactionID
                || this.selectedFilter?.moduleId || this.selectedFilter.actionType || this.selectedFilter.modifiedOn) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/usage-count')) {
            if ( this.selectedFilter.emailTo || this.selectedFilter.emailFrom || this.selectedFilter?.transactionID ) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/blacklist-email')) {
            if (this.selectedFilter.emailTo ||  this.selectedFilter?.emailType || this.selectedFilter?.emailFormat) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/dashboard')) {
            if ( (this.selectedFilter.invoiceDateRange) && ((this.selectedFilter.legalEntityID && this.selectedFilter.legalEntityID.length > 0) || (this.selectedFilter.docTypeDashboard && this.selectedFilter.docTypeDashboard.length > 0) || (this.selectedFilter.vendorName && this.selectedFilter.vendorName.length > 0) || (this.selectedFilter.documentProvider && this.selectedFilter.documentProvider.length > 0)
                || (this.selectedFilter.documentProvider) || this.selectedFilter.pool || (this.selectedFilter.invoiceDateRange) || (this.selectedFilter.fileType))) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/data-change-logger')) {
            if ((this.selectedFilter.transactionID) || (this.selectedFilter.modifiedOn) || this.selectedFilter.moduleId || (this.selectedFilter.documentField) || (this.selectedFilter.beforeInput) || (this.selectedFilter.afterInput) || (this.selectedFilter.modifiedBy)) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/document')) {
            if ((this.selectedFilter.docStatus && this.selectedFilter.docStatus.length > 0) || this.selectedFilter?.remarks || this.selectedFilter.docType || this.selectedFilter.messageType == 1 || this.selectedFilter.messageType == 0 || (this.selectedFilter.transactionID)
                || this.selectedFilter.legalEntityName || (this.selectedFilter.legalEntityID && this.selectedFilter.legalEntityID.length > 0) || (this.selectedFilter.vendorID && this.selectedFilter.vendorID.length > 0) || this.selectedFilter.vendorName || this.selectedFilter.invoiceNo
                || this.selectedFilter.invoiceDateRange || this.selectedFilter.fileSource || this.selectedFilter.fileType || this.selectedFilter.currency || this.selectedFilter.destApp || this.selectedFilter?.fileLocked == true || this.selectedFilter?.fileLocked == false || this.selectedFilter?.isEmbeddedAvailable == true || this.selectedFilter?.isEmbeddedAvailable == false || this.selectedFilter.receivedDateRange
                || this.selectedFilter.invoiceAssignedTo || this.selectedFilter.tiNumber || this.selectedFilter?.processingEngine|| this.selectedFilter.ediControlNumber || (this.selectedFilter.errorCode && this.selectedFilter.errorCode.length > 0) || this.selectedFilter.poNumber || this.selectedFilter.testIndicator || this.selectedFilter.languageDoc || this.selectedFilter.documentProvider || this.selectedFilter.actionTakenBy || this.selectedFilter.actionTakenAtRange || this.selectedFilter.vatDueDate) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/edi/trading-partner-setup')) {
            if (this.selectedFilter.fileFormat || this.selectedFilter?.status == 0 || this.selectedFilter?.status == 1 || this.selectedFilter?.profileName || this.selectedFilter.senderId || this.selectedFilter.receiverId || this.selectedFilter.senderName || this.selectedFilter.receiverName
                || this.selectedFilter.messageTypeEDI || this.selectedFilter.profileName || this.selectedFilter.messageVersion || this.selectedFilter.messageDirection || this.selectedFilter?.acknowledgementFlag == true || this.selectedFilter?.acknowledgementFlag == false || this.selectedFilter?.allowDuplicate == true || this.selectedFilter?.allowDuplicate == false || this.selectedFilter.allowDuplicate) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/xml')) {
            if (this.selectedFilter.fileFormat || this.selectedFilter?.status == 0 || this.selectedFilter?.status == 1 || this.selectedFilter?.profileName || this.selectedFilter.countryName ||
              this.selectedFilter?.pdfRequiredFlag == true || this.selectedFilter?.pdfRequiredFlag == false) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/connectivity')) {
            if (this.selectedFilter.connectivityName || this.selectedFilter.status == 1 || this.selectedFilter.status == 0 || this.selectedFilter.connectivityType || this.selectedFilter.messageDirection) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl == '/user/edi/code-list') {
            if (this.selectedFilter.table || this.selectedFilter.description) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/edi/code-list/library')) {
            if (this.selectedFilter.senderId || this.selectedFilter.receiverId || this.selectedFilter.messageTypeEDI || this.selectedFilter.lookupValue) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/user-management')) {
            if (this.selectedFilter.userStatus == 1 || this.selectedFilter.userStatus == 0 || this.selectedFilter.userEmail || (this.selectedFilter.legalEntityID && this.selectedFilter.legalEntityID.length > 0) || this.selectedFilter.roleName || this.selectedFilter.userFirstName || this.selectedFilter.userLastName || this.selectedFilter.userCompany) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/activity-log')) {
            if (this.selectedFilter.userName || this.selectedFilter.moduleId || this.selectedFilter?.userId || this.selectedFilter?.reference || this.selectedFilter.actionType || this.selectedFilter.actionOnDateRange) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/cron-job-logger')) {
            if (this.selectedFilter.jobName || this.selectedFilter.jobStatus || this.selectedFilter.completedOn ||this.selectedFilter.startedOn) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/email-template')|| this.currentRouteUrl.includes('user/administration/manual-email-template')) {
            if (this.selectedFilter.emailStatus != null || this.selectedFilter.emailTo || this.selectedFilter?.emailType||this.selectedFilter.templateType!=null || this.selectedFilter.emailCC || this.selectedFilter.emailSubject || this.selectedFilter.templateName) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/document-type')) {
            if (this.selectedFilter.status != null || this.selectedFilter.name) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/emailtemplate-controller')) {
            if (this.selectedFilter.emailStatus != null || this.selectedFilter.legalEntityId || this.selectedFilter.errorCode|| this.selectedFilter.vendorID && this.selectedFilter.vendorID.length > 0) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/le-management')) {
            if (this.selectedFilter.leLegalEntityId || this.selectedFilter.leEntityName || this.selectedFilter?.leApContactEmail || this.selectedFilter.leCountry || this.selectedFilter.leCompanyNumber || this.selectedFilter.leEanNumber || this.selectedFilter.leVatNumber || this.selectedFilter.leOverruleDocumentGroup || this.selectedFilter.leDestinationHold ||  this.selectedFilter.leReviewFlag || this.selectedFilter.leCozmosEnabled == false || this.selectedFilter.leCozmosEnabled == true || this.selectedFilter.leCity || this.selectedFilter.leZipCode) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/ap-invoice-export')) {
            if (this.selectedFilter.legalEntityID || this.selectedFilter.vendorID || this.selectedFilter.voucher || this.selectedFilter.currency || this.selectedFilter.purchaseOrder || this.selectedFilter.invoiceDateRange || this.selectedFilter.invoiceNo || this.selectedFilter.fileSource || this.selectedFilter.sourceSystem) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/vendor-management')) {
            if (this.selectedFilter.veVendorAccount || this.selectedFilter.salesTaxGroup || this.selectedFilter.reviewAutoCodingFlag !=null || this.selectedFilter.runAutoCodingFlag !=null  || this.selectedFilter.collapseLineFlag !=null  || this.selectedFilter.veName || this.selectedFilter.cozmosIgnore!=null || this.selectedFilter.sourceSystem?.length>0 || this.selectedFilter.sourceSystem ||  this.selectedFilter.fileType || this.selectedFilter.remarks || this.selectedFilter.veEanNumber || this.selectedFilter.veAllowFalseDuplicate == true || this.selectedFilter.veAllowFalseDuplicate == false || this.selectedFilter.veLegalEntityId || this.selectedFilter.veVatNumber || this.selectedFilter.vePoRequired == false || this.selectedFilter.vePoRequired == true || this.selectedFilter.veBlocked == false || this.selectedFilter.veBlocked == true || this.selectedFilter.veCountry || this.selectedFilter.veVendorGroup || this.selectedFilter.veIban || this.selectedFilter.veCurrency || this.selectedFilter.destinationReview || this.selectedFilter.documentGroupReview=='0' || this.selectedFilter.documentGroupReview  || this.selectedFilter.vendorCity || this.selectedFilter.vendorZipCode) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/currency-management')) {
            if (this.selectedFilter.countryRegion || this.selectedFilter.currencyName || this.selectedFilter.code || this.selectedFilter.status == 1 || this.selectedFilter.status == 0) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/vat-alias')) {
            if (this.selectedFilter.vatType || this.selectedFilter.vatLookupValue || this.selectedFilter.actionOnDateRange || this.selectedFilter.vatStatus == 0 || this.selectedFilter.vatStatus == 1 || this.selectedFilter.vatReplaceValue || this.selectedFilter.vatLeId) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/name-alias')) {
            if (this.selectedFilter.nameType || this.selectedFilter.nameLookupValue || this.selectedFilter.actionOnDateRange || this.selectedFilter.nameStatus == 0 || this.selectedFilter.nameStatus == 1 || this.selectedFilter.nameReplaceValue || this.selectedFilter.nameLeId) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/city-alias')) {
            if (this.selectedFilter.cityLookupValue || this.selectedFilter.cityStatus == 0 || this.selectedFilter.actionOnDateRange || this.selectedFilter.cityStatus == 1 || this.selectedFilter.cityReplaceValue || this.selectedFilter.cityLeId || this.selectedFilter.cityType) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/reference-lookup')) {
            if (this.selectedFilter.lookupValue  || this.selectedFilter.actionOnDateRange || this.selectedFilter.status != null || this.selectedFilter.replaceValue || this.selectedFilter.legalEntityId || this.selectedFilter.currency) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/currencyalias-management')) {
            if (this.selectedFilter.lookupValue || this.selectedFilter.replaceValue || this.selectedFilter.aliasStatus == 1 || this.selectedFilter.aliasStatus == 0) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/administration/poprefix-management')) {
            if (this.selectedFilter.poPrefix || this.selectedFilter.sourceSystem || this.selectedFilter.poPrefixStatus == 1 || this.selectedFilter.poPrefixStatus == 0) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('user/master-data/zipcode-alias')) {
            if (this.selectedFilter.zipLookupValue || this.selectedFilter.zipReplaceValue || this.selectedFilter.actionOnDateRange || this.selectedFilter.zipStatus == 1 || this.selectedFilter.zipStatus == 0 || this.selectedFilter.zipType || this.selectedFilter.zipLeId) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('/user/administration/errocodelist')) {
            if (this.selectedFilter.ErrorType || this.selectedFilter.ErrorCode || this.selectedFilter.errorCategory || this.selectedFilter.ErrorMessage || this.selectedFilter.ErrorEmails) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('/user/administration/languageintent-alias')) {
            if (this.selectedFilter.documentTypeId || this.selectedFilter.keyText || this.selectedFilter.language) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('/user/master-data/po-management')) {
            if (this.selectedFilter.popurchaseOrder || this.selectedFilter.poStatus == 0 || this.selectedFilter.poStatus == 1 || this.selectedFilter.legalEntityId
                || this.selectedFilter.pool || this.selectedFilter.povendorAccountNumber || this.selectedFilter.povendorName
                || this.selectedFilter.povendorCurrency) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }
        if (this.currentRouteUrl.includes('/user/administration/customprofile-list')) {
            if (this.selectedFilter.profileName || this.selectedFilter.description || this.selectedFilter.legalEntityID || this.selectedFilter.customProfileStatus == 0 || this.selectedFilter.customProfileStatus == 1 || this.selectedFilter.uniqueKeys || this.selectedFilter.profileType == 1 || this.selectedFilter.profileType == 2
            ) {
                this.disableSave = false;
            }
            else {
                this.disableSave = true;
            }
        }


    }



    // get filter templates according to preference Id     
    getAllFilterTemplates() {
        this.common.getFilterTemplateList(this.preferenceId).subscribe({
            next: (res: any) => {
                this.templateList = res?.results;
            },
            error: (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    life: 3000,
                    summary: 'Error Occurred',
                    detail: 'Something Went Wrong , Please Try Again Later'
                });
            }
        })
    }
    onPanelHideResetOptions(vendor) {
        if (!(this.selectedFilter.vendorID && this.selectedFilter.vendorID.length > 0)) {
            this.vendorAccountList = [];
            this.vendorListName = [];
            vendor._filterValue = null;
            vendor._filteredOptions = [];
        }
    }
    //save templates according to route url
    onClickSaveTemplate() {
        var templateBody = {}
        var userPreferenceSettingBody = {}
        if (this.currentRouteUrl?.includes("user/email-tracker")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.emailDateRange);
            userPreferenceSettingBody = {
                "startDate": dates?.startDate ?? null,
                "endDate": dates?.endDate ?? null,
                "emailFrom": this.selectedFilter?.emailFrom,
                "emailTo": this.selectedFilter?.emailTo,
                "emailCc": this.selectedFilter?.emailCC,
                "emailSubject": this.selectedFilter?.emailSubject,
                "mid": this.selectedFilter?.messageID,
                "emailStatus": this.selectedFilter?.emailStatus,
                "noOfAttachments": this.selectedFilter?.numOfAttachements ?? null,
                "isAttachmentDiscrepancy":this.selectedFilter?.isAttachmentDiscrepancy,
                "noOfPdf": this.selectedFilter?.numOfPdf ?? null,
                "assignTo": this.selectedFilter?.emailAssignTo,
                "testIndicator": this.selectedFilter?.testIndicator,
                "remarks": this.selectedFilter?.remarks
            }
        }
        if (this.currentRouteUrl?.includes("user/document")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.receivedDateRange);
            let newArray = this.selectedFilter.legalEntityID ? this.selectedFilter.legalEntityID.map(item => item === 'EMPTY' ? "NOT_IDENTIFY" : item) : undefined;
            var invoiceDates = this.getDatesFromDateRange(this.selectedFilter.invoiceDateRange);
            var actionTakenAtDates = this.getDatesFromDateRange(this.selectedFilter.actionTakenAtRange);
            var vatDueDates = this.getDatesFromDateRange(this.selectedFilter.vatDueDate);
            userPreferenceSettingBody = {
                "documentStatus": this.selectedFilter.docStatus,
                "transId": this.selectedFilter?.transactionID ? this.selectedFilter.transactionID?.split(',') : null,
                "docType": this.selectedFilter.docType,
                "tiNumber":this.selectedFilter.tiNumber,
                "messageType": this.selectedFilter.messageType,
                "legalEntityID": newArray,
                "legalEntityName": this.selectedFilter.legalEntityName ?? null,
                "vendorID": this.selectedFilter.vendorID,
                "vendorName": this.selectedFilter.vendorName,
                "ediControlNumber": this.selectedFilter.ediControlNumber,
                "poNumber": this.selectedFilter.poNumber,
                "invoiceNo": this.selectedFilter.invoiceNo,
                "invoiceDateStart": invoiceDates?.startDate ?? null,
                "invoiceDateEnd": invoiceDates?.endDate ?? null,
                "currency": this.selectedFilter.currency,
                "destApp": this.selectedFilter.destApp,
                "processingEngine": this.selectedFilter.processingEngine,
                "fileSource": this.selectedFilter?.fileSource,
                "fileType": this.selectedFilter?.fileType,
                "isLocked": this.selectedFilter?.fileLocked,
                "isEmbeddedAvailable": this.selectedFilter?.isEmbeddedAvailable,
                "receivedStartDate": dates.startDate ?? null,
                "receivedEndDate": dates?.endDate ?? null,
                "testIndicator": this.selectedFilter?.testIndicator,
                "remarks": this.selectedFilter?.remarks,
                "documentProvider": this.selectedFilter?.documentProvider,
                "language": this.selectedFilter?.languageDoc,
                "errorCodes": this.selectedFilter?.errorCode?.join(','),
                "invoiceAssignedTo": this.selectedFilter.invoiceAssignedTo,
                "actionTakenBy": this.selectedFilter.actionTakenBy,
                "actionTakenAtStartDate": actionTakenAtDates.startDate ?? null,
                "actionTakenAtEndDate": actionTakenAtDates.endDate ?? null,
                "vatDueDateStartDate": vatDueDates.startDate ?? null,
                "vatDueDateEndDate": vatDueDates.endDate ?? null
            }
        }
        if (this.currentRouteUrl?.includes("user/dashboard")) {
            var dates = this.getDatesFromDateRange(this.selectedFilter?.invoiceDateRange);
            userPreferenceSettingBody={
                "startDate": dates?.startDate ?? null,
                "endDate": dates?.endDate ?? null,
                "docTypeDashboard": this.selectedFilter?.docTypeDashboard,
                "vendorName": this.selectedFilter?.vendorName,
                "legalEntityID": this.selectedFilter.legalEntityID,
                "documentProvider": this.selectedFilter?.documentProvider,
                "fileType": this.selectedFilter?.fileType,
                "pool": this.selectedFilter?.pool,
                "isFilter": this.isFilter
            }
        }
        templateBody = {
            "templateName": this.saveTemplateForm.value.uniqueTemplateName,
            "isShared": this.saveTemplateForm.value.isTemplateShared,
            "id": this.showUpdate ? this.templateId : 0,
            "preferenceType": this.preferenceId,
            "userPreferenceSetting": userPreferenceSettingBody
        }
        this.common.createFilterTemplate(templateBody).subscribe({
            next: (res: any) => {
                this.messageService.add({
                    severity: 'success',
                    life: 3000,
                    summary: 'Filter Template Added',
                    detail: 'New Filter Template has been added successfully.'
                });
                this.getAllFilterTemplates();
            },
            error: (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    life: 3000,
                    summary: 'Error Occurred',
                    detail: 'Failed to Add New Filter Template'
                });

            }
        });
        this.resetSaveTemplateForm();
    }

    resetSaveTemplateForm() {
        this.saveTemplateForm.patchValue({
            isTemplateShared: false,
            uniqueTemplateName: null
        })
        this.templateId = 0;
        this.showUpdate = false;
    }

    //get details of template by template id 
    onChangeGetFilterTemplateDetails(event) {
        if (!this.isDeleting) {
            if (event != null || event != undefined) {
                this.templateId = event.id;
                this.saveTemplateForm.controls['uniqueTemplateName'].setValue(event.templateName);
                if (this.privilegeList.includes('VIEW_USER_PREFERENCE_SINGLE_TEMPLATE_DETAILS')) {
                    this.common.templateDetailsByID(event.id).subscribe({
                        next: (res: any) => {
                            this.templateFilterData = res?.userPreferenceSetting;
                            this.saveTemplateForm.patchValue({
                                isTemplateShared: res?.isShared,
                                uniqueTemplateName: res?.templateName
                            })
                            this.populateFilter();
                        },
                        error: (error: any) => {
                            this.messageService.add({
                                severity: 'error',
                                life: 3000,
                                summary: 'Error Occurred',
                                detail: 'Something Went Wrong , Please Try Again Later'
                            });
                        }
                    }
                    );
                    this.showUpdate = true;
                }
            }
            else {
                this.resetSaveTemplateForm();
                this.onClickClearFilter();
            }
        }
    }
    onClickDeleteTemplate(templateId) {
        this.isDeleting = true;
        this.confirmationService.confirm({
            message: 'Are you sure, you want to Delete this Template ?',
            accept: () => {
                this.common.deleteTemplateById(templateId).subscribe({
                    next: (res: any) => {
                        this.getAllFilterTemplates();
                        this.showUpdate = false;
                        this.isDeleting = false;
                    },
                    error: (error: any) => {
                        this.messageService.add({
                            severity: 'error',
                            life: 2000,
                            summary: 'Error Occurred',
                            detail: 'Something Went Wrong , Please Try Again Later'
                        });
                        this.isDeleting = false;
                    }
                }
                );
            },
            reject: () => {
                this.isDeleting = false;
            }
        });
    }
    //populate filter fields according to route 
    populateFilter() {
        if (this.templateFilterData != null) {
            if (this.currentRouteUrl == "/user/email-tracker") {
                this.selectedFilter.emailStatus = this.templateFilterData?.emailStatus;
                this.selectedFilter.emailAssignTo = this.templateFilterData?.assignTo;
                this.selectedFilter.messageID = this.templateFilterData?.mid;
                this.selectedFilter.emailFrom = this.templateFilterData?.emailFrom;
                this.selectedFilter.emailTo = this.templateFilterData?.emailTo;
                this.selectedFilter.emailCC = this.templateFilterData?.emailCc;
                this.selectedFilter.emailSubject = this.templateFilterData?.emailSubject;
                this.selectedFilter.numOfAttachements = this.templateFilterData?.noOfAttachments;
                this.selectedFilter.isAttachmentDiscrepancy=this.templateFilterData?.isAttachmentDiscrepancy,
                this.selectedFilter.numOfPdf = this.templateFilterData?.noOfPdf;
                this.selectedFilter.testIndicator = this.templateFilterData?.testIndicator;
                this.selectedFilter.remarks = this.templateFilterData?.remarks;
                var dateRange = (this.templateFilterData?.startDate == null && this.templateFilterData?.endDate == null) ? null : this.getDateRangesFromDates(this.templateFilterData?.startDate, this.templateFilterData?.endDate)
                this.selectedFilter.emailDateRange = dateRange;
                this.checkRequiredFilterFields();
            }
            if (this.currentRouteUrl == "/user/dashboard") {
                this.selectedFilter.docTypeDashboard = this.templateFilterData?.docTypeDashboard;
                this.selectedFilter.vendorName = this.templateFilterData?.vendorName;
                this.selectedFilter.legalEntityID = this.templateFilterData?.legalEntityID;
                this.selectedFilter.documentProvider = this.templateFilterData?.documentProvider;
                this.selectedFilter.fileType = this.templateFilterData?.fileType;
                this.selectedFilter.pool = this.templateFilterData?.pool;
                var dateRange = (this.templateFilterData?.startDate == null && this.templateFilterData?.endDate == null) ? null : this.getDateRangesFromDates(this.templateFilterData?.startDate, this.templateFilterData?.endDate)
                this.selectedFilter.invoiceDateRange = dateRange;
                this.checkRequiredFilterFields();
            }
            if (this.currentRouteUrl == "/user/document") {
                var newArray = [];
                this.vendorAccountList=this.templateFilterData?.vendorID;
                this.selectedFilter.docStatus = this.templateFilterData?.documentStatus;
                this.selectedFilter.docType = this.templateFilterData?.docType;
                this.selectedFilter.messageType = this.templateFilterData?.messageType;
                this.selectedFilter.tiNumber=this.templateFilterData?.tiNumber;
                this.selectedFilter.ediControlNumber = this.templateFilterData?.ediControlNumber,
                    this.selectedFilter.legalEntityName = this.templateFilterData?.legalEntityName;
                this.selectedFilter.vendorID = this.templateFilterData?.vendorID;
                this.selectedFilter.vendorName = this.templateFilterData?.vendorName;
                this.selectedFilter.invoiceNo = this.templateFilterData?.invoiceNo;
                this.selectedFilter.remarks = this.templateFilterData?.remarks;
                this.selectedFilter.currency = this.templateFilterData?.currency;
                this.selectedFilter.destApp = this.templateFilterData?.destApp;
                this.selectedFilter.processingEngine = this.templateFilterData?.processingEngine,
                this.selectedFilter.fileLocked = this.templateFilterData?.isLocked;
                this.selectedFilter.isEmbeddedAvailable=this.templateFilterData?.isEmbeddedAvailable;
                this.selectedFilter.fileSource = this.templateFilterData?.fileSource;
                this.selectedFilter.fileType = this.templateFilterData?.fileType;
                this.selectedFilter.invoiceAssignedTo = this.templateFilterData?.invoiceAssignedTo;
                this.selectedFilter.actionTakenBy = this.templateFilterData?.actionTakenBy;
                this.selectedFilter.testIndicator = this.templateFilterData?.testIndicator;
                this.selectedFilter.languageDoc = this.templateFilterData?.language;
                this.selectedFilter.documentProvider = this.templateFilterData?.documentProvider;
                this.selectedFilter.poNumber = this.templateFilterData?.poNumber,
                    newArray = this.templateFilterData?.legalEntityID ? this.templateFilterData?.legalEntityID.map(item => item === 'NOT_IDENTIFY' ? "EMPTY" : item) : [];
                this.selectedFilter.legalEntityID = newArray;
                this.checkRequiredFilterFields();
                this.selectedFilter.transactionID = this.templateFilterData?.transId?.join(',');
                this.selectedFilter.errorCode = this.templateFilterData?.errorCodes?.split(','),
                this.selectedFilter.vatDueDate = this.getDateRangesFromDates(this.templateFilterData?.vatDueDateStartDate, this.templateFilterData?.vatDueDateEndDate);
                this.selectedFilter.actionTakenAtRange = this.getDateRangesFromDates(this.templateFilterData?.actionTakenAtStartDate, this.templateFilterData?.actionTakenAtEndDate);
                this.selectedFilter.invoiceDateRange = this.getDateRangesFromDates(this.templateFilterData?.invoiceDateStart, this.templateFilterData?.invoiceDateEnd);
                this.selectedFilter.receivedDateRange = this.getDateRangesFromDates(this.templateFilterData?.receivedStartDate, this.templateFilterData?.receivedEndDate);
            }

        }else{
            this.vendorAccountList=[];
        }
    }

    //get start and end date from date range in required format
    getDatesFromDateRange(dateRange) {
        var dates = {
            startDate: null,
            endDate: null
        }
        if (dateRange) {
            if (dateRange[0] != "" && dateRange[1] != undefined) {
                dates.startDate = moment(dateRange[0]).format(Settings.DateFormats.dateFormatBackend);
                dates.endDate = moment(dateRange[1]).format(Settings.DateFormats.dateFormatBackend);
            }
            else if (dateRange[0] && (!dateRange[1])) {
                dates.startDate = moment(dateRange[0]).format(Settings.DateFormats.dateFormatBackend);
                dates.endDate = moment(dateRange[0]).format(Settings.DateFormats.dateFormatBackend);
            }
            else {
                dates.startDate = null;
                dates.endDate = null;
            }
        } else {
            dates.startDate = null;
            dates.endDate = null;
        }
        return dates;
    }

    //convert start date and end date to date range
    getDateRangesFromDates(startDate, endDate) {
        var dateRange: string[];
        if ((startDate != "" && startDate != null) && (endDate != null && endDate != "")) {
            startDate = new Date(startDate.split("-").reverse().join("-"));
            endDate = new Date(endDate.split("-").reverse().join("-"));
            dateRange = [startDate, endDate]
        }
        else {
            dateRange = null;
        }
        return dateRange;
    }

    closeSidebar() {
        this.showPanel = false;
    }

    //reset the filter
    onClickReset() {
        this.onClickClearFilter();
        this.closeSidebar();
        this.vendorListName = [];
        this.vendorAccountOptionsList = [];
    }
    onClearCalendar() {
        this.checkRequiredFilterFields();
    }
    //clear filter
    onClickClearFilter() {
        this.leIdOptions = []
        this.replaceValueOptions = []
        Object.keys(this.selectedFilter).map(key => {
            if (this.selectedFilter[key] instanceof Array) this.selectedFilter[key] = undefined
            else this.selectedFilter[key] = undefined
        })
        if (this.templateValue) {
            this.resetSaveTemplateForm();
            this.templateValue = null;
        }
        this.disableSave = true;
        this.onClickApplyFilter();
    }

    ngOnDestroy() {
        this.filterSubscription.unsubscribe();
        this.clearfilterEvent.unsubscribe();
    }

    //get preference list and segregate according to route
    getMasterPreferencesList() {
        var preferenceListFilterLocal = Settings.CommonFunctions.getPreferenceList('userDetails');
        var privilegeListFilterLocal = Settings.CommonFunctions.getPrivilegeList();
        if (!preferenceListFilterLocal) {
            this.userDetailsSubscription = this.common.getPrivelegeEvent().subscribe((res) => {
                var preferenceListFilter = res?.preferences;
                this.privilegeList = res?.privileges;
                this.preferenceListColumnSettings = preferenceListFilter.filter((data) =>
                    data.userPreferenceType.includes("FILTER")
                );
                if (this.router.url == '/user/email-tracker')
                    this.checkPreferenceID("ETRACKER")
                if (this.router.url == '/user/document')
                    this.checkPreferenceID("DOC")
                if (this.router.url == '/user/dashboard')
                    this.checkPreferenceID("DASHBOARD")
            })
        }
        else {
            this.privilegeList = privilegeListFilterLocal;
            this.preferenceListFilter = preferenceListFilterLocal.filter((data) =>
                data.userPreferenceType.includes("FILTER")
            );
            if (this.router.url == '/user/email-tracker')
                this.checkPreferenceID("ETRACKER")
            if (this.router.url == '/user/document')
                this.checkPreferenceID("DOC")
            if (this.router.url == '/user/dashboard')
                this.checkPreferenceID("DASHBOARD")
        }
    }
    checkPreferenceID(routeConf) {
        this.preferenceListFilter.forEach((items: any) => {
            if (items.userPreferenceType?.includes(routeConf)) {
                this.preferenceId = items.id;
            }
        })
        if (this.privilegeList.includes('CREATE_UPDATE_USER_PREFERENCE_TEMPLATE')) {
            this.getAllFilterTemplates();
        }
    }
    // --------------get 'legal-entity id & replace value options' for vat & name & city and zip alias tables---------//

    getLegalEntity(type, field) {
        this.leIdOptions = []
        this.replaceValueOptions = []
        this.common.getLeVendorCustomData(type, field, null).subscribe({
            next: (res: any) => {
                res?.legalEntityId?.map((data) => {
                    this.leIdOptions.push({ "name": data?.value })
                })
            }
        })
    }
    aliasLeIdOptions() {
        this.getLegalEntity('le', 'legal_entity_id')
    }
    getReplaceValueOptions(module) {
        this.replaceValueOptions = []
        if (module === 'vat') {
            if (this.selectedFilter?.vatLeId != undefined) {
                if (this.selectedFilter?.vatType == 'Vendor') {
                    let body = {
                        'legalEntityId': this.selectedFilter?.vatLeId,
                    }
                    this.vendorService.getVendorSearchList(body).subscribe({
                        next: (res: any) => {
                            res?.results?.map((data) => {
                                this.replaceValueOptions.push({ "name": data?.vatNumber })
                            })
                            const unique = [...new Map(this.replaceValueOptions.map(item => [item['name'], item])).values()]
                            this.replaceValueOptions = unique;
                        }
                    })
                } else {
                    let body = {
                        "legalEntityId": this.selectedFilter?.vatLeId,
                    }
                    this.legalEntityService.getAllLegalEntityList(body, false).subscribe({
                        next: (res: any) => {
                            res?.results?.map((data) => {
                                this.replaceValueOptions.push({ "name": data?.vatNumber })
                            })
                        }
                    })
                }
            } else {
                this.replaceValueOptions = []
            }
        }
        else if (module === 'name') {
            if (this.selectedFilter?.nameLeId != undefined) {
                if (this.selectedFilter?.nameType == 'Vendor') {
                    let body = {
                        'legalEntityId': this.selectedFilter?.nameLeId,
                    }
                    this.vendorService.getVendorSearchList(body).subscribe({
                        next: (res: any) => {
                            res?.results?.map((data) => {
                                this.replaceValueOptions.push({ "name": data?.name })
                            })
                            const unique = [...new Map(this.replaceValueOptions.map(item => [item['name'], item])).values()]
                            this.replaceValueOptions = unique;
                        }
                    })
                } else {
                    let body = {
                        "legalEntityId": this.selectedFilter?.nameLeId,
                    }
                    this.legalEntityService.getAllLegalEntityList(body, false).subscribe({
                        next: (res: any) => {
                            res?.results?.map((data) => {
                                this.replaceValueOptions.push({ "name": data?.entityName })
                            })
                        }
                    })
                }
            } else {
                this.replaceValueOptions = []
            }
        }
        else if (module === 'city') {
            if (this.selectedFilter?.cityLeId != undefined) {
                if (this.selectedFilter?.cityType == 'Vendor') {
                    let body = {
                        'legalEntityId': this.selectedFilter?.cityLeId,
                    }
                    this.vendorService.getVendorSearchList(body).subscribe({
                        next: (res: any) => {
                            res?.results?.map((data) => {
                                this.replaceValueOptions.push({ "name": data?.city })
                            })
                            const unique = [...new Map(this.replaceValueOptions.map(item => [item['name'], item])).values()]
                            this.replaceValueOptions = unique;
                        }
                    })
                } else {
                    let body = {
                        "legalEntityId": this.selectedFilter?.cityLeId,
                    }
                    this.legalEntityService.getAllLegalEntityList(body, false).subscribe({
                        next: (res: any) => {
                            res?.results?.map((data) => {
                                this.replaceValueOptions.push({ "name": data?.city })
                            })
                        }
                    })
                }
            } else {
                this.replaceValueOptions = []
            }
        }
        else if (module === 'zip') {
            if (this.selectedFilter?.zipLeId != undefined) {
                if (this.selectedFilter?.zipType == 'Vendor') {
                    let body = {
                        'legalEntityId': this.selectedFilter?.zipLeId,
                    }
                    this.vendorService.getVendorSearchList(body).subscribe({
                        next: (res: any) => {
                            res?.results?.map((data) => {
                                this.replaceValueOptions.push({ "name": data?.zipCode })
                            })
                            const unique = [...new Map(this.replaceValueOptions.map(item => [item['name'], item])).values()]
                            this.replaceValueOptions = unique;
                        }
                    })
                } else {
                    let body = {
                        "legalEntityId": this.selectedFilter?.zipLeId,
                    }
                    this.legalEntityService.getAllLegalEntityList(body).subscribe({
                        next: (res: any) => {
                            res?.results?.map((data) => {
                                this.replaceValueOptions.push({ "name": data?.zipCode })
                            })
                        }
                    })
                }
            } else {
                this.replaceValueOptions = []
            }
        }
    }
}
