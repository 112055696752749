import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  baseModuleUrl="doctracker/document/";
  private http:HttpClient
    constructor(_http:HttpClient) {
      this.http=_http;
     }
  getAllDocumentList(docBody:{},isBlob:boolean){
    if(isBlob){
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'list',docBody,{observe: 'response', responseType: 'blob' });
    }
    else{
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'list',docBody);
    }
  }
  getDocumentDetailsById(docId:Number){
    return this.http.get(environment.baseApiUrl+this.baseModuleUrl+docId);
  }
  getPrevNextDocument(docId:Number,payload:{}){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'next-pre/'+docId,payload);
  }
  getErrorLogListById(docId:Number){
    return this.http.post(environment.baseApiUrl+'doctracker/document/error-log/list',{"documentId":docId});
  }
  getErrorLogListHistoryById(docId:Number){
    return this.http.post(environment.baseApiUrl+'doctracker/document/error-log-history/list',{"documentId":docId});
  }
  getCommentListById(docId:Number){
    return this.http.post(environment.baseApiUrl+'doctracker/document/comment/list',{"documentId":docId});
  }
  getDocTrackerMailChainData(docId:Number){
    return this.http.get<any>(environment.baseApiUrl+'etracker/email/document/chain/'+docId);
  }
  getDocumentLineItemsById(docId:Number){
    return this.http.post(environment.baseApiUrl+'doctracker/lineitem/list',{"documentId":docId});
  }
  getTaxHeaderDetails(docId:Number){
    return this.http.get(environment.baseApiUrl+'doctracker/header-tax?document_id='+docId);
  }
  addDocumentLineItems(lineItemsBody:{}){
    return this.http.post(environment.baseApiUrl+'doctracker/lineitem',lineItemsBody);
   }
   addDocumentTaxLineItems(lineTaxItemsBody:{}){
    return this.http.post(environment.baseApiUrl+'doctracker/lineitem/tax',lineTaxItemsBody);
   }
   addDocumentHeaderTax(lineTaxItemsBody:{}){
    return this.http.post(environment.baseApiUrl+'doctracker/header-tax',lineTaxItemsBody);
   }
   getAllDocumentTypeList(docBody:{},isBlob:boolean){
    if(isBlob){
      return this.http.post(environment.baseApiUrl+'doctracker/document_type/list',docBody,{observe: 'response', responseType: 'blob' });
    }
    else{
      return this.http.post(environment.baseApiUrl+'doctracker/document_type/list',docBody);
    }
  }
   addDocumentType(body:{}){
    return this.http.post(environment.baseApiUrl+'doctracker/document_type',body);
   }
   updateDocumentType(id:number,body:{}){
    return this.http.put(environment.baseApiUrl+'doctracker/document_type/'+id,body);
   }
   deleteDocumentType(typeId:Number){
    return this.http.delete(environment.baseApiUrl+'doctracker/document_type/'+typeId);
  }
  updateDocumentLineItems(lineItemId:number,lineItemsBody:{}){
    return this.http.put(environment.baseApiUrl+'doctracker/lineitem/'+lineItemId,lineItemsBody);
   }
   updateHeaderTaxItems(taxId:number,taxBody:{}){
    return this.http.put(environment.baseApiUrl+'doctracker/header-tax/'+taxId,taxBody);
   }
   updateDocumentTaxLineItems(taxId:number,lineItemsBody:{}){
    return this.http.put(environment.baseApiUrl+'doctracker/lineitem/tax/'+taxId,lineItemsBody);
   }
   getDocumentFiles(docId:Number){
    return this.http.get(environment.baseApiUrl+this.baseModuleUrl+'files/'+docId)
  }
  getEDIPDFFile(docId:Number){
  return this.http.get(environment.baseApiUrl+'doctracker/edi-pdf/'+docId)
  }
  deleteDocumentLineNumber(lineItemId:Number){
    return this.http.delete(environment.baseApiUrl+'doctracker/lineitem/'+lineItemId);
  }
  deleteDocumentLineTax(lineItemId:Number){
    return this.http.delete(environment.baseApiUrl+'doctracker/lineitem/tax/'+lineItemId);
  }
  deleteHeaderTax(lineItemId:Number){
    return this.http.delete(environment.baseApiUrl+'doctracker/header-tax/'+lineItemId);
  }
  assignDocument(assignBody:{}){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'assign',assignBody);
   }
   markDocumentCompleted(body:{}){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'force-complete',body);
   }
   lockDocument(docId,lockBody:{}){
    return this.http.put(environment.baseApiUrl+'doctracker/edit/lock/'+docId,lockBody);
   }
   actionOnDocument(actionBody:{}){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+"action",actionBody);
   }
   addRemark(remarkBody:{}){
    return this.http.post(environment.baseApiUrl+'doctracker/remark',remarkBody);
   }
   replyOrForwardMailDoc(body:FormData){
    return this.http.post(environment.baseApiUrl+'doctracker/reply',body);
   }
   massForward(body:{}){
    return this.http.post(environment.baseApiUrl+'doctracker/multi-forward',body);
   }
   downloadDocument(docIds:String,fileAs:String){
    return this.http.get<Blob>(environment.baseApiUrl+"doctracker/download?ids="+docIds+"&file_as="+fileAs,{observe: 'response', responseType: 'blob' as 'json' });
   }
   redirectDestination(body:any){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'destination/change',body);
   }


   getDocumentAutocodingLines(docId:Number){
    return this.http.get(environment.baseApiUrl+'doctracker/auto-coding/line/'+docId);
  }
  saveAutoCodingHeader(body){
    return this.http.post(environment.baseApiUrl+'doctracker/auto-coding/header',body);
  }

  getDocumentHeaderAutocoding(docId:Number){
    return this.http.get(environment.baseApiUrl+'doctracker/auto-coding/header/'+docId);
  }

  getAutocodingLineDetailbyId(lineId){
    return this.http.get(environment.baseApiUrl+'doctracker/auto-coding/line/detail/'+lineId);
  }

  saveAutoCodingLines(body){
    return this.http.post(environment.baseApiUrl+'doctracker/auto-coding/line',body);
  }

  deleteAutocodingLine(lineId){
    return this.http.delete(environment.baseApiUrl+'doctracker/auto-coding/line/'+lineId);
  }
}
